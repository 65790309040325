import * as Yup from "yup";

export const originalSchema = Yup.object().shape({
  _original: Yup.object().notRequired(),
});

export const payWithCardValidationSchema = (isCardSelected) => {
  // SCHEMA FOR PROFILE DETAILS
  let PROFILE = {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    countryCodeForMobileNumber: Yup.string(),
    mobileNoWithoutCountryCode: Yup.string(),
  };

  // SCHEMA FOR CARD DETAILS
  let CARD = {
    name: Yup.string().required("Name on card is required"),
    card: Yup.string()
      .test(
        "len",
        "Invalid card number",
        (val) => (val && val?.length === 16) || val?.length === 15
      )
      .required("Card number is required"),
    expiryDate: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Invalid date format")
      .required("MM/YY is required"),
    cvv: Yup.string()
      .required("CVV is required")
      .min(3, "CVV must be at least 3 digits")
      .max(4, "CVV can be at most 4 digits"),
    country: Yup.object().required("Country is required"),
    zipCode: Yup.string().required("Zipcode is required"),
  };

  // CONDITIONAL RETURNING
  return Yup.object().shape(
    isCardSelected
      ? {
          ...PROFILE,
        }
      : { ...PROFILE, ...CARD }
  );
};

export const signInValidationSchema = (showPassword=true) => {

  let password = Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
  
  let email = Yup.string()
    .email("Invalid email")
    .required("Shashi Id is required")
  
 return Yup.object().shape(
  showPassword ?
     { email, password } :
     { email } 
      
  );
}

export const corporateRateplanSchema = Yup.object().shape({
  corpCode: Yup.string().required("Corporate ID is required"),
});

export const reservationCancelValidationSchema = Yup.object().shape({
  reservationId: Yup.string().required("Reservation Id is required"),
  lastName: Yup.string().required("Lastname is required"),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string().required("Password is required").min(6),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required")
    .min(6),
});
