import { CustomBox } from "../../commonElements";
import { useDispatch, useSelector } from "react-redux";
import { setDateFormat, setIsDateSingleSelection } from "./calenderSlice";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { memo, useEffect, useRef, useState } from "react";
import { isSameDay, eachDayOfInterval } from "date-fns";
import { IconArrow } from "../../icons";
import moment from "moment-timezone";
import "./calender.css";
import { setStatus } from "../costSummary/costSummarySlice"; 
import { roomSelector } from "../rooms/roomSlice";
import { getDisabledDates } from "./api";
import { useLocation, useParams } from "react-router-dom";
import { calendarHelper } from "./helper";

const Calender = ({queryDetails}) => {
  const { arrivalDate, departureDate, isDateSingleSelect, disabledDatesArray } =
    useSelector((state) => state.calender);
  const { groupCodeDetails, loading, selectedRatePlan, selectedRoom } = useSelector(roomSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // 2023-06-15T00:00:00.000Z
  // const [disabledDates, setDisabledDates] = useState([
  //   new Date(2023, 5, 15), // June 15, 2023
  //   new Date(2023, 5, 18), // June 18, 2023
  //   new Date(2023, 6, 3), // July 3, 2023
  //   new Date(2023, 6, 7), // July 7, 2023
  // ]);
  let endDate = moment().add(2,'month').startOf("month").add(11,'days').format('YYYY-MM-DD')

  const [disabledDates, setDisabledDates] = useState([]);
  const [availableDate, setAvailableDate] = useState([]);
  const [dateInOutForEffect,setDateInOutForEffect] = useState({dateIn:"",dateOut:""})
  const [checkOut, setCheckOut] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if(!searchParams.get("datein") && !searchParams.get("datein")) return
    let startOfCal = moment.unix(searchParams.get("datein")).startOf('month').startOf('week').format('YYYY-MM-DD')
    let endOfCal = moment.unix(searchParams.get("datein")).add(1, 'month').endOf('month').endOf('week').format('YYYY-MM-DD')
    if (!moment().tz('GMT').isBefore(startOfCal, 'day')) {
      startOfCal = moment().format('YYYY-MM-DD')
    }
    console.log('calStart', startOfCal, endOfCal, !moment().tz('GMT').isBefore(startOfCal, 'day'));

    setDateInOutForEffect({
      dateIn: startOfCal,
      dateOut: endOfCal
    })
  }, [])


  useEffect(() => {
    if (!selectedRatePlan?.ratePlanCode) return;
      dispatch(
        getDisabledDates({
          dateRange: {
            dateIn: dateInOutForEffect.dateIn || moment().format('YYYY-MM-DD'),
            dateOut: dateInOutForEffect.dateOut || endDate
          },
          CBhandleDD: handleDD,
          queryDetails
        })
      );
    
  }, [dateInOutForEffect, queryDetails?.adults, queryDetails?.children, selectedRatePlan, selectedRoom]);


  const handleViewCallback = ({ action, activeStartDate }) => {
    function callback(dateIn,dateOut) {
      setDateInOutForEffect({
        dateIn,
        dateOut
      })
    }
    calendarHelper.handleViewCallback(action,activeStartDate,groupCodeDetails,callback)  
  };

  const handleDD = (data) => {
    // console.log("data:", data);

    let resultDD = [];
    let resultCO = [];
    let resultAV = [];

    for (let i = 0; i < data?.length; i++) {
      let currentDay = data[i]?.date;
       let checkAvailability = data[i]?.availability.some(item => item.availStatus == "RateNotSetUp")
      if (data[i]?.isAvailable === false) {
        if (data[i - 1]?.isAvailable && !checkAvailability ) {
          resultCO.push(new Date(moment(currentDay).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')));
        } else {
          resultDD.push(new Date(moment(currentDay).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')));
        }
      } else if (data[i]?.isAvailable === true) {
        resultAV.push(data[i]);
      }
    }

    setDisabledDates(resultDD);
    setCheckOut(resultCO);
    setAvailableDate(resultAV);

    console.log("resultDD:", resultDD);
    console.log("resultCO:", resultCO);
  };

  const tileDisabled = ({ date }) => {
    // console.log('date:', date)

    // Check if the date is in the disabledDates array
    return disabledDates.some((disabledDate) => isSameDay(disabledDate, date));
  };

  const getAllDatesInRange = (selectedRange) => {
    if (selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      const datesInRange = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });
      return datesInRange;
    }
    return [];
  };

  const handleDateChange = (date) => {
    // console.log("date:", date);

    const selectedRange = getAllDatesInRange(date);
    // console.log("selectedRange:", selectedRange);

    // Check if any selected date falls within the disabled range
    const isDisabledDateSelected = selectedRange.some((selectedDate) =>
      disabledDates.some((disabledDate) =>
        isSameDay(selectedDate, disabledDate)
      )
    );

    if (isDisabledDateSelected) {
      // return alert(
      //   "One or more dates have been selected for which the hotel is unavailable."
      // );

      return dispatch(
        setStatus({
          apiMessage:
            "One or more dates have been selected for which the hotel is unavailable.",
          apiSuccess: false,
        })
      );
    } else {
      onChange(date);
    }
  };

  const onChange = (e) => {
    // console.log('e:', e)

    if (
      moment(e[0]).startOf("date").format("X") ==
      moment(e[1]).startOf("date").format("X")
    ) {
      dispatch(
        setStatus({
          apiMessage:"Your Arrival and Departure date cannot be same.",
          apiSuccess: false,
        })
      );
    } else {
      if(loading) return 
      dispatch(setDateFormat(e));
    }
  };
  const onClickDay = (e) => {
    // console.log("e:", e);

    const isDisabledDateSelected = checkOut.some((selectedDate) =>
      isSameDay(selectedDate, e)
    );
    if (isDisabledDateSelected && count % 2) {
      setCount((prev) => prev + 1);

      // return alert("Only check-out is allowed for the selected date");
      return dispatch(
        setStatus({
          apiMessage: "This date is unavailable as a Check-in date",
          apiSuccess: false,
        })
      );
    }

    // console.log("count", count);
    setCount((prev) => prev + 1);
    dispatch(setIsDateSingleSelection(!isDateSingleSelect));
  };
  
  return (

      <CustomBox>
        <Calendar
          // className="vertical-calendar"
          // orientation="portrait"
          tileDisabled={tileDisabled}
        // tileContent={(e) => {
          
        //     if (checkOut.some((selectedDate) => isSameDay(selectedDate, e.date))) {
             
        //       return   <div className=" font-light whitespace-nowrap leading-[9px] checkout-only mt-[2px]">
        //   Check-out<br />
        //   only
        // </div>
        //     }
        //     return availableDate.map((event) => {
        //       if (
        //         event.isAvailable === true &&
        //         isSameDay(
        //           new Date(
        //             moment(event.date).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ")
        //           ),
        //           e.date
        //         )
        //       ) {
        //         // return <div className=" font-light whitespace-nowrap leading-[9px] h-[18px] room-price mt-[2px]">
        //         //   {event?.rate?.minRate && !isNaN(Number(event?.rate?.minRate))
        //         //     ? (() => {
        //         //       const minRate = Number(event.rate.minRate).toFixed(0)
        //         //       if (minRate < 0) {
        //         //         console.log("Negative value for minRate.");
        //         //         return null; // Handle negative values accordingly
        //         //       }
        //         //       return `$${minRate}`;
        //         //     })()
        //         //     : ""}
        //         // </div>
        //       }
        //     });
        //   }}
          onActiveStartDateChange={handleViewCallback}
        maxDate={calendarHelper.maxDate(groupCodeDetails)}
          prevLabel={<img alt="For select previous month " src="/images/icons/leftArrow.svg"/>}
          nextLabel={<img alt="For select next month " src="/images/icons/rightArrow.svg"/>}
          formatShortWeekday={(locale, date) =>
            moment(date).format("dd").charAt(0)
          }
          value={[
            moment.unix(arrivalDate).toDate(),
            moment.unix(departureDate).toDate(),
          ]}
          // defaultActiveStartDate={moment.unix(departureDate).toDate()}
          minDate={
            Object.keys(groupCodeDetails).length > 0
              ? moment(groupCodeDetails?.startDate).toDate()
              : new Date()
          }
          goToRangeStartOnSelect={false}
          calendarType={"US"}
          onChange={handleDateChange}
          selectRange={true}
          prev2Label={null}
          next2Label={null}
          onClickDay={onClickDay}
      />

    </CustomBox>
  );
}

export default memo(Calender);

