import { MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./savedcards.css";
import { useDispatch, useSelector } from "react-redux";
import {
  costSummarySelector,
  setOpenPayInfo,
} from "../costSummary/costSummarySlice";
import {
  saveCardsSelector,
  selectCard,
  setOpenCardOptions,
} from "./savedCardsSlice";

const SavedCards = ({ open }) => {
  const { savedCards, openCardOptions, selectedCard, isCardSelected } =
    useSelector(saveCardsSelector);
  const { openPayInfo } = useSelector(costSummarySelector);

  const dispatch = useDispatch();

  const collapseCardOptons = () => {
    dispatch(setOpenCardOptions(!openCardOptions));
    // dispatch(setOpenPayInfo(!openPayInfo));
  };

  return (
    <Box sx={{ maxWidth: { xs: "500px" }, ml: "auto", mr: "auto" }}>
      {savedCards?.length > 0 && (
        <Select
          className="saved-card-select"
          onClick={collapseCardOptons}
          open={open}
          value={
            isCardSelected
              ? `${selectedCard?.brand} - ${selectedCard?.last4}`
              : "payWithCard"
          }
          sx={{ width: "100%" }}
        >
          {savedCards.map((card, index) => (
            <MenuItem
              className="saved-card-items"
              key={index}
              value={`${card?.brand} - ${card?.last4}`}
              onClick={() => {
                dispatch(selectCard(card));
              }}
            >
              <p>
                {card?.brand} - {card?.last4}
              </p>
            </MenuItem>
          ))}
          <MenuItem
            className="saved-card-items"
            value={"payWithCard"}
            onClick={() => {
              dispatch(selectCard());
            }}
          >
            <p>Add New Card</p>
          </MenuItem>
        </Select>
      )}
    </Box>
  );
};

export default SavedCards;
