import React from "react";
import { Abtest } from "../../mixpanel";

function index({ count, className }) {
  return (
    <Abtest
      VariantA={
        <span
          className={`${className} w-fit xs:hidden font-[400] ml-auto mr-auto mb-[16px] lg:flex`}
        >
          {count}
        </span>
      }
      VariantB={
        <span
          className={`${className} bg-magenta filter-brightness-150 
          w-[30px] h-[30px] pt-[2px] text-white rounded-full flex justify-center items-center font-[400] ml-auto mr-auto mb-[13px] lg:flex`}
        >
          {count}
        </span>
      }
    />
  );
}

export default index;
