import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { cancelRoomApiMsg, config } from "../../config";
import { setDfltPkgOnRomSlctin, setTimeSpan } from "./roomSlice";
import { DateChangetoNextWeek, setDate } from "../calender/calenderSlice";
import moment from "moment";

export const fetchRatePlanRoomType = createAsyncThunk(
  "room/ratePlanRoomType",
  async (state, { getState, rejectWithValue, dispatch }) => {
    const { navigate, queryDetails, isDateProvideInQuery, isdefaultRoomSelection } = state;
    let { calender, room, stripe } = getState();
    let { arrivalDate, departureDate, isDefaultDate } = calender;
    let { hotelDetails } = stripe
    try {
      let { ratePlanCode, corpCode, roomTypeId, groupCode, selectedRoom } =
        room;
      let hotelId = hotelDetails?.hotelCode;
      let value = {
        hotelId,
        arrivalDate,
        departureDate,
        ratePlanCodes: ratePlanCode,
        // currently the bookingData is static -->
        roomTypeId: roomTypeId || queryDetails.roomTypeId || "",
        bookingData: [
          {
            rooms: queryDetails.rooms,
            pets: queryDetails.pets,
            adults: queryDetails.adults,
            children: queryDetails.children,
            corpCode: corpCode,
            groupCode: groupCode,
          },
        ],
        specialCodes: {
          groupId: queryDetails.groupId,
        },
        // "3852882"
      };
      var res = await AXIOS_RESERVATION.post(`v1/hotels/${hotelId}`, value, {
        cancelToken: window?.cancelTokenHotelsPostMethod?.token,
      });
      // if (res?.data?.success) {

      // } else {
      //   return {
      //     status: {
      //       apiMessage: res?.data?.message,
      //       apiSuccess: res?.data?.success,
      //     },
      //   };
      // }
      if (
        res?.data?.data != undefined &&
        Object.keys(res?.data?.data).length > 0
      ) {
        
        let sltdRoom = selectedRoom?.length > 0 || isdefaultRoomSelection || queryDetails?.roomTypeId
          ? res?.data?.data?.roomType.filter((e) => e.default)
          : [];
        let allOptn = setDfltPkgOnRomSlctin(sltdRoom, res?.data?.data?.packages)
        
        return {
          selectedRoom:sltdRoom,
          ratePlans: res?.data?.data?.ratePlans,
          roomType: res?.data?.data?.roomType,
          timeSpan: res?.data?.data?.timeSpan,
          allOptions: allOptn,
          cancellationPolicy:
            res?.data?.data?.ratePlans[0]?.cancellationPolicy
              ?.policyDescription,
          status: {
            apiMessage: res?.data?.message,
            apiSuccess: res?.data?.success,
          },
          groupCodeDetails: res?.data?.data?.groupCodeResp || {},
          isRoomAvailable: res?.data?.isRoomAvailable,
          isCorpCode: res?.data?.isCorpCode || false,
        };
      } else {
        throw {
          status: {
            apiMessage:
              res?.data?.message ||
              "Something went wrong please try again later",
            apiSuccess: res?.data?.success,
          },
          isRoomAvailable: res?.data?.isRoomAvailable,
        };
      }
    } catch (error) {
      let { status, isRoomAvailable } = error;
      if (isRoomAvailable != false && error?.message !== cancelRoomApiMsg) {
        if (!window.location.pathname.includes('webflowWebView')) {
          navigate("/intro");
        }
      }
      if (
        isDefaultDate &&
        !isDateProvideInQuery &&
        error?.message !== cancelRoomApiMsg
      ) {
        dispatch(DateChangetoNextWeek());
      }
      throw rejectWithValue({
        status,
        isRoomAvailable: false,
        loading: error?.message == cancelRoomApiMsg ? true : false,
        optionLoader: error?.message == cancelRoomApiMsg ? true : false,
      });
    }
  }
);
