import { Skeleton } from "@mui/material";
import React from "react";
import { CustomBox } from "../../commonElements";

const SkeletonCircular = () => (
  <div className="flex flex-col items-center">
    <Skeleton
      sx={{ bgcolor: "#e0e0e0", width: "39px", height: "39px" }}
      variant="circular"
    />
    <Skeleton
      variant="text"
      sx={{ bgcolor: "#e0e0e0", width: "23px", height: "17px", mt: "5px" }}
    />
  </div>
);

const SkeletonBase = ({ children }) => (
  <div className="flex p-1 gap-4">{children}</div>
);

function BubbleSkeleton() {
  return (
    <CustomBox sx={{ padding: "18px",marginBottom:'35px' }}>
      <Skeleton
        sx={{ bgcolor: "#e0e0e0", width: "150px", ml: "7px", mb: "10px" }}
        variant="text"
      />
      <SkeletonBase>
        <SkeletonCircular />
        <SkeletonCircular />
        <SkeletonCircular />
        <SkeletonCircular />
        <SkeletonCircular />
      </SkeletonBase>
    </CustomBox>
  );
}

export default BubbleSkeleton;
