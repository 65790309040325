import { useParams } from "react-router-dom";
import {
  ResetPassword,
  ResetPasswordError,
  ResetPasswordSuccess,
} from "../../features";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyResetPswdTkn } from "../../features/resetPassword/api";
import { resetPasswordSelector } from "../../features/resetPassword/resetPasswordSlice";

function PasswordReset() {
  const { resetPasswordToken } = useParams();
  const { verifyTokenStatus, resetPasswordStatus } = useSelector(
    resetPasswordSelector
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(verifyResetPswdTkn(resetPasswordToken));
  }, []);

  return (
    <section className="flex items-center justify-center w-full h-auto bg-main">
      {verifyTokenStatus.apiSuccess ? (
        resetPasswordStatus.apiSuccess ? (
          <ResetPasswordSuccess />
        ) : resetPasswordStatus.apiSuccess == false ? (
          <ResetPasswordError status={resetPasswordStatus} />
        ) : (
          <ResetPassword />
        )
      ) : verifyTokenStatus.apiSuccess == false ? (
        <ResetPasswordError status={verifyTokenStatus} />
      ) : (
        <Fragment />
      )}
    </section>
  );
}

export default PasswordReset;
