import React from "react";
// import { Icon_logo } from "../../icons/icons";

function VerificationLayout(props) {
  return (
    <>
      <header
        className="
      top-0 sticky bg-white top-bar-static-page-shadow z-5 
      flex flex-nowrap items-start justify-center w-full 
      pt-4 pb-4
      md:pt-5 md:pb-5 pl-4 pr-4 md:pl-8 md:pr-8"
      >
        <div className="w-36 xs:w-44 sxs:w-48 xl:w-56   2xl:w-72">
          {/* <Icon_logo className={`fill-black cursor-pointer Icon_logo`} /> */}
        </div>
      </header>
      <main className={`${props.className} pl-5 pr-5 verify-main-tag overflow-y-scroll pb-10 pt-10`}>
        {props.children}
      </main>
      <style>
        {`
        h2{
          font-size:2.2em;
          font-weight:500;
        }
        h3{
          font-size:1.624em;
          font-weight:400;
        }
        @media(max-width:650px){
          h3{
              font-size:1.2em;
              font-weight:400;
          }
          h2{
              font-size:1.8em;
              font-weight:500;
          }
        }
        .verify-main-tag{
          height:calc(100vh - 53.59px);
        }
        @media(min-width:410px){
          .verify-main-tag{
            height:calc(100vh - 58.4px);
          }
        }
        @media(min-width:540px){
          .verify-main-tag{
            height:calc(100vh - 60.8px);
          }
        }
        @media(min-width:768px){
          .verify-main-tag{
            height:calc(100vh - 68.8px);
          }
        }
        @media(min-width:1280px){
          .verify-main-tag{
            height:calc(100vh - 73.59px);
          }
        }
        @media(min-width:1536px){
          .verify-main-tag{
            height:calc(100vh - 83.2px);
          }
        }
        `}
      </style>
    </>
  );
}

export default VerificationLayout;
