import { roomExtraReducers } from "./extraReducer";
import { createSlice } from "@reduxjs/toolkit";
import { roomReducers } from "./reducer";
export * from "./helper";

const initialState = {
  //descriptionOpenKey text for show description
  selectedIndexAndKey: {},
  packageDisabledIds:[],
  firstAllOptions: [],
  selectedRatePlan: {},
  groupCodeDetails: {},
  selectedOptions: [],
  selectedRoom: [],
  prevOptions: [],
  allOptions: [],
  ratePlans: [],
  roomType: [],
  timeSpan: {},
  isVisitedRoomBookingPage: false,
  isCollapseRatePlan: false,
  isRoomChangeFlag: false,
  isRoomAvailable:false,
  isCorpCategory: false,
  optionLoader: false,
  isCorpCode:false,
  isApiCall: true,
  loading: false,
  isHandleOpen: null,
  corpCode: "",
  groupCode: "",
  roomTypeId: "",
  ratePlanCode: "",
  cancellationPolicy: "",
  // petPolicy: "",
  descriptionOpenKey: "",

  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const roomSlice = createSlice({
  initialState,
  name: "room",
  reducers: roomReducers,
  extraReducers: roomExtraReducers,
});

export const {
  setStatus,
  setBubble,
  selectRoom,
  setCorpCode,
  setHandleOpen,
  setTimeSpan,
  setIsApiCall,
  showAllRooms,
  setPrevBubble,
  setRoomTypeId,
  resetRatePlan,
  resetCorpCode,
  setRatePlanCode,
  resetRoomLoader,
  setDescriptionKey,
  setIsCorpCategory,
  setIsRoomAvailable,
  setCollapseRatePlan,
  setSelectedRatePlan,
  setCancellationPolicy,
  setPackagesDisabledIds,
  setVisitedRoomBookingPage,
  // setPetPolicy,
} = roomSlice.actions;

export default roomSlice.reducer;
export const roomSelector = (state) => state.room;
