import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../config";
import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";

export const getSavedCards = createAsyncThunk(
    "savedCards/getAllCards",
    async (state, { rejectWithValue, getState }) => {
        try {
            let { stripe } = getState();
            const { hotelDetails } = stripe;
            const res = await AXIOS_RESERVATION.get(`getallcards?hotelId=${hotelDetails?.hotelCode}`);
            console.log(res?.data?.result?.data?.cards, "res >>>");
            if (res?.data?.success) {
                return {
                    savedCards: res?.data?.result?.data?.cards,
                    status: {
                        apiMessage: res?.data?.result?.message,
                        apiSuccess: res?.data?.success,
                    },
                };
            } else {
                return {
                    savedCards: [],
                    status: {
                        apiMessage: res?.data?.result?.message,
                        apiSuccess: res?.data?.success,
                    },
                };
            }
        } catch (error) {
            throw rejectWithValue({
                userData: {},
                status: {
                    apiSuccess: error?.response?.data?.success || false,
                    apiMessage:
                        error?.response?.data?.result?.message ||
                        "Something went wrong please try again later",
                },
            });
        }
    }
);
