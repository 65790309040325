import mixpanel from "mixpanel-browser";
import { mixPanelEvents } from "./event";

export const mixPanelInit = (triggerMixpanelInitialVisit) => {
  mixpanel.init(process?.env?.REACT_APP_MIXPANEL_TOKEN, {
    debug: true,
    persistence: "localStorage",
  });
  mixPanelEvents.initialVisitor(triggerMixpanelInitialVisit)
};
