export const PayWithCardButton = ({
  className = "",
  name = "",
  Icon = () => null,
  spanClassName = "",
  onClick = () => {},
  type = "button",
  dropDownIcon = null,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`pt-[11px] pb-[11px] flex justify-between items-center ${className}`}
      style={{ width: "100%", height: "40px" }}
    >
      <div className="flex w-11/12 justify-center pl-6">
        <Icon />

        <span
          className={`flex items-center font-medium text-[13.333] text-center ${spanClassName}`}
        >
          {name}
        </span>
      </div>
      <div>{dropDownIcon}</div>
    </button>
  );
};
