import mixpanel from "mixpanel-browser";
import moment from "moment";

//  FOR RESERVATION FAILED --->>
const reservationSuccess = (reservationRespData, totalCost) => {
  const { roomTypeName, reservationData } = reservationRespData?.data;
  const reservations = reservationData.reservations;
  const { reservationId, arrivalDate, departureDate } = reservations[0];
  const dateIn = moment(arrivalDate).format("ddd, MMM D, YYYY");
  const dateOut = moment(departureDate).format("ddd, MMM D, YYYY");
  let pushtell = localStorage.getItem("PUSHTELL-bookingEngine");
  if (pushtell) {
    let obj = {
      dateIn,
      dateOut,
      totalCost,
      roomTypeName,
      reservationId,
      reservation: "success",
    };
    if (process.env.REACT_APP_SERVER == "production") {
      mixpanel.track(`${pushtell} - User Reservation Production`, obj);
    } else {
      mixpanel.track(`${pushtell} - User Reservation Staging`, obj);
    }
  }
};

// FOR RESERVATION FAILED -->>
const reservationFailed = () => {
  let pushtell = localStorage.getItem("PUSHTELL-bookingEngine");
  let obj = { reservation: "failed" };
  if (pushtell) {
    if (process.env.REACT_APP_SERVER == "production") {
      mixpanel.track(`${pushtell} - User Reservation Production`, obj);
    } else {
      mixpanel.track(`${pushtell} - User Reservation Staging`, obj);
    }
  }
};

// FOR PAGE  INITIAL VISIT -->>
const initialVisitor = (isInitial) => {
  let pushtell = localStorage.getItem("PUSHTELL-bookingEngine");
  if (pushtell && isInitial) {
    if (process.env.REACT_APP_SERVER == "production") {
      mixpanel.track(`${pushtell} - User Visits Production`);
    } else {
      mixpanel.track(`${pushtell} - User Visits Staging`);
    }
  }
};

export const mixPanelEvents = {
  reservationSuccess,
  reservationFailed,
  initialVisitor,
};
