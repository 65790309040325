import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";

export const reservationCancel = createAsyncThunk(
  "reservationCancel",
  async (
    { reservationId, lastName, CB },
    { getState, rejectWithValue, dispatch }
  ) => {
    // console.log("reservationCancel:", state.reservationId, state.lastName);

    try {
      let { data } = await AXIOS_RESERVATION.get(
        `getreservationofuser?reservationId=${reservationId}&lastName=${lastName}`
      );

      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
        throw data;
      } else {
        CB && CB(data);
        return data;
      }
    } catch (err) {
      // console.log("err:", err.message);
      // return err.message;
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiMessage: err?.result?.message,
          apiSuccess: false,
        },
      });
    }
  }
);
