import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { CloseButton } from "../../commonElements/button";
import { roomSelector } from "../rooms/roomSlice";

const CancellationPolicy = ({ handleClose }) => {
  const { cancellationPolicy } = useSelector(roomSelector);
  return (
    <Fragment>
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full p-6 m-auto bg-white  shadow-md max-w-xl relative mx-5"
      >
        <div className="right-0 absolute mr-6">
          <CloseButton handleClose={handleClose} />
        </div>

        <h1 className="font-normal text-center text-black mt-[2.75rem] text-xl">
          CANCELLATION POLICY
        </h1>

        <p className="font-light text-black text-justify m-5">
          {/* {cancellationPolicy} */}
          Reservations must be cancelled 48 hours prior to day of arrival hotel time to avoid being charged a penalty of one night room and tax. Advanced Purchase reservations are non-refundable.
        </p>
      </div>
    </Fragment>
  );
};

export default CancellationPolicy;
