import React from "react";
import CostSummary from "../costSummary";
import { Count, CustomContainer, Title } from "../../commonElements";
import { Abtest } from "../../mixpanel";

function SectionC({ setQueryDetails, queryDetails }) {
  return (
    <CustomContainer
      className={`bg-main lg:bg-white pt-[32px] pb-[32px] mt-[44px] lg:mt-0 lg:top-[58px] lg:sticky`}
    >
      <Count count={"3"} />
      <Abtest
        VariantA={
          <>
            <Title
              variant={"h1"}
              className={`text-magenta filter-brightness-150`}
              content="complete YOUR BOOKING"
            />
            <p className="text-darkAsh text-[13px] flex justify-center mt-[4px] font-light uppercase mb-[32px]">
              REQUIRED
            </p>
          </>
        }
        VariantB={<div className=" uppercase text-magenta filter-brightness-150 text-center leading-[15.6px] text-[13px] font-light" >complete YOUR BOOKING</div>}
      />
      <CostSummary queryDetails={queryDetails} />
    </CustomContainer>
  );
}

export default SectionC;
