console.log(process.env.REACT_APP_SERVER, "REACT_APP_SERVER");
const config = {
  // RESERVATION_API: 'http://localhost:8006/api',
  // HOSPITALITY_API: 'http://localhost:8001/api',

  RESERVATION_API:
    process.env.REACT_APP_SERVER == "production"
      ? "https://api.reservationapp.andmv.com/api"
      : process.env.REACT_APP_SERVER == "preproduction"
      ? "https://preproduction-api.reservationapp.andmv.com/api"
      : "https://staging-api.reservationapp.andmv.com/api",

  HOSPITALITY_API:
    process.env.REACT_APP_SERVER == "production"
      ? "https://api.hospitality.andmv.com/api"
      : process.env.REACT_APP_SERVER == "preproduction"
      ? "https://preproduction-api.hospitality.andmv.com/api"
      : "https://staging-api.hospitality.andmv.com/api",
};

const RatePlan_Category = {
  corp: "Corporate",
};
const pmsRateExternalCode = {
  advance: "ADVPUR",
};
const cancelRoomApiMsg = "cancel api due to new request POST-(method)-HOTELS";

module.exports = {
  config,
  cancelRoomApiMsg,
  RatePlan_Category,
  pmsRateExternalCode,
};
