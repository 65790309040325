import React, { useEffect, useState } from "react";
import { IconLogo } from "../../icons";
import { useScrollTrigger } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAuthTokenAvailability,
  getAuthData,
  removeAuth,
} from "../../util/cookieReducer";
import {
  setIsToken,
  setUserData,
  signInSelector,
} from "../../features/signIn/signInSlice";
import { removeCardsData } from "../../features/savedCards/savedCardsSlice";
import {
  resetPaymentForm,
  setOpenPayInfo,
  setUserProfileNotFoundGPay,
} from "../../features/costSummary/costSummarySlice";
import { setCollapseEnroll, setIsChecked, setIsCreateNewUser } from "../../features/enroll/enrollSlice";
import { Abtest } from "../../mixpanel";

function Header({ className }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  // webflowWebView 
  const isWebflowWebView = window.location.pathname.includes('webflowWebView')
  console.log('isWebflowWebView:', isWebflowWebView)



  const HeaderCom = () => {
    const { isToken, userData, removeLogoutOption } =
      useSelector(signInSelector);
    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(setIsToken(checkAuthTokenAvailability()));
    //   dispatch(setUserData(getAuthData()));
    // }, [dispatch]);

    const handleLogout = () => {
      dispatch(
        setUserData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNoWithoutCountryCode: "",
        })
      );
      dispatch(
        removeCardsData({
          isSavedCards: false,
          savedCards: [],
          selectedCard: {},
        })
      );
      removeAuth();
      dispatch(setIsToken(false));
      dispatch(setUserData({}));
      dispatch(resetPaymentForm())
      dispatch(setUserProfileNotFoundGPay(false))
      dispatch(setOpenPayInfo(false))
      dispatch(setIsChecked(false))
      dispatch(setIsCreateNewUser(false))
      dispatch(setCollapseEnroll(false))
    };

    return (
      <motion.header
        // initial={{ opacity: 0, top: -60 }}
        // animate={{ opacity: 1, top: 0 }}
        // style={{
        //   backdropFilter:'blur(5px)',
        //   backgroundColor: trigger ? "#ffffff5c" : "white",
        //   color: trigger ? "white" : "black",
        // }}
        // transition={{ duration: 1.2 }}
        className={`${removeLogoutOption ? `h-3` : `h-[72px]`
          } lg:h-[72px] shadow-md 
        bg-main flex items-center 
        ml-auto mr-auto  
        top-0 z-40 w-full px-5 md:px-[50px] lg:px-[80px]`}
      >
        <div
          className={`w-full mx-auto max-w-[86rem] ${removeLogoutOption ? "hidden" : "flex"
            } justify-between items-center `}
        >
          <Abtest
            VariantA={
              <a className={``} href="https://shashihotel.com/">
                <IconLogo />
              </a>
            }
            VariantB={
              <a className={`ml-auto mr-auto`} href="https://shashihotel.com/">
                <IconLogo />
              </a>
            }
          />
          {userData?.email && (
            <button
              className="text-black hover:text-magenta filter-brightness-150"
              onClick={handleLogout}
            >
              Logout
            </button>
          )}
        </div>
      </motion.header>
    );
  };

  return (
    <AnimatePresence>{isWebflowWebView ? null : trigger ? <HeaderCom /> : <HeaderCom />}</AnimatePresence>
  );
}

export default Header;
