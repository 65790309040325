import { createSlice } from "@reduxjs/toolkit";
import { reservationCancel } from "./api";

const initialState = {
  cancelUserData: {},

  isLoading: false,
  error: "",
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

export const reservationCancelSlice = createSlice({
  initialState,
  name: "reservationCancel",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // CASE FOR RESERVASTION CANCEL --->>>
      .addCase(reservationCancel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reservationCancel.fulfilled, (state, { payload }) => {
        // console.log("fulfilled payload:", payload);

        state.cancelUserData = payload;
        state.isLoading = false;
      })
      .addCase(reservationCancel.rejected, (state, { payload }) => {
        // console.log("rejected payload:", payload);
        state.status = payload.status;
        // state.error = payload;
        state.isLoading = false;
      });
  },
});

export default reservationCancelSlice.reducer;
export const { setStatus } = reservationCancelSlice.actions;
export const reservationCancelSelector = (state) => state.reservationCancel;
