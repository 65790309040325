import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isMobileScreen: true,
};
const themeSlice = createSlice({
  initialState,
  name: "theme",
  reducers: {
    setIsMobileScreen: (state, action) => {
      state.isMobileScreen = action.payload;
    },
  },
});
export default themeSlice.reducer;
export const { setIsMobileScreen } = themeSlice.actions;
export const themeSelector = (state) => state.theme;
