import { Field, ErrorMessage, useField } from "formik";
import "./styles.css";

export const ErrorMsg = ({ name }) => (
  <ErrorMessage
    name={name}
    component="div"
    className="text-red-500 text-xs mt-1 absolute"
  />
);

export const Input = ({
  name = "",
  type = "text",
  placeholder = "",
  redStar = null,
  autoComplete = "off",
  disableField = false,
  value,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div className="mb-[29px] w-full relative">
      <Field
        {...props}
        aria-label={name}
        type={type}
        placeholder={placeholder}
        name={name}
        autoComplete={autoComplete}
        disabled={disableField}
        value={value}
        className="block w-full h-[42px] placeholder-center px-4 text-black bg-white rounded-none border border-gray-400 focus:outline-none appearance-none"
      />
      {redStar && !meta.touched && (
        <span className="absolute left-2 top-0 h-full flex items-center">
          <span className="text-red-500 mr-1">{redStar}</span>
        </span>
      )}
      <ErrorMsg name={name} />
    </div>
  );
};
