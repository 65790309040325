import React, { useEffect, useState } from "react";
import "./loader.css";
const Loader = (props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3600);

    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <div
      className={`fixed top-0 w-full h-full flex items-center justify-center bg-white z-50 ${
        show ? "block" : "hidden"
      }`}
    >
     <img
        src="/animation_300_l7spra2w.gif"
        alt="loading spinner"
        className="object-contain w-24 md:w-28 lg:w-32"
      />
    </div>
  );
};
export default Loader;
