import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../theme/themeSlice";
import { roomSelector } from "../rooms/roomSlice";
import { RoomCard, RoomCardSkeleton } from "../../components";
import { string } from "yup";
import { Collapse } from "@mui/material";

function AvailableRoom({
  for_DK_PK = false,
  showAllRoom = false,
  showAccessibleRoom = false,
  showNotAccessibleRooms = false,
  setAccessibleRoomCollapse,
  isCollapse,
  TitleComp,
  ...props
}) {
  const { isMobileScreen } = useSelector(themeSelector);
  const { roomType, loading, selectedRoom } = useSelector(roomSelector);
  let flag = 0
  // filter array -->>
  let tempArray =
    roomType?.length > 0 &&
    roomType.filter((e) => {
      if (showAllRoom) {
       
        if (selectedRoom.length > 0) {
          return !e.default
        } else if (for_DK_PK) {
          if (e.code == "DK" || e.code == "PK") {
            flag = 1;
          }
          if (e.code !== "DK" && e.code !== "PK" && flag == 1) {
            return e 
          } else if (flag == 0 && e.code !== "DKK") {
            return e 
          }
          
        } else if(!for_DK_PK) {
          return e;
        }
      } else if (for_DK_PK) {
        if (e.code == "DK" || e.code == "PK") {
          flag = 1
          return e
        } else if (flag == 0 && e.code == "DKK") {
          return e
        }
       } else if (
        showNotAccessibleRooms &&
        !e.name?.toLowerCase()?.includes("accessible")
      ) {
        return e;
      } else if (
        showAccessibleRoom &&
        e.name?.toLowerCase()?.includes("accessible")
      ) {
        return e;
      }
    });
  
  useEffect(() => {
    if (!tempArray?.length > 0 && showNotAccessibleRooms) {
      setAccessibleRoomCollapse(true);
    } else if (tempArray?.length > 0 && showNotAccessibleRooms) {
      setAccessibleRoomCollapse(false);
    }
  }, [roomType]);

  return (
    <Fragment>
      {/* AVAILABLE ROOM  */}
      {TitleComp && tempArray.length > 0 ? TitleComp : <></>}
      {showNotAccessibleRooms ? (
        !loading ? (
          tempArray.length > 0 ? (
            tempArray.map((sltdRm, index) => (
              <Fragment key={index}>
                <RoomCard
                  callback={props?.callback}
                  uniqueTag={"SELECTED_ROOM_"}
                  isRoomChangeFlag={false}
                  selectRoomFlag={true}
                  RoomDetails={sltdRm}
                  index={index}
                />
              </Fragment>
            ))
          ) : (
            <></>
          )
        ) : (
          <RoomCardSkeleton />
        )
      ) : (
        <Collapse timeout={2000} in={isCollapse}>
          {!loading ? (
            tempArray.length > 0 ? (
              tempArray.map((sltdRm, index) => (
                <Fragment key={index}>
                  <RoomCard
                    callback={props?.callback}
                    uniqueTag={"SELECTED_ROOM_"}
                    isRoomChangeFlag={false}
                    selectRoomFlag={true}
                    RoomDetails={sltdRm}
                    index={index}
                  />
                </Fragment>
              ))
            ) : (
              <></>
            )
          ) : (
            <RoomCardSkeleton />
          )}
        </Collapse>
      )}
    </Fragment>
  );
}

export default AvailableRoom;
