import React, { Fragment } from "react";
import { RoomCard, RoomCardSkeleton } from "../../components";
import { useSelector } from "react-redux";
import { roomSelector } from "../rooms/roomSlice";

function SelectedRoom() {
  const { loading, selectedRoom } = useSelector(roomSelector);

  if (selectedRoom?.length > 0 && loading) return <RoomCardSkeleton />;

  if (selectedRoom?.length > 0)
    return selectedRoom.map((sltdRm, index) => (
      <Fragment key={index}>
        <RoomCard
          isSelectedRoom={true}
          uniqueTag={"SELECTED_ROOM_"}
          RoomDetails={sltdRm}
          index={index}
          
        />
      </Fragment>
    ));
}

export default SelectedRoom;
