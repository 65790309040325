import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setShashiCashTermsAndCondition } from "./enrollSlice";
import { FiCheck } from "react-icons/fi";
import sweetAlert from "sweetalert";
import { getAuthToken } from "../../util/cookieReducer";
import { UpdateLoyalty } from "./api";
import { AnimatePresence, motion } from "framer-motion";
import { IconTick } from "../../icons";
function ExistingUserEnroll({ userData,isEnrollUiVersion2=false }) {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  const Enroll = () => {
    const updateLoyalityValue = {
      isLoyaltyApply: true,
      email: userData.email,
    };
    dispatch(UpdateLoyalty({ updateLoyalityValue, token: getAuthToken() }));
  };

  return userData?.isLoyaltyApply ? (
    <>
      {isEnrollUiVersion2 && <div className="h-[12px]" />}
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.3 }}
      className={`text-xs font-light ${isEnrollUiVersion2?`text-black`:'text-white'}  flex justify-between items-center`}
    >
      <p>{userData?.email}</p>
      <div
        className="
      aspect-[1/1] rounded-full w-10 h-10
      bg-[#FE0097] flex items-center
      justify-center ml-2"
      >
        <IconTick />
      </div>
    </motion.div>
    </>
    
  ) : (
      <>
        {isEnrollUiVersion2&& <div className="h-[3px]" />}
        <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.3 }}
      className="flex flex-nowrap justify-between items-center enroll-terms"
    >
      <div className="flex">
        <label className={`${isEnrollUiVersion2?'text-black':'text-white'} font-light`} htmlFor="">
        By enrolling I agree to &nbsp;
          <span
            onClick={() => dispatch(setShashiCashTermsAndCondition(true))}
            className="underline cursor-pointer"
          >
            Shashi Cash Terms & Conditions.
          </span>
        </label>
      </div>
      <div
        onClick={Enroll}
          className="enroll-btn
          aspect-[1/1] rounded-full w-10 h-10 
          bg-[#FE0097] text-white flex items-center 
          justify-center cursor-pointer ml-1"
      >
        Enroll
      </div>
    </motion.section>
      </>
  );
}

export default ExistingUserEnroll;
