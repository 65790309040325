import React from "react";

import { Button } from "../../commonElements";
import { Box } from "@mui/material";
import { ReservationCancelFailedIcon } from "../../icons";
import { useNavigate, useParams } from "react-router-dom";
import { reservationCancelSelector } from "./reservationCancelSlice";
import { useSelector } from "react-redux";

const ReservationCancelFailed = () => {
  const { cancelUserData } = useSelector(reservationCancelSelector);

  const navigate = useNavigate();

  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="flex items-center justify-center w-full h-auto p-4"
    >
      <div
        className="w-full p-[15px] md:p-[27px] bg-white"
        style={{ maxWidth: "435px" }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="flex items-center justify-center mb-[37px]">
            <ReservationCancelFailedIcon />
          </div>

          <h3 className="font-light text-center text-black mt-[26px] text-[13px] md:text-[16px]">
            We're sorry, but we encountered an <br /> error while processing
            your cancellation <br /> request. Please try again later.
          </h3>

          <h3 className="font-light text-center text-black mt-[26px] text-[13px] md:text-[16px]">
            If the issue persists, <br /> please contact Front Desk at <br />
            <a href="tel:+6504202600" className="underline">
              (650) 420-2600
            </a>
          </h3>
        </Box>

        <Button
          type={"button"}
          className={"mt-[69px] bg-white text-black border border-black"}
          name={"Retry Cancellation"}
          spanClassName={"font-normal"}
          onClick={() =>
            navigate(`/reservation_cancel_details`, {
              state: cancelUserData,
            })
          }
        />
      </div>
    </div>
  );
};

export default ReservationCancelFailed;
