import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../config";
import { AXIOS_HOSPITALITY, AXIOS_RESERVATION } from "../../util/axiosInterceptors";

export const fetchpaymentDetails = createAsyncThunk(
  "stripe/paymentDetails",
  async (state, { rejectWithValue }) => {
    try {
      let hostName = window.location.hostname.split('.')
      let domain = hostName.at(-2) + '.' + hostName.at(-1);
      if (window.location.href.includes('localhost')) {
        domain = 'shashihotel.com'
      }
      console.log('domain:', domain)
      let response = await AXIOS_RESERVATION.get(`v1/hotels/${domain}`);
      if (response?.data.success == true) {
        // ADD COMMON HEADER -->>
        AXIOS_RESERVATION.defaults.headers.common['hotelId'] = response?.data?.result?.data?.hotelId
        AXIOS_RESERVATION.defaults.headers.common['hotelCode'] = response?.data?.result?.data?.hotelCode

        AXIOS_HOSPITALITY.defaults.headers.common['hotelId'] = response?.data?.result?.data?.hotelId
        AXIOS_HOSPITALITY.defaults.headers.common['hotelCode'] = response?.data?.result?.data?.hotelCode
        // ADD COMMON HEADER -->>

        return response?.data?.result?.data;
      } else {
        throw { success: false, message: response?.data?.message };
      }
    } catch (error) {
      if (state?.callback) {
        state?.callback();
      }
      throw rejectWithValue({
        status: {
          apiSuccess: error.success,
          apiMessage:
            error.message || "Something went wrong please try again later",
        },
      });
    }
  }
);
