import { Skeleton } from "@mui/material";
import React from "react";
import { CustomBox } from "../../commonElements";

const SkeletonCircular = () => (
  <div className="flex flex-col items-center w-10 mb-4 mt-4">
    <Skeleton
      sx={{ bgcolor: "#e0e0e0", width: "39px", height: "39px" }}
      variant="circular"
    />
    <Skeleton
      variant="text"
      sx={{ bgcolor: "#e0e0e0", width: "27px", height: "17px", mt: "5px" }}
    />
  </div>
);

const SkeletonPoints = ({width}) => (
    <div className="flex flex-nowrap items-center mt-1">
      <Skeleton
        sx={{ bgcolor: "#e0e0e0", width: "8px",height: "8px" }}
        variant="circular"
      />
      <Skeleton
        variant="text"
        sx={{ bgcolor: "#e0e0e0", width:width||'150px',height: "14px",marginLeft:"10px" }}
      />
    </div>
  );

export default function PkgWithDecSkeleton() {
  return (
    <CustomBox sx={{ padding: "18px",marginBottom:'35px' }}>
      <Skeleton
        sx={{ bgcolor: "#e0e0e0", width: "220px", ml: "7px" }}
        variant="text"
          />
          <SkeletonCircular />
          <SkeletonPoints width={'110px'} />
          <SkeletonPoints width={'140px'}/>
          <SkeletonPoints width={'70px'}/>
          <SkeletonPoints width={'120px'}/>
          <SkeletonPoints />
          
    </CustomBox>
  );
}
