import React, { Fragment, useState } from "react";
import { CoverImage } from "..";
import { motion } from "framer-motion";
import {
  selectRoom,
  showAllRooms,
  roomSelector,
  isOpenDescription,
  setRoomTypeId,
  setHandleOpen,
  triggerRoomApi,
} from "../../features/rooms/roomSlice";
import { Collapse } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { SwiperSlide } from "swiper/react";
import { RxDotsHorizontal } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { BackDrop, CustomBox, CustomSwiper } from "../../commonElements";
import {
  setCancellationIsOpen,
  setSignInOpen,
  setTermsAndConditionIsOpen,
} from "../../features/costSummary/costSummarySlice";
import { signInSelector } from "../../features/signIn/signInSlice";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Abtest } from "../../mixpanel";

const LabelWithData = (props) => (
  <div className={`flex flex-col justify-between ${props.className}`}>
    {props.label ? (
      <>
        {props.isLinkTag ? (
          <a
            href="inapp://RoomTypeChange"
            onClick={props.onClick}
            className={`text-[10px] pt-[4px] pb-[2px] uppercase ${
              !props.isRoomChangeFlag && `${props.childClassName}`
            }`}
          >
            {props.label}
          </a>
        ) : (
          <div
            onClick={props.onClick}
            className={`text-[10px] pt-[4px] pb-[2px] uppercase ${
              !props.isRoomChangeFlag && `${props.childClassName}`
            }`}
          >
            {props.label}
          </div>
        )}
      </>
    ) : (
      <div />
    )}

    <p className="mt-[12px] capitalize" children={props.data} />
  </div>
);

function RoomCard({
  RoomDetails,
  isSelectedRoom = false,
  selectRoomFlag = false,
  uniqueTag,
  index,
  ...props
}) {
  const { avgRate, name, roomDescription, images, roomFeatures } = RoomDetails;
  const { descriptionOpenKey, selectedRoom } = useSelector(roomSelector);
  const { removeLogoutOption } = useSelector(signInSelector);
  const { isRoomChangeFlag } = props;
  const [isOpen, setIsOpen] = React.useState();
  // const [state, setState] = useState("");

  const dispatch = useDispatch();

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setHandleOpen(true));
  };

  const findAmenity = (type) => {
    if (!roomFeatures?.length > 0) return "";

    if (type == "Size") {
      var findroomFeature = roomFeatures?.find((obj) => obj.type == type);
      return findroomFeature?.quantity + findroomFeature?.amenityName || "";
    } else if (type == "Beds") {
      return (
        roomFeatures?.find((obj) => obj?.type == "Beds")?.amenityName || ""
      );
    }
  };

  const ChildSection = ({ className }) => (
    <div className={className}>
      <div
        className="
      flex justify-between pl-[14px] 
      pr-[14px] pt-[11px] pb-[11px]"
      >
        <LabelWithData
          label={"ROOM TYPE"}
          data={name}
          className={"items-start"}
        />
        <Abtest
          VariantA={
            <LabelWithData
              // isLinkTag={removeLogoutOption}
              isRoomChangeFlag={isRoomChangeFlag}
              onClick={() => {
                if (isRoomChangeFlag) return;
                dispatch(showAllRooms(true));
              }}
              label={isRoomChangeFlag ? `price` : `CHANGE`}
              childClassName={`text-magenta filter-brightness-150 border-[1px] border-magenta px-[14px] cursor-pointer`}
              data={`$${parseFloat(avgRate).toFixed(2)}`}
              className={"items-end"}
            />
          }
          VariantB={
            isSelectedRoom ? (
              <LabelWithData
                label={""}
                childClassName={`
        bg-magenta font-light text-white filter-brightness-150
         border-[1px] border-magenta px-[14px] h-full`}
                data={`$${parseFloat(avgRate).toFixed(2)}`}
                className={"items-end"}
              />
            ) : selectRoomFlag ? (
              <LabelWithData
                // isLinkTag={removeLogoutOption}
                isRoomChangeFlag={isRoomChangeFlag}
                onClick={(e) => {
                  if (props?.callback) props.callback();
                  e.stopPropagation();
                  dispatch(selectRoom(RoomDetails));
                  dispatch(setRoomTypeId(RoomDetails._id));
                  dispatch(triggerRoomApi());
                }}
                label={``}
                childClassName={`
        text-magenta filter-brightness-150
        border-[1px] border-magenta px-[14px] cursor-pointer`}
                data={`$${parseFloat(avgRate).toFixed(2)}`}
                className={"items-end"}
              />
            ) : (
              <Fragment />
            )
          }
        />
      </div>

      <CustomSwiper>
        {images?.length &&
          images.map(({ source, type }, ImgInd) => (
            <SwiperSlide
              onClick={(e) => e.stopPropagation()}
              key={ImgInd}
              className="w-full h-full cursor-default"
              lazy={true}
              children={<CoverImage alt={type} src={source} />}
            />
          ))}
      </CustomSwiper>

      <div >
        <div
          className="flex justify-between 
        items-center px-[16.96px]"
        >
          <motion.p
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            className="
        text-[11px] leading-[1.5]
        mt-[11px] mb-[11px] h-[16.8px]"
            children={findAmenity("Beds")}
          />

          <motion.p
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            className={`text-[11px] leading-[1.5]`}
            children={findAmenity("Size")}
          />
          {/* IF USER CLICK CHANGE ROOM BTN.
         NEED TO SHOW SELECT BTN */}
          <Abtest
            VariantA={
              isRoomChangeFlag ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(selectRoom(RoomDetails));
                    dispatch(setRoomTypeId(RoomDetails._id));
                    dispatch(triggerRoomApi());
                    // if (removeLogoutOption) {
                    //   window.location.href = "inapp://RoomTypeChangeDone";
                    // }
                  }}
                  className="
                w-[88px] 
                font-medium h-[21px] text-[10px] bg-white text-black border border-black"
                >
                  Select
                </button>
              ) : (
                <RxDotsHorizontal
                  onClick={handleOpen}
                  className="
            text-[14px] leading-[13px] 
            cursor-pointer"
                />
              )
            }
            VariantB={
              <div
                className=" flex flex-nowrap items-center
                text-[11px] leading-[13px] 
        cursor-pointer"
                onClick={handleOpen}
              >
                Amenities{" "}
                <MdOutlineArrowForwardIos className="text-[11px] ml-[3px]" />
              </div>
            }
          />
        </div>

        <Abtest
          VariantA={<></>}
          VariantB={
            <>
              {RoomDetails?.packageDescription?.length > 0 ? <div className="description-rmCard mt-[9px] mb-[12px] px-[13px]">
              {
                  RoomDetails?.packageDescription.map((chld) => (
                    <div className="flex flex-nowrap flex-row leading-[18px]">
                      <span className="text-white   mr-[4px] font-light text-[13px] ">
                        +
                      </span>
                      <p
                        className={` text-start text-[0.75rem] font-light text-white`}
                      >
                        {chld}
                      </p>
                    </div>
                  ))}
              </div> : <div className="mb-[16px]"/>}
              
              {isSelectedRoom ? (
                <div className="px-[12px]">
                <button className="bg-magenta text-white w-full h-[35px]  mb-[12px] border border-magenta filter-brightness-150 uppercase">
                  Selected
                  </button>
                  </div>
              ) : selectRoomFlag ? (
                <div className="px-[12px]">
                <button onClick={(e) => {
                  if (props?.callback) props.callback();
                  e.stopPropagation();
                  dispatch(selectRoom(RoomDetails));
                  dispatch(setRoomTypeId(RoomDetails._id));
                  dispatch(triggerRoomApi());
                  }}
                  className="bg-white text-magenta w-full h-[35px]  mb-[12px] border border-magenta filter-brightness-150 uppercase">
                  Select
                    </button>
                </div>
              ):<Fragment/>}
            </>
          }
        />
      </div>

      {/* <BackDrop handleClose={() => setIsOpen(false)} open={isOpen}>
    <RoomDescription handleClose={() => setIsOpen(false)} />
  </BackDrop> */}

      {/* <Collapse
    in={descriptionOpenKey == uniqueTag + index ? true : false}
    timeout={700}
    unmountOnExit
    children={
      <p
        className={`
        pl-[14px] pr-[14px] mt-[15px] 
        mb-[26px] text-[11px] font-light
        overflow-hidden relative`}
      >
        {roomDescription}
      </p>
    }
  /> */}
    </div>
  );

  return (
    <CustomBox
      className={`room-type-baseDiv text-black ${
        isRoomChangeFlag ? `cursor-pointer` : ``
      }`}
      sx={{ marginTop: "32px" }}
    >
      <Abtest
        VariantA={<ChildSection />}
        VariantB={<ChildSection className={"bg-black text-white"} />}
      />
    </CustomBox>
  );
}
export default RoomCard;
