import { fetchRatePlanRoomType } from "./api";

export const roomExtraReducers = (builder) => {
  builder
    .addCase(fetchRatePlanRoomType.pending, (state) => {
      state.loading = true;
      state.optionLoader = true;
      state.status = {
        apiMessage: "",
        apiSuccess: true,
      };
    })
    .addCase(fetchRatePlanRoomType.fulfilled, (state, { payload }) => {
      if (!payload.status.apiSuccess) {
        state.corpCode = "";
      }

      state.cancellationPolicy = payload.cancellationPolicy;
      state.isRoomAvailable =payload.isRoomAvailable;
      state.selectedRoom = payload.selectedRoom;
      state.allOptions = payload.allOptions;
      state.firstAllOptions = payload.allOptions;
      state.isCorpCode = payload.isCorpCode;
      state.ratePlans = payload.ratePlans;
      state.roomType = payload.roomType;
      state.timeSpan = payload.timeSpan;
      state.status = payload.status;
      state.ratePlanCategory = "";
      state.optionLoader = false;
      state.packageDisabledIds = []
      
      if (Object.keys(payload.groupCodeDetails).length > 0) {
        state.groupCode = payload.groupCodeDetails.groupCode;
        state.groupCodeDetails = payload.groupCodeDetails;
      }

      state.loading = false;
    })
    .addCase(fetchRatePlanRoomType.rejected, (state, { payload }) => {
      state.loading = payload.loading || false;
      state.optionLoader = payload.optionLoader || false;
      state.status = payload?.status || { apiMessage: "", apiSuccess: true };
      state.isRoomAvailable = payload.isRoomAvailable;
    });
};
