import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
function BubbleAnimate({ children }) {
    const ref = useRef(null);
    const isInView = useInView(ref);
  return (
    <motion.div
      className="w-fit"
      style={{
        transitionDelay: "0.2s",
        transitionDuration: "0.8s",
        transform: isInView ? "scale(1)" : "scale(0.8)",
        opacity: isInView ? 1: 0.7,
      }}
      ref={ref}
    >
      {children}
    </motion.div>
  );
}

export default BubbleAnimate;
