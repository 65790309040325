import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { getDisabledDates } from "./api";

const initialState = {
  isLoading: false,
  disabledDatesArray: [],
  arrivalDate:
    // (> 1) REPRESENT GREATER THAN MONDAY
    moment().day() > 1
      ? moment()
          .startOf("isoWeek")
          .add(1, "week")
          .day(1)
          .startOf("date")
          .add(15, "hour")
          .format("X")
      : moment().day() == 1
      ? moment().startOf("date").add(15, "hour").format("X")
      : moment().add(1, "day").startOf("date").add(15, "hour").format("X"),
  departureDate:
    // (> 1) REPRESENT GREATER THAN MONDAY
    moment().day() > 1
      ? moment()
          .startOf("isoWeek")
          .add(1, "week")
          .day(4)
          .startOf("date")
          .add(15, "hour")
          .format("X")
      : moment().day() == 1
      ? moment().add(3, "day").startOf("date").add(15, "hour").format("X")
      : moment().add(4, "day").startOf("date").add(15, "hour").format("X"),
  isDateSingleSelect: false,
  isDefaultDate:true,
};

export const calenderSlice = createSlice({
  name: "calender",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.isDefaultDate = false;
      state.arrivalDate = action.payload[0];
      state.departureDate = action.payload[1];
    },
    setIsDateSingleSelection:(state,{payload})=>{
      state.isDateSingleSelect = payload
    },
    setNextWeek: (state, { payload }) => {
      const { arrivalDate, departureDate } = payload;
      state.arrivalDate = arrivalDate;
      state.departureDate = departureDate;
    },
  },

  extraReducers: (builder) => {
    builder
      // CASE FOR DISABLED DATES --->>>
      .addCase(getDisabledDates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDisabledDates.fulfilled, (state, { payload }) => {
        // console.log("fulfilled payload:", payload);

        state.disabledDatesArray = payload;
        state.isLoading = false;
      })
      .addCase(getDisabledDates.rejected, (state, { payload }) => {
        // console.log("rejected payload:", payload);
        state.status = payload.status;
        // state.error = payload;
        state.isLoading = false;
      });
  },
});

export const { setDate,setIsDateSingleSelection, setNextWeek } = calenderSlice.actions;

export const calendarSelector = (state) => state.calender;

export const setDateFormat = (date) => (dispatch, getState) => {
  // store arrival and departure date
  dispatch(
    setDate([
      moment(date[0]).startOf("date").add(15, "hour").format("X"),
      moment(date[1]).endOf("date").subtract(9, "hour").format("X"),
    ])
  );
};

export const DateChangetoNextWeek = (date) => (dispatch, getState) => {
  const { calender } = getState();
  dispatch(
    setNextWeek({
      arrivalDate: moment.unix(calender.arrivalDate).add(1, "week").format("X"),
      departureDate: moment.unix(calender.departureDate).add(1, "week").format("X"),
    })
  );
};

export default calenderSlice.reducer;