import { CustomBox } from "../../commonElements";
import BubbleAnimate from "./bubbleAnimate";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { IconTick } from "../../icons";
import { motion } from "framer-motion";
import React from "react";

function SelectWithDescrip({
  requestBodyKey,
  onClickMethod,
  darkMode,
  options,
  title,
}) {
  const dispatch = useDispatch();
  return (
    <CustomBox darkMode={darkMode} className={`mb-[35px] flex flex-col p-4`}>
      <Typography className={`text-white`} variant="h3" children={title} />
      <div className="flex gap-[17px] flex-wrap">
        {options?.length &&
          options.map(({ title, description, tag, isSelected }, index) => (
            <div key={index}>
              <BubbleAnimate>
                <div
                  onClick={() => {
                    dispatch(onClickMethod({ requestBodyKey, index }));
                  }}
                  className={`bg-magenta
              text-white border-magenta filter-brightness-150  border-[0.5px]
                w-[42px] h-[42px] rounded-full 
                flex flex-col justify-center 
                text-center 
                font-light mt-[16px] cursor-pointer-desktop duration-700`}
                >
                  {isSelected ? (
                    <motion.div
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.7 }}
                    >
                      <IconTick />
                    </motion.div>
                  ) : (
                    <motion.span
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.7 }}
                      className={` text-[10px] font-normal leading-[19px] ml-auto mr-auto`}
                    >
                      {title}
                    </motion.span>
                  )}
                </div>
              </BubbleAnimate>
              <p
                className={`mt-[8px] text-[0.8125rem]  w-[39px] text-center ] first-line:text-white mb-4`}
                // className={`mt-[8px] text-[13px]  w-[39px] text-c2px solidenter first-line:text-white `}
              >
                {tag}
              </p>
              {/* {description ? (
                <p
                  className={`mt-[19px] text-start text-[0.75rem] font-light whitespace-pre ${
                    darkMode ? "text-white  leading-[1.5]" : "text-black"
                  }`}
                >
                  {description}
                </p>
              ) : null} */}
              {description
                .split("+")
                .slice(1)
                .map((e) => (
                  <div className="flex flex-nowrap flex-row">
                    <span className="text-white   mr-[4px] font-light text-[13px] ">
                      +
                    </span>
                    <p
                      className={` text-start text-[0.75rem] font-light ${
                        darkMode ? "text-white  leading-[1.5]" : "text-black"
                      }`}
                    >
                      {e}
                    </p>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </CustomBox>
  );
}

export default SelectWithDescrip;
