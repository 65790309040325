import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";

export const getDisabledDates = createAsyncThunk(
  "calender/getDisabledDates",
  async (state, { getState, rejectWithValue, dispatch }) => {
    const { dateRange, CBhandleDD, queryDetails } = state;
    const { room, stripe } = getState()
    const {hotelDetails} = stripe
    console.log("dateRange:", dateRange);

    try {
      let { data } = await AXIOS_RESERVATION.post(
        `v1/getCalendarDetails/${hotelDetails?.hotelCode}`,
        {
          children: queryDetails?.children || "",
          dateRange, adults: queryDetails?.adults || "",
          roomTypeCode: room?.selectedRoom?.length>0?room?.selectedRoom[0].roomTypeCode:"",
          ratePlanType: room?.selectedRatePlan?.ratePlanType || "",
          ratePlanCode: room?.selectedRatePlan?.ratePlanCode || ""
        }
      );

      // console.log("data:", data);

      if (data?.success == false) {
        throw data;
      } else {
        CBhandleDD && CBhandleDD(data.data.dates);
        return data;
      }
    } catch (err) {
      // console.log("err:", err.message);

      throw rejectWithValue({
        status: {
          apiMessage: err?.result?.message,
          apiSuccess: false,
        },
      });
    }
  }
);
