import { Experiment, Variant } from "@marvelapp/react-ab-test";
import React from "react";

export const Abtest = ({ VariantA, VariantB }) => {
  return VariantB
  return (
    <Experiment name="bookingEngine">
      <Variant name="A">{VariantA}</Variant>
      <Variant name="B">{VariantB}</Variant>
    </Experiment>
  );
};

export default Abtest;
