import React from "react";
import { IoIosArrowDown } from "react-icons/io";

function CollapseTitle({
  openLabel,
  closeLable,
  flag,
  label = flag ? openLabel : closeLable,
  className,
  ...props
}) {
  return (
    <div
      {...props}
      className={` ${className} w-fit flex flex-nowrap items-center gap-1 text-base`}
    >
      {label}
      <IoIosArrowDown
        className={`${flag && `rotate-180`} duration-700`}
        size={18}
      />
    </div>
  );
}

export default CollapseTitle;
