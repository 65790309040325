import React from 'react';
import './skipLink.css'
function SkipLink() {
  return (
    <a href="#main" className="sr-only">
      Skip to main content
    </a>
  );
}

export default SkipLink;