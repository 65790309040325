import React, { useEffect, useState } from "react";
import { BiBadgeCheck } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { AppStoreIcon, GooglePayIcon } from "../../icons";
import VerificationLayout from "../../layout/VerificationLayout";
import { verifyUserEmail } from "../../util/verify-user-email";

function VerifyUser() {
  //   const [message, setMessage] = useState({ status: null, message: "" });
  //   const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    console.log("params", searchParams.get("code"));

    verifyUserEmail(searchParams.get("code"))
      .then((data) => {
        console.log("dataa", data);
        setMessage({
          status: true,
          message: "Your account has been verified successfully.",
        });
      })
      .catch((error) => {
        console.log("error msg", error);
        setMessage({
          status: false,
          message: "Sorry, Your account could not be verified",
        });
      });
  }, []);

  const handleClick = () => {
    window.location.href = "https://shashihotel.com/";
  };

  console.log("message message", message);

  return (
    <section className="mt-10 mb-10 ml-auto mr-auto text-center ">
      {message.status === true ? (
        <>
          <div className="grid place-content-center">
            <BiBadgeCheck
              style={{ color: "#38AD75", fontWeight: "bold", fontSize: "80px" }}
            />
          </div>
          <h3 className="font-medium">Congratulations!</h3>
          <h3 className="font-medium text-center">{message.message}</h3>

          <hr
            style={{
              borderBottom: "1px solid #161616",
              color: "#e5e5e5",
              margin: "0px 0 40px 0",
            }}
          />

          {/* {!getAuthToken() && ( */}
          <label
            onClick={handleClick}
            className="ml-1 font-medium whitespace-nowrap cursor-pointer mt-5 bg-black text-white pl-3 pr-3 pt-1 pb-1 rounded"
          >
            Back To Shashi
          </label>
          {/* )} */}

          {/* <hr style={{ color: "#e5e5e5" }} className="br-underline mt-10" /> */}
          <p className="mt-[30px] mb-[-10px] text-center">
            For more personalised experience download our app
          </p>
          <div className="flex gap-3 m-[0px] justify-center">
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://apps.apple.com/in/app/shashi-hospitality-unplugged/id1458214587"
            >
              <div
                className="w-120 md:w-120
                  lg:w-136 relative aspect-[10/3]"
              >
                <AppStoreIcon />
              </div>
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.shashigroup.reservationapp"
            >
              <div
                className="w-120 md:w-120
                  lg:w-136 relative aspect-[10/3]"
              >
                <GooglePayIcon />
              </div>
            </a>
          </div>
        </>
      ) : message.status === false ? (
        <>
          <h2 style={{ color: "red" }}>Uh, Oh!</h2>
          <h3 className="font-medium text-center">{message.message}</h3>
        </>
      ) : (
        <></>
      )}
      <style>
        <style>
          {`
        .br-underline{
          height:1px;
          width:100%;
          max-width:900px;
        }
        
        h2{
          font-size:2.2em;
          font-weight:500;
        }
        h3{
          font-size:1.624em;
          font-weight:400;
        }
        @media(max-width:650px){
          h3{
              font-size:1.2em;
              font-weight:400;
          }
          h2{
              font-size:1.8em;
              font-weight:500;
          }
        }
        .verify-main-tag{
          height:calc(100vh - 53.59px);
        }
        @media(min-width:410px){
          .verify-main-tag{
            height:calc(100vh - 58.4px);
          }
        }
        @media(min-width:540px){
          .verify-main-tag{
            height:calc(100vh - 60.8px);
          }
        }
        @media(min-width:768px){
          .verify-main-tag{
            height:calc(100vh - 68.8px);
          }
        }
        @media(min-width:1280px){
          .verify-main-tag{
            height:calc(100vh - 73.59px);
          }
        }
        @media(min-width:1536px){
          .verify-main-tag{
            height:calc(100vh - 83.2px);
          }
        }
        `}
        </style>
      </style>
    </section>
  );
}
// }

export default VerifyUser;
