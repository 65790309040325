import { createSlice } from "@reduxjs/toolkit";
import { getSavedCards } from "./api";

const initialState = {
  isSavedCards: false,
  savedCards: [],
  selectedCard: {},
  isCardSelected: false,
  openCardOptions: false,
  addNewCard: false,
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

export const savedCardsSlice = createSlice({
  initialState,
  name: "savedCards",
  reducers: {
    setOpenCardOptions: (state, { payload }) => {
      state.openCardOptions = payload;
    },
    removeCardsData: (state, { payload }) => {
      state.isSavedCards = false;
      state.savedCards = [];
      state.selectedCard = {};
      state.isCardSelected = false;
    },
    setAddNewCard: (state, { payload }) => {
      state.addNewCard = payload;
    },
    selectCard: (state, { payload }) => {
      let card = payload || {};
      if (Object.keys(card).length > 0) {
        state.selectedCard = payload;
        state.isCardSelected = true;
      } else {
        state.selectedCard = card;
        state.isCardSelected = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // CASE FOR USER SAVED CARDS --->>>
      .addCase(getSavedCards.pending, (state) => {
        state.isLoading = true;
        state.isSavedCards = false;
        state.savedCards = [];
        state.selectedCard = {};
        state.isCardSelected = false;
      })
      .addCase(getSavedCards.fulfilled, (state, { payload }) => {
        state.isSavedCards = payload.savedCards.length ? true : false;
        state.savedCards = payload.savedCards;
        state.status = payload.status;
        payload.savedCards.map((card) => {
          if (card.isPrefered) {
            state.selectedCard = card;
            state.isCardSelected = true;
          }
        });
      })
      .addCase(getSavedCards.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});
export default savedCardsSlice.reducer;
export const saveCardsSelector = (state) => state.savedCards;
export const {
  setOpenCardOptions,
  removeCardsData,
  setAddNewCard,
  selectCard,
} = savedCardsSlice.actions;
