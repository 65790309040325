import { styled } from "@mui/system";
import React, { Fragment } from "react";
import { SkipLink } from "../commonElements";
import Header from "./Header";
import { useSelector } from "react-redux";
import { signInSelector } from "../features/signIn/signInSlice";

function Layout({ children }) {
  const Main = styled("main")({});
  return (
    <Fragment>
      {/* IF HEADER AND MAIN COMPONENT HEIGHT CHANGE h-[58px]. 
      SAME TIME NEED TO CHANGE AVAILABLE ROOM COMPONENT PADDING TOP */}
      <SkipLink/>
      <Header />
      <Main
        sx={{minHeight:`calc(100vh - 72px)`}}
        className="lg:pb-[58px] bg-white lg:bg-main  flex"
        children={children}
      />
    </Fragment>
  );
}

export default Layout;
