import React from "react";
import {
  setCorpCode,
  resetRatePlan,
  setRatePlanCode,
  setIsCorpCategory,
  setCancellationPolicy,
  setSelectedRatePlan,
  collapseRatePlan,
  resetCorpCode,
  roomSelector,
} from "../rooms/roomSlice";
import { useSelector } from "react-redux";
import { RatePlan, RatePlanSkelton } from "../../components";

function RateplanComp() {
  const {
    loading,
    ratePlans,
    isCorpCategory,
    isCollapseRatePlan,
    selectedRatePlan,
    ratePlanCode,
    corpCode,
  } = useSelector(roomSelector);
  return loading ? (
    <RatePlanSkelton />
  ) : (
    ratePlans?.length > 0 && (
      <RatePlan
        loading={loading}
        corpCode={corpCode}
        ratePlans={ratePlans}
        ratePlanCode={ratePlanCode}
        isOpen={isCollapseRatePlan}
        resetCorpCode={resetCorpCode}
        resetRatePlan={resetRatePlan}
        funCollapse={collapseRatePlan}
        funSelectCorpCode={setCorpCode}
        funSelectPlan={setRatePlanCode}
        isCorpCategory={isCorpCategory}
        selectedRatePlan={selectedRatePlan}
        setSelectedRatePlan={setSelectedRatePlan}
        funSelectCPolicy={setCancellationPolicy}
        setIsCorpCategory={setIsCorpCategory}
      />
    )
  );
}

export default RateplanComp;
