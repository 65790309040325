import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { GrCheckbox } from "react-icons/gr";
import { IoCheckboxSharp } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";
import { motion } from "framer-motion";
import { signInValidationSchema } from "../../commonElements/validationSchema/index";
import { useDispatch, useSelector } from "react-redux";
import { AddNewUser } from "./api";
import {
  enrollSelector,
  setIsChecked,
  setShashiCashTermsAndCondition,
} from "./enrollSlice";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

function Input({ formik, value, type, name, placeholder, children, isEnrollUiVersion2 }) {
  return (
    <div onClick={(e)=>e.stopPropagation()} className={`h-[42px] w-full flex ${isEnrollUiVersion2?'bg-white  border-black-1px':'bg-black border-white-1px'} px-[14.5px] relative enroll-input`}>
      {formik.errors && formik.errors[name] && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className={`${isEnrollUiVersion2?'bg-white':'bg-black'} text-red-600 absolute  w-fit text-[10px] leading-4 -mt-[5px] ml-1 left-0 px-1`}
        >
          {formik.errors[name]}
        </motion.p>
      )}

      <input
        name={name}
        type={type}
        value={value}
        aria-label={name}
        placeholder={placeholder}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        className={`
        outline-none  w-full h-full text-[13px] 
        ${isEnrollUiVersion2 ? `enroll-input-light
         caret-black text-black bg-white` :
            `enroll-input-dark caret-white text-white bg-black`}`}
      />
      {children}
    </div>
  );
}

function NewUser({isEnrollUiVersion2 = false}) {
  const [isVisible, setIsvisible] = useState(false);
  const { isChecked, status, isLoading } = useSelector(enrollSelector);
  const dispatch = useDispatch();
  const showPassword = false;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInValidationSchema(showPassword),
    onSubmit: (formData) => {
      dispatch(setIsChecked(true));
      let getUserQueryProp = {
        ratePlanCode: searchParams.get("ratePlanCode") || "",
        corpCode: searchParams.get('corpCode') || ""
      }
      dispatch(AddNewUser({formData,getUserQueryProp}));
    },
  });

  function eye() {
    setIsvisible(!isVisible);
  }

  useEffect(() => {
    if (status.apiMessage !== "") {
      formik.resetForm();
    }
  }, [status]);

  return (
    <Fragment>
      <motion.form
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6,delay:0.1 }}
        className={`enroll-form ${isEnrollUiVersion2?'pb-[12px]':""}`}
        onSubmit={formik.handleSubmit}
      >
        <Input
          isEnrollUiVersion2={isEnrollUiVersion2}
          type={"text"}
          name="email"
          placeholder="CREATE SHASHI ID (EMAIL ONLY)"
          formik={formik}
          value={formik.values.email}
        />
        {isEnrollUiVersion2?showPassword?<div className="h-[19px]"/>:<Fragment/>: <p className="text-[8px] text-white font-light leading-[13px] px-[14.5px] my-[3px] enroll-input-dec">
          Your Shashi ID cannot be changed. Additional emails can be added.
        </p> }
       
        { showPassword && <Input
          isEnrollUiVersion2={isEnrollUiVersion2}
          type={isVisible ? "text" : "password"}
          value={formik.values.password}
          name="password"
          placeholder="PASSWORD"
          formik={formik}
          children={
            isVisible ? (
              <EyeIcon className="text-[#A3A3A3] w-[22px] ml-1" onClick={eye} />
            ) : (
              <EyeOffIcon
                className="text-[#A3A3A3] w-[22px] ml-1"
                onClick={eye}
              />
            )
          }
          />
        }
        {isEnrollUiVersion2&&<div className="h-[3px]"/>}
        {isLoading ? (
          <div className="flex justify-center w-full mt-[9px] h-[40px] items-center">
            <ThreeDots
              height="10"
              width="28"
              radius="9"
              color={isEnrollUiVersion2?`black`:'white'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <div onClick={(e)=>e.stopPropagation()} className=" flex justify-between items-center enroll-terms">
            <label className={`${isEnrollUiVersion2?`text-black `:`text-white `} font-light`} htmlFor="">
            By enrolling I agree to &nbsp;
              <span onClick={()=> dispatch(setShashiCashTermsAndCondition(true))} className="underline cursor-pointer">
                Shashi Cash Terms & Conditions.
              </span>
              </label>
              
            <div
              onClick={(e) => {
                e.stopPropagation();
                formik.submitForm();
              }}
              className=" enroll-btn
        aspect-[1/1] rounded-full w-10 h-10 
        bg-[#FE0097] text-white flex items-center 
        justify-center cursor-pointer ml-1"
            >
              Enroll
            </div>
          </div>
        )}
      </motion.form>
    </Fragment>
  );
}

export default NewUser;
