import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button, Title } from "../../commonElements";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";
import { Swal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { reservationCancelSelector, setStatus } from "./reservationCancelSlice";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { stripeSelector } from "../../features/stripe/stripeSlice";

const loadingThreeDots = () => (
  <ThreeDots
    height="33"
    width="33"
    radius="15"
    color="black"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);

const CostData = ({ data, className }) => (
  <div className={`flex flex-col ${className}`}>
    <p className="capitalize text-base font-normal">{data}</p>
  </div>
);

const ReservationCancelDetails = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { status } = useSelector(reservationCancelSelector);
  const { hotelDetails } = useSelector(stripeSelector);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    arrivalDateTime,
    departureDateTime,
    totalCost,
    deductAmount,
    refundAmount,
    reservation_id,

    cancellationCharge,
    chargeAmount,
    userReservationId,
  } = location?.state;

  const handleReservationCancel = async () => {
    setIsLoading(true);

    let payload = {
      hotelId: hotelDetails?.hotelId,
      userReservationId,
      reservationId: reservation_id,
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`cancelReservation`, payload);

      // console.log("data:", data);

      setIsLoading(false);
      navigate(`/reservation_cancel_successful`);
    } catch (err) {
      setIsLoading(false);
      // console.log("err:", err?.response?.data?.result?.message);

      if (
        err?.response?.data?.result?.message ==
        "Reservation has been already cancelled"
      ) {
        return dispatch(
          setStatus({
            apiMessage: "Reservation has been already cancelled",
            apiSuccess: false,
          })
        );
      }

      navigate(`/reservation_cancel_failed`);
    }
  };
  const reservationDateFormat = (dateString) => {
    // console.log("dateString:", dateString);

    const dateN = new Date(+dateString);
    const dateStringN = dateN.toString();
    // console.log("dateStringN:", dateStringN);

    // var initial = dateString?.split(" ")[0]?.split("-");
    // var dateTime = initial && [initial[1], initial[0], initial[2]].join("-");
    // dateTime += " " + dateString?.split(" ")[1];

    // console.log(dateTime);

    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const date = new Date(dateStringN).getTime();

    return new Date(date).toLocaleDateString("en-US", options);
  };

  const dateFormatterFunc = (dataDate) => {
    console.log("dataDate:", dataDate);

    const dateF = new Date(parseInt(dataDate));

    // Extract the year, month, and day from the Date object
    const year = dateF.getFullYear();
    const month = String(dateF.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(dateF.getDate()).padStart(2, "0");

    // // Format the date string as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate:", formattedDate);

    // 2023-04-19
    return moment(formattedDate).format("ddd MMM D, YYYY");

    // Step 1: Create a Date object from the input date string
    const inputDate = new Date(formattedDate);

    // Step 2: Extract day, month, and year from the Date object
    const dayz = inputDate.toLocaleString("en-US", { day: "numeric" });
    const monthz = inputDate.toLocaleString("en-US", { month: "short" });
    const yearz = inputDate.toLocaleString("en-US", { year: "numeric" });

    // Step 3: Get the day of the week as a string
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[inputDate.getUTCDay()];

    // Step 4: Convert the month into its abbreviated form
    const abbreviatedMonth = String(monthz).substr(0, 3);

    // Step 5: Create a new string in the desired format
    const outputStr = `${dayOfWeek} ${abbreviatedMonth} ${dayz}, ${yearz}`;

    console.log(outputStr); // Output: "Wed Apr 19, 2023"
    return outputStr;
  };
  // dateFormatterFunc(arrivalDateTime);

  return (
    <>
      <Swal status={status} dispatchMetod={setStatus} />

      <div
        style={{ backgroundColor: "#F8F8F8" }}
        className={`w-full flex flex-col items-center justify-center ml-auto mr-auto bg-white`}
      >
        <div>
          <h3 className="font-light text-center text-black text-[13px] pt-4">
            Reservation Number
          </h3>
          <h2 className="font-normal text-center text-black mb-[16px] text-xl">
            {reservation_id}
          </h2>
        </div>

        <div
          style={{ backgroundColor: "#F8F8F8" }}
          className="flex items-center justify-center w-full h-auto p-4"
        >
          <div
            className="w-full p-[15px] md:p-[27px] bg-white"
            style={{ maxWidth: "435px" }}
          >
            <Box
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Title
                variant={"h2"}
                className={`text-magenta filter-brightness-150`}
                sx={{ fontSize: "0.8125rem" }}
                content="reservation details"
              />

              <div className="flex justify-between font-light text-[12px] mt-[38px]">
                <label>Check-In</label>
                <label>Check-Out</label>
              </div>
              <div className="flex justify-between font-normal text-[13px] mt-[6px]">
                <label>
                  {moment
                    .unix(arrivalDateTime / 1000)
                    .tz("GMT")
                    .format("ddd MMM D, YYYY")}

                  {/* {reservationDateFormat(arrivalDateTime)} */}
                </label>
                <label>
                  {moment
                    .unix(departureDateTime / 1000)
                    .tz("GMT")
                    .format("ddd MMM D, YYYY")}

                  {/* {reservationDateFormat(departureDateTime)} */}
                </label>
              </div>
            </Box>
            <Box
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3rem",
              }}
            >
              <div className="flex justify-between mt-[6px]">
                <CostData data={"Total Amount"} className={"items-start"} />
                <CostData data={`$${totalCost}`} />
              </div>

              <div className="flex justify-between mt-[4px]">
                <CostData data={"Deduction"} className={"items-start"} />
                <CostData data={`$${deductAmount}`} />
              </div>

              {refundAmount != 0 ? (
                <>
                  <hr className="border-gray-600 border-solid border-1 my-[6px]" />

                  <div className="flex justify-between mt-[6px]">
                    <CostData data={"Refund"} className={"items-start"} />
                    <CostData data={`$${refundAmount}`} />
                  </div>
                </>
              ) : (
                ""
              )}
            </Box>
            <Button
              type={"button"}
              className={"mt-[69px] bg-white text-black border border-black"}
              name={!isLoading && "Cancel Reservation"}
              spanClassName={"font-normal"}
              disabled={isLoading}
              loadingThreeDots={isLoading && loadingThreeDots()}
              onClick={handleReservationCancel}
            />
            <p className="mt-[36px] text-center  font-light text-base">
              Reservations must be cancelled 48 hours prior to arrival date 6pm
              hotel time to avoid a penalty of one night room and tax.
            </p>
            <p className="mt-[36px] text-center font-light text-base">
              Your refund for the cancelled hotel booking will be processed in
              the next few days.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationCancelDetails;
