import { Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import { CustomBox } from "../../commonElements";
const SmTextSkel = (props) => (
  <Skeleton
    variant="text"
    sx={{
      bgcolor: "#e0e0e0",
      ...props,
    }}
  />
);

const SmTextBse = ({ children, className }) => (
  <div
    className={`
      pl-[14px] pt-[14px] 
      pr-[14px] flex flex-wrap 
      justify-between ${className}`}
  >
    {children}
  </div>
);
function RatePlanSkelton() {
  return (
    <Fragment>
       <CustomBox
      onClick={(e) => e.stopPropagation()}
      className={`room-type-baseDiv text-black `}
      sx={{ marginTop: "35px" }}
    >
      <SmTextBse>
        <SmTextSkel height="15px" width="68px" />
        <SmTextSkel height="15px" width="68px" />
      </SmTextBse>
       <SmTextBse className={'mb-[14px]'}>
        <SmTextSkel width="90px" />
        <SmTextSkel width="90px" />
      </SmTextBse>
    </CustomBox>
    <div className="h-[24px]">

    </div>
    </Fragment>
   
  );
}

export default RatePlanSkelton;
