import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setGuestProfileToForm } from "../../features/costSummary/costSummarySlice";

function SetFormField({ values }) {
    const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setGuestProfileToForm(values))
  }, [values]);
  return null;
}

export default SetFormField;
