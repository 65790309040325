import { AXIOS_RESERVATION } from "../../util/axiosInterceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setBookingData, setGuestInfo } from "./costSummarySlice";
import { config } from "../../config";
import Cryptr from "cryptr";
import axios from "axios";

export const makeTokenization = createAsyncThunk(
  "costSummary/makeTokenization",
  async (values, { getState, dispatch, rejectWithValue }) => {
    try {
      console.log("makeTokenization>>>>>>>");
      const { costSummary, signIn, stripe, calender } = getState();
      const { totalCost } = costSummary;
      const { userData } = signIn;
      const { hotelDetails } = stripe;
      const {
        firstName,
        lastName,
        email,
        countryCodeForMobileNumber,
        mobileNoWithoutCountryCode,
        phoneNo,
        name,
        card,
        expiryDate,
        cvv,
        queryDetails
      } = values;
      const cryptr = new Cryptr(hotelDetails?.secretKey);

      dispatch(
        setGuestInfo({
          firstName,
          lastName,
          email,
          countryCodeForMobileNumber,
          mobileNoWithoutCountryCode,
          phoneNo,
        })
      );
      let hotelId = hotelDetails?.hotelCode;
      let dataForTokenization = {
        name,
        card,
        expiry_date: expiryDate,
        cvv,
        email,
      };
      const encrypted = cryptr.encrypt(JSON.stringify(dataForTokenization));

      let res = await AXIOS_RESERVATION.post(
        `v1/cardTokenization/${hotelId}/web/enc`,
        { card: encrypted }
      );

      if (res?.data?.success) {
        const decrypted = JSON.parse(cryptr.decrypt(res.data.result.data));
        console.log(decrypted, "decrypted")
        const tokenizedData = {
          card,
          currency: "usd",
          customer: "",
          description: "Reservation",
          encryptedSource: res.data.result.data,
          email: userData?.email || email,
          isPrefered: true,
          amount: parseFloat(totalCost),
          queryDetails
        };
        dispatch(makePayment(tokenizedData));
        return {
          tokenizedData,
          status: {
            apiMessage: res?.data?.result?.message,
            apiSuccess: res?.data?.success,
          },
        };
      } else {
        return {
          tokenizedData: {},
          status: {
            apiMessage: res?.data?.result?.message,
            apiSuccess: res?.data?.success,
          },
        };
      }
    } catch (error) {
      throw rejectWithValue({
        status: {
          apiSuccess: error?.response?.data?.success || false,
          apiMessage:
            error?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const makePayment = createAsyncThunk(
  "costSummary/makePayment",
  async (tokenizedData, { getState, dispatch, rejectWithValue }) => {
    try {
      const { calender, room, stripe } = getState();
      const { arrivalDate, departureDate } = calender;
      const { hotelDetails } = stripe;
      const { selectedRoom, ratePlanCode, corpCode } = room;
      const { queryDetails } = tokenizedData;
      const { adults, children, pets, rooms } = queryDetails;
      delete tokenizedData.queryDetails
      console.log(selectedRoom, "selectedRoom");
      let IpAddress = await axios.get("https://api.ipify.org?format=json");
      let userAgent = window.navigator.userAgent
      let userOs = userAgent?.split(/\((.*?)\)/)[1] || ""

      let bookingData = {
        adults,
        children,
        pets,
        rooms,
        arrivalDate,
        departureDate,
        isCorporate: 0,
        ratePlanCodes: selectedRoom[0].ratePlanCode,
        roomtype: selectedRoom[0].code,
        roomTypeName: selectedRoom[0].name,
        ratePlanCodeInDigit: ratePlanCode || "",
        corpCode: corpCode || "",
      };
      dispatch(setBookingData(bookingData));
      console.log("makePayment>>>>>>>");
      let { costSummary, signIn } = getState();
      const { guestInfo, paymentMethod, paymentMode } = costSummary;
      const { userData } = signIn;
      let hotelId = hotelDetails?.hotelId;
      let value = {
        bookingData: [bookingData],
        data: tokenizedData,
        emailId: userData?.email || guestInfo?.email || tokenizedData?.email,
        hotelId: hotelId,
        IPaddress: IpAddress?.data?.ip|| null,
        isAdvancePurchase: false,
        machineName: "web",
        OS: userOs,
        paymentMethod,
        paymentMode,
        saveCardForLaterUse: false,
      };

      let res = await AXIOS_RESERVATION.post("/paymentv3", value);

      if (res?.data?.success) {
        dispatch(makeReservation({...res?.data?.result,queryDetails}));
        return {
          paymentResData: res?.data?.result,
          status: {
            apiMessage: res?.data?.result?.message,
            apiSuccess: res?.data?.success,
          },
        };
      } else {
        // throw {
        //   paymentResData:{},
        //   status: {
        //     apiMessage: res?.data?.result?.message,
        //     apiSuccess: res?.data?.success,
        //   },
        // }
      }
    } catch (error) {
      console.log(error,'issue...');
      throw rejectWithValue(
         {paymentResData:{},
          status: {
            apiMessage: error?.response?.data?.result?.message,
            apiSuccess: error?.response?.data?.success,
          },
        });
    }
  }
);

export const makeReservation = createAsyncThunk(
  "costSummary/makeReservation",
  async (paymentResData, { getState, rejectWithValue }) => {
    try {
      console.log("makeReservation>>>>>>>");
      
      const { costSummary, room, calender, signIn, stripe } = getState();
      const { guestInfo, totalCost, paymentMode } = costSummary;
      const { selectedRoom, allOptions } = room;
      const { hotelDetails } = stripe
      let hotelId = hotelDetails?.hotelId
      const { userData } = signIn;
      const { _id, roomData, accessToken, paymentMethod, resTransactionId, queryDetails, encUserCardData } = paymentResData;
      const { adults, children, pets, rooms } = queryDetails;
      const {
        firstName,
        lastName,
        email,
        countryCodeForMobileNumber,
        mobileNoWithoutCountryCode,
        phoneNo,
      } = guestInfo;
      const { arrivalDate, departureDate } = calender;
      const { amtTotal, avgRate, name, code, ratePlanCode } = selectedRoom[0];

      let addOns = [];
      allOptions?.map((option) => {
        option.options.map((opt) => {
          if (opt.isSelected) {
            addOns.push(opt);
          }
        });
      });

      if (selectedRoom?.length > 0 && selectedRoom[0]?.addOn) {
        addOns.push(selectedRoom[0].addOn)
      }
      
      let IpAddress = await axios.get("https://api.ipify.org?format=json");
      let userAgent = window.navigator.userAgent
      let userOs = userAgent?.split(/\((.*?)\)/)[1] || ""
      

      let value = {
        _id,
        accessToken,
        addOns,
        arrivalDate,
        departureDate,
        emailId: userData?.email || email,
        hotelId: hotelId,
        IPaddress: IpAddress?.data?.ip|| null,
        isLoyaltyApply: userData?.isLoyaltyApply || false,
        machineName: 'web',
        numberOfAdults: adults,
        numberOfChildren: children,
        numberOfPets: pets,
        OS: userOs,
        paymentMethod: paymentMethod,
        paymentType: paymentMode,
        primaryGuest: {
          addresses: [
            {
              addressLines: ["tutti sign", ""],
              city: "Digital",
              country: "United States",
              countryCode: "US",
              postalCode: "2388",
              state: "Colorado",
              stateProvinceCode: "CO",
              type: "Home",
            },
          ],
          countryCodeForMobileNumber:
            userData?.countryCodeForMobileNumber || countryCodeForMobileNumber,
          firstName: userData?.firstName || firstName,
          lastName: userData?.lastName || lastName,
          mobileNoWithoutCountryCode:
            userData?.mobileNoWithoutCountryCode || mobileNoWithoutCountryCode,
          phoneNo: userData?.phoneNo || phoneNo,
          stayProfileId: "",
        },
        ratePlanCode,
        roomData,
        rooms: rooms,
        resTransactionId,
        roomTypeCode: code,
        roomTypeName: name,
        specialRequest: "",
        totalAmount: totalCost,
        totalRoomAmount: amtTotal,
        totalRoomPrice: avgRate,
        aORbTest: localStorage.getItem('PUSHTELL-bookingEngine'),
        encUserCardData,
      };

      let res = await AXIOS_RESERVATION.post("/v1/reservations", value);

      if (res?.data?.success) {
        return {
          reservationRespData: res?.data?.result,
          status: {
            apiMessage: res?.data?.result?.message,
            apiSuccess: res?.data?.success,
          },
        };
      } else {
        throw {
          status: {
            apiMessage: res?.data?.result?.message||
            "Something went wrong please try again later",
            apiSuccess: res?.data?.success || false,
          },
        };
      }
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
