import moment from "moment";
import {
  setCollapseRatePlan,
  setDescriptionKey,
  setIsApiCall,
  setBubble,
  setPrevBubble,
  setPackagesDisabledIds,
} from "./roomSlice";

export const isOpenDescription = (state) => (dispatch, getState) => {
  if (
    typeof state == "string" &&
    state !== getState()?.room?.descriptionOpenKey
  ) {
    dispatch(setDescriptionKey(state));
  } else {
    dispatch(setDescriptionKey(""));
  }
};

export const collapseRatePlan = (state) => (dispatch, getState) => {
  dispatch(setCollapseRatePlan(!getState().room.isCollapseRatePlan));
};

export const triggerRoomApi = () => (dispatch, getState) => {
  dispatch(setIsApiCall(moment().valueOf()));
};

export const selectStayOptions = (state) => (dispatch, getState) => {
  const { requestBodyKey, index } = state;

  const { allOptions, firstAllOptions, prevOptions, packageDisabledIds } = getState()?.room;

  const updatedArray = allOptions.map((item) => {
    return requestBodyKey == item.requestBodyKey
      ? {
          ...item,
          options: item.options.map((opt, i) => {
            return i == index
              ? {
                  ...opt,
                  isSelected: !opt.isSelected,
                }
              : { ...opt, isSelected: false };
          }),
        }
      : {
          ...item,
        };
  });

  const newArray = updatedArray
    .filter((item) => item.requestBodyKey === requestBodyKey)
    .map((item) => {
      const isSelectedTrueData = item.options.find(
        (option) => option.isSelected
      );

      if (isSelectedTrueData) {
        const disableIds = item.disablePackages.map((id) => id);
        dispatch(setPackagesDisabledIds([...packageDisabledIds,...disableIds]))
        return updatedArray.filter((obj) => !disableIds.includes(obj._id));
      } else {
        const Data = updatedArray.map((item) => {
          if (
            item.disablePackages.length !== 0 &&
            requestBodyKey == item.requestBodyKey
          ) {

            let TempDisablePackageIds = packageDisabledIds.map((e) => e);
            for (var i = 0; i < item.disablePackages.length; i++) {
              for (var j = 0; j < TempDisablePackageIds.length; j++) {
                if (item.disablePackages[i] == TempDisablePackageIds[j]) {
                  TempDisablePackageIds.splice(j, 1);
                  break;
                }
              }
            }          
            const data = prevOptions.map((item) => {
              return requestBodyKey == item.requestBodyKey
                ? {
                    ...item,
                    options: item.options.map((opt, i) => {
                      return i == index
                        ? {
                            ...opt,
                            isSelected: false,
                          }
                        : { ...opt, isSelected: false };
                    }),
                  }
                : {
                    ...item,
                  };
            });

            const prevIds = data.map((item) => item._id);
            const filteredOptions = firstAllOptions.filter((item) => {
              if (
                !prevIds.includes(item._id) &&
                !TempDisablePackageIds?.includes(item._id)
              ) {
                return item;
              }
            });
            const concatenatedArray = data
              .concat(filteredOptions)
              .sort((a, b) => a.displayOrder - b.displayOrder);
              dispatch(setPackagesDisabledIds(TempDisablePackageIds))
            return concatenatedArray;
          }
          if (
            item.disablePackages.length === 0 &&
            requestBodyKey == item.requestBodyKey
          ) {
            const data = updatedArray.map((item) => {
              return requestBodyKey == item.requestBodyKey
                ? {
                    ...item,
                    options: item.options.map((opt, i) => {
                      return i == index
                        ? {
                            ...opt,
                            isSelected: false,
                          }
                        : { ...opt, isSelected: false };
                    }),
                  }
                : {
                    ...item,
                  };
            });

            return data;
          }
        });

        const filteredData = Data.filter((item) => item !== undefined);

        return filteredData[0];
      }
    });

  dispatch(setPrevBubble(newArray[0]));
  dispatch(setBubble(newArray[0]));
};

export const setDfltPkgOnRomSlctin = (selectedRoom,packages = []) => {
  let tempTestArrayKeyword = [
    "breakfastPackage",
    "earlyCheckInPackage",
    "lateCheckOutPackage",
  ]
  return  packages.map((chld, idx) => {
    if (tempTestArrayKeyword.includes(chld?.requestBodyKey) && selectedRoom.length > 0) {
      chld.showAtDefaultSection = true;
    }
    return chld
  })
}
