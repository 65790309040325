import { Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
function index({ content, className, sx,variant }) {
  return (
    <motion.div
    initial={{opacity:0.3}}
    animate={{opacity:1}}
    transition={{duration:0.7}}
    >
      <Typography
        sx={{ ...sx, fontWeight: "fontWeightLight", lineHeight: "16px"}}
        className={`${className} uppercase text-center`}
        variant={variant}
      >
        {content}
      </Typography>
    </motion.div>
  );
}

export default index;
