import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY } from "../../util/axiosInterceptors";
import {
  setGuestProfileToForm,
  setSignInOpen,
  setUserProfileNotFoundGPay,
} from "../costSummary/costSummarySlice";
import { setCorpCode, setIsApiCall, setIsCorpCategory, setRatePlanCode, triggerRoomApi } from "../rooms/roomSlice";

export const signIn = createAsyncThunk(
  "signIn/auth",
  async (state, { getState, rejectWithValue, dispatch }) => {
    dispatch(setUserProfileNotFoundGPay(false));
    try {
      let res = await AXIOS_HOSPITALITY.post("loginv3", state.formData);

      // console.log('res:', res)

      if (res?.data?.success) {
        dispatch(getUserProfile({token:res?.data?.result.token,...state?.getUserQueryProp})); 
        return {
          token: res.data.result.token,
          status: { apiSuccess: res.data.success, apiMessage: "" },
        };
      } else {
        throw res.data;
      }
    } catch (error) {
      // console.log('error:', error)

      throw rejectWithValue({
        token: "",
        status: {
          apiSuccess: error.success || false,
          apiMessage:
            error?.result?.message ||
            error?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "signIn/getUserProfile",
  async (state, { getState, rejectWithValue, dispatch }) => {
    const { room } = getState()
    try {
      let res = await AXIOS_HOSPITALITY.get("getUserProfile", {
        headers: { Authorization: state?.token },
      });
      
        const {
          firstName,
          lastName,
          primaryEmail,
          mobileNoWithoutCountryCode,
          countryCodeForMobileNumber,
          isLoyaltyApply,
          loyaltyPoints,
          reservationDefaults
        } = res.data.result.data;

        dispatch(setSignInOpen(false));
        const userData = {
          firstName,
          lastName,
          email: primaryEmail,
          mobileNoWithoutCountryCode,
          countryCodeForMobileNumber,
          isLoyaltyApply,
          loyaltyPoints
        };

      dispatch(setGuestProfileToForm(userData));
      if (reservationDefaults?.length > 0 && !state?.ratePlanCode && !state?.corpCode) {        
        dispatch(setRatePlanCode( reservationDefaults[0].ratePlanCodeInDigit || ""))
        dispatch(setCorpCode(reservationDefaults[0].corpCode || ""))
        if (reservationDefaults[0].ratePlanCodeInDigit || reservationDefaults[0].corpCode) {
          // for reseting corpCategory
          dispatch(setIsCorpCategory(false))
          dispatch(triggerRoomApi())
        }
      }
      
        return {
          userData,
          status: { apiSuccess: res.data.success, apiMessage: "" },
        };
      
    } catch (error) {
      throw rejectWithValue({
        userData: {},
        status: {
          apiSuccess: error?.response?.data?.success || false,
          apiMessage:
          error?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
