import BubbleSkeleton from "../../components/bubbleSelection/bubbleSkeleton";
import { Count, CustomContainer, Title } from "../../commonElements";
import { selectStayOptions, roomSelector } from "../rooms/roomSlice";
import { SelectWithImg, SelectWithDescrip } from "../../components";
import BubbleSelection from "../../components/bubbleSelection";
import { useSelector } from "react-redux";
import React, { Fragment } from "react";
import PkgWithDecSkeleton from "../../components/bubbleSelection/pkgDecSkeleton";

function StayOptions({ forDefaultView = false }) {
  const { allOptions, optionLoader, selectedRoom } = useSelector(roomSelector);

  const checkItems = (child) => {
    if (selectedRoom.length > 0) {
      if (forDefaultView) {
        if (child.showAtDefaultSection) {
          return true;
        }
      } else {
        if (!child.showAtDefaultSection) return true;
      }
    } else {
      if (forDefaultView) return false;
      return true;
    }
  };

  const latestArray =
    allOptions?.length && !optionLoader
      ? allOptions.filter((e)=>e.requestBodyKey!="roomPackage").map((child, index) => {
          if (checkItems(child)) {
            return (
              <>
                {child.images?.length ? (
                  <SelectWithImg
                    onClickMethod={selectStayOptions}
                    key={index}
                    {...child}
                  />
                ) : child.options.find(
                    (option) => option.description !== ""
                  ) ? (
                  <SelectWithDescrip
                    onClickMethod={selectStayOptions}
                    key={index}
                    {...child}
                  />
                ) : (
                  <BubbleSelection
                    onClickMethod={selectStayOptions}
                    key={index}
                    {...child}
                  />
                )}
              </>
            );
          }
        })
      : null;

  return (
    <>
      {latestArray?.length>0 && forDefaultView && <div className={`mb-[25px]`} />}
      {latestArray}

      {optionLoader && !forDefaultView && (
        <Fragment>
          <PkgWithDecSkeleton />
          <BubbleSkeleton />
          <BubbleSkeleton />
          <BubbleSkeleton />
        </Fragment>
      )}
    </>
  );
}

export default StayOptions;
