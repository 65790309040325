import { CustomBox } from "../../commonElements";
import { MenuItem, Select } from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";
import "./rateplan.css";
import { useFormik } from "formik";
import { corporateRateplanSchema } from "../../commonElements/validationSchema";
import { triggerRoomApi } from "../../features/rooms/roomSlice";
import { ThreeDots } from "react-loader-spinner";
import { RatePlan_Category } from "../../config";
function RatePlan({
  funSelectPlan,
  ratePlanCode,
  funCollapse,
  ratePlans,
  isOpen,
  funSelectCPolicy,
  funSelectCorpCode,
  loading,
  corpCode,
  setSelectedRatePlan,
  selectedRatePlan,
  resetCorpCode,
  resetRatePlan,
  setIsCorpCategory,
  isCorpCategory,
}) {
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      corpCode,
    },
    validationSchema: corporateRateplanSchema,
    onSubmit: (value) => {
      dispatch(funSelectCorpCode(value.corpCode));
      dispatch(triggerRoomApi());
    },
  });

  useMemo(() => {
    ratePlans.length > 0 &&
      ratePlans
        .filter((fltr) => {
          if (corpCode == "" && isCorpCategory && fltr?.ratePlanCategory == "Corporate" ) {
            return fltr;
          } else {
            return fltr.default;
          }
        })
      .map((child, index) => {
        if (child.ratePlanCategory !== RatePlan_Category.corp) {
          dispatch(resetCorpCode());
          dispatch(setIsCorpCategory(false));
        }else{
          dispatch(setIsCorpCategory(true));
        }
          dispatch(setSelectedRatePlan(child))
        });
  }, [ratePlans]);

  function clickOptions(Option) {
    if (!funSelectPlan) return;
    dispatch(funSelectPlan(Option.ratePlanCode));

    // REMOVE CORPORATE CODE WHEN SELECT NON CORPORATE RATE PLAN
    if (Option.ratePlanCategory !== RatePlan_Category.corp) {
      dispatch(resetCorpCode());
      dispatch(setIsCorpCategory(false));
    }else{
      dispatch(setIsCorpCategory(true));
    }

    dispatch(triggerRoomApi());
    if (Option.ratePlanCode !== "") {
      
    } else {
      dispatch(resetCorpCode());
    }
    dispatch(funCollapse());
  }

  return (
    <Fragment>
      <CustomBox
        sx={{ marginTop: "35px" }}
        className="pl-[14px] pr-[14px] pt-[11px] pb-[11px]"
      >
        <div className="flex justify-between">
          <label className="text-[11px] leading-[12px] uppercase pt-[5px]">RATE PLAN</label>
          {!corpCode ? (
            <label
              onClick={() => {
                if (!funCollapse) return;
                dispatch(funCollapse());
              }}
              className="text-[10px] leading-[12px] cursor-pointer border-[1px] border-magenta px-[14px] pt-[5px] pb-[4px] text-magenta filter-brightness-150"
            >
              CHANGE
            </label>
          ) : null}
        </div>
        <p className="mt-[6px] capitalize">{selectedRatePlan?.ratePlanName}</p>
      </CustomBox>
      <Box
        sx={{
          maxWidth: {
            xs: "500px",
          },
          ml: "auto",
          mr: "auto",
        }}
      >
        {ratePlans?.length > 0 && (
          <Select
            className="rate-plan-select"
            onClick={() => {
              dispatch(funCollapse());
            }}
            open={isOpen}
            value={selectedRatePlan?.ratePlanCode}
            sx={{ width: "100%" }}
          >
            {ratePlans.map((availablePlans, index) => (
              <MenuItem
                className="rate-plan-items"
                key={index}
                value={availablePlans.ratePlanCode}
                onClick={() => clickOptions(availablePlans)}
              >
                <p>{availablePlans.ratePlanName}</p>
                {/* <p className="ml-auto">${availablePlans.leadRate}</p> */}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      {isCorpCategory ? (
        <CustomBox className={`mt-2 relative mb-[24px]`}>
          <form
            onSubmit={formik.handleSubmit}
            className="flex justify-between p-3 gap-2"
          >
            <input
              disabled={
                selectedRatePlan.ratePlanCategory == RatePlan_Category.corp &&
                selectedRatePlan.ratePlanCode
                  ? true
                  : false
              }
              // disabled
              value={formik.values.corpCode}
              name="corpCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              className="focus:outline-none   w-full"
              placeholder="Enter your Rate Code"
            />
            {selectedRatePlan.ratePlanCategory == RatePlan_Category.corp &&
            selectedRatePlan.ratePlanCode !== "" ? (
              <button
                onClick={() => {
                  dispatch(resetCorpCode());
                  dispatch(resetRatePlan());
                  dispatch(setIsCorpCategory(false));
                  dispatch(triggerRoomApi());
                }}
                type={"button"}
                className="capitalize bg-black text-white px-9 py-2 text-[13px] font-normal min-w-[111.74px] flex justify-center"
              >
                REMOVE
              </button>
            ) : (
              <button
                type={loading ? "button" : "submit"}
                className="capitalize bg-black text-white px-9 py-2 text-[13px] font-normal min-w-[111.74px] flex justify-center"
              >
                {loading ? (
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <>APPLY</>
                )}
              </button>
            )}
          </form>
          {formik.touched && formik.errors.corpCode && (
            <div className="absolute bottom-0 pl-2 text-[13px] text-red-600">
              {formik.errors.corpCode}
            </div>
          )}
        </CustomBox>
      ) : null}
    </Fragment>
  );
}

export default RatePlan;
