import {
  Swal,
  RatePlan,
  RoomCard,
  RatePlanSkelton,
  RoomCardSkeleton,
} from "../../components";
import { fetchRatePlanRoomType } from "./api";
import AvailableRooms from "./availableRooms";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeSelector } from "../../theme/themeSlice";
import { roomSelector } from "./roomSlice";
import { setDateFormat } from "../calender/calenderSlice";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { cancelRoomApiMsg } from "../../config";
import RateplanComp from "../splitLayout/rateplanComp";
import { Abtest } from "../../mixpanel";

function RoomType({ queryDetails, isDateProvideInQuery ,isdefaultRoomSelection}) {
  const { arrivalDate, departureDate, isDefaultDate } = useSelector(
    (state) => state.calender
  );
  const { isMobileScreen } = useSelector(themeSelector);
  const {
    isRoomChangeFlag,
    groupCodeDetails,
    isRoomAvailable,
    selectedRoom,
    isCorpCode,
    groupCode,
    roomType,
    isApiCall,
    loading,
    status,
  } = useSelector(roomSelector);
  // console.log("🚀 ~ file: index.jsx:48 ~ RoomType ~ roomType:", roomType);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.cancelTokenHotelsPostMethod = axios.CancelToken.source();
    dispatch(
      fetchRatePlanRoomType({ navigate, queryDetails, isDateProvideInQuery, isdefaultRoomSelection })
    );
    return () => window.cancelTokenHotelsPostMethod.cancel(cancelRoomApiMsg);
  }, [arrivalDate, departureDate, isApiCall]);

  useEffect(() => {
    if (groupCode) {
      dispatch(
        setDateFormat([groupCodeDetails.startDate, groupCodeDetails.endDate])
      );
    }
  }, [groupCode]);

  return (
    <Abtest
      VariantA={
        <Fragment>
          {/* Swal FOR  API MESSAGE */}
          {(isRoomAvailable == false && !isDefaultDate) || isCorpCode ? (
            <Swal status={status} />
          ) : (
            ""
          )}
          {isRoomChangeFlag ? (
            <Fragment>
              {/* COMPONENT FOR DESKTOP VIEW  */}
              {!isMobileScreen &&
                roomType?.length > 0 &&
                roomType.map((sltdRm, index) => (
                  <Fragment key={index}>
                    <RoomCard
                      uniqueTag={"SELECTED_ROOM_"}
                      isRoomChangeFlag={isRoomChangeFlag}
                      RoomDetails={sltdRm}
                      index={index}
                    />
                  </Fragment>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {/* DISPLAY DEFAULT SELECTED ROOM */}
              {loading ? (
                <RoomCardSkeleton />
              ) : selectedRoom?.length > 0 ? (
                selectedRoom.map((sltdRm, index) => (
                  <Fragment key={index}>
                    <RoomCard
                      isSelectedRoom={true}
                      uniqueTag={"SELECTED_ROOM_"}
                      RoomDetails={sltdRm}
                      index={index}
                    />
                  </Fragment>
                ))
              ) : null}
            </Fragment>
          )}

          {/* LIST ALL RATEPLAN   */}
          <RateplanComp />
          {/* COMPONENT FOR MOBILE VIEW */}
          {isMobileScreen && <AvailableRooms />}
          {/* {isMobileScreen && <MobileDescPrev/>} */}
        </Fragment>
      }
      VariantB={
        (isRoomAvailable == false && !isDefaultDate) || isCorpCode ? (
          <Swal status={status} />
        ) : (
          <Fragment />
        )
      }
    />
  );
}

export default RoomType;
