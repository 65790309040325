export const roomReducers = {
  // THIS KEY FOR MATCHING ROOM CARD DESCRIPTION
  setDescriptionKey: (state, { payload }) => {
    state.descriptionOpenKey = payload;
    state.isCollapseRatePlan = false;
  },
  showAllRooms: (state, { payload }) => {
    // SHOW ALL ROOM FLAG FOR SHOW AVAILABLE ROOMS
    state.descriptionOpenKey = "";
    state.isRoomChangeFlag = payload;
    state.isCollapseRatePlan = false;
  },
  setCollapseRatePlan: (state, { payload }) => {
    //  FOR SHOWING AVAILABLE RATEPLAN OPTIONS
    state.descriptionOpenKey = "";
    state.isCollapseRatePlan = payload;
    state.isRoomChangeFlag = false;
  },
  setRatePlanCode: (state, { payload }) => {
    // SET NEW RATEPLAN CODE
    state.descriptionOpenKey = "";
    state.ratePlanCode = payload;
    state.isCollapseRatePlan = false;
  },
  resetRatePlan: (state) => {
    state.ratePlanCode = "";
  },
  selectRoom: (state, { payload }) => {
    // SELECT ROOM FROM THE ROOMTYPE ARRAY
    state.selectedRoom = [payload];
    state.isRoomChangeFlag = false;
  },
  setBubble: (state, action) => {
    state.allOptions = action.payload;
  },
  setPrevBubble: (state, action) => {
    state.prevOptions = action.payload;
  },
  setCancellationPolicy: (state, action) => {
    state.cancellationPolicy = action.payload;
  },
  // setPetPolicy: (state, action) => {
  //   state.petPolicy = action.payload;
  // },
  setCorpCode: (state, action) => {
    state.corpCode = action.payload;
  },
  setIsApiCall: (state, { payload }) => {
    state.isApiCall = payload;
  },
  setSelectedRatePlan: (state, { payload }) => {
    state.selectedRatePlan = payload;
  },
  resetCorpCode: (state) => {
    state.corpCode = "";
  },
  setIsCorpCategory: (state, { payload }) => {
    state.isCorpCategory = payload;
  },
  setRoomTypeId: (state, { payload }) => {
    state.roomTypeId = payload;
  },
  setTimeSpan: (state, { payload }) => {
    state.timeSpan = payload;
  },
  setHandleOpen: (state, action) => {
    state.isHandleOpen = action.payload;
  },
  setIsRoomAvailable: (state, { payload }) => {
    state.isRoomAvailable = payload
  },
  setPackagesDisabledIds: (state, { payload }) => {
    state.packageDisabledIds = payload
  },
  setVisitedRoomBookingPage: (state) => {
    state.isVisitedRoomBookingPage = true
  },
  setStatus: (state, { payload }) => {
    state.status = payload;
  }
};
