import { Box, Collapse } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BackDrop,
  Button,
  Count,
  CustomContainer,
  Title,
} from "../../commonElements";
import {
  setIsChecked,
  setOpenPayInfo,
  costSummarySelector,
  setTotalCost,
  setPaymentMethod,
  setSignInOpen,
  setCancellationIsOpen,
  // setPetIsOpen,
  setTermsAndConditionIsOpen,
  setCostSItems,
  setPaymentMode,
  setUserProfileNotFoundGPay,
  setPackagesAndRoomTotalCost,
} from "./costSummarySlice";
import { calendarSelector } from "../calender/calenderSlice";
import PayWithCard from "./PayWithCard";
import SignIn from "../signIn";
import { roomSelector } from "../rooms/roomSlice";
import PaymentWithStripe from "../stripe";
import { setUserData, signInSelector } from "../signIn/signInSlice";
import { getAuthData } from "../../util/cookieReducer";
import CancellationPolicy from "../cancellationPolicy";
import TermsConditions from "../../pages/TermsConditions";
import {
  saveCardsSelector,
  setOpenCardOptions,
} from "../savedCards/savedCardsSlice";
import { getSavedCards } from "../savedCards/api";
import SavedCards from "../savedCards";
import { RotatingLines } from "react-loader-spinner";
import { ImSpinner2 } from "react-icons/im";
import { PayWithCardButton } from "./PayWithCardButton";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { IconArrow, InfoIcon } from "../../icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import { GrCheckbox } from "react-icons/gr";
import { IoCheckboxSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Enroll } from "..";
import PetPolicy from "../petPolicy";
import { pmsRateExternalCode } from "../../config";
import EnrollV2 from "../enroll/enrollV2";
import { stripeSelector } from "../stripe/stripeSlice";

const CostData = ({ data, className, ...props }) => (
  <div {...props} className={`flex flex-col ${className}`}>
    <p className="capitalize text-base font-normal leading-[20.3px]">{data}</p>
  </div>
);

const CardIcon = () => (
  <CreditCardIcon
    className="w-5 h-5 mr-2 -ml-1"
    style={{ fontSize: "25px" }}
    aria-hidden="true"
    focusable="false"
  />
);

function CostSummary({ queryDetails }) {
  const navigate = useNavigate();

  const { arrivalDate, departureDate } = useSelector(calendarSelector);
  const {hotelDetails}  = useSelector(stripeSelector)
  const { selectedRoom, allOptions, selectedRatePlan } =
    useSelector(roomSelector);
  const [isCollapseTax, setIsCollapseTax] = useState(false);
  const {
    loading,
    isSignInOption,
    openPayInfo,
    signInOpen,
    isChecked,
    totalCost,
    costSItems,
    cancellationIsOpen,
    petIsOpen,
    termsAndConditionIsOpen,
  } = useSelector(costSummarySelector);
  const { userData, isToken, removeLogoutOption } = useSelector(signInSelector);
  const { isSavedCards, selectedCard, openCardOptions, isCardSelected } =
    useSelector(saveCardsSelector);
  const dispatch = useDispatch();

  const openPaymentInfo = () => {
    dispatch(setUserProfileNotFoundGPay(false));
    dispatch(setOpenPayInfo(!openPayInfo));
    if (!openPayInfo) {
      dispatch(setPaymentMethod("card"));
      dispatch(setPaymentMode("fuesbox"));
    }
    if (Object.keys(selectedCard).length)
      dispatch(setOpenCardOptions(!openCardOptions));
  };

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(setSignInOpen(false));
    dispatch(setCancellationIsOpen(false));
    // dispatch(setPetIsOpen(false));
    dispatch(setTermsAndConditionIsOpen(false));
  };

  const handleSignInToggle = () => {
    // if(removeLogoutOption) return
    dispatch(setSignInOpen(!signInOpen));
  };

  const handleCancelToggle = () => {
    dispatch(setCancellationIsOpen(!cancellationIsOpen));
  };

  // const handlePetToggle = () => {
  //   dispatch(setPetIsOpen(!petIsOpen));
  // };

  const handleTandCToggle = (event) => {
    event.preventDefault();
    if (!termsAndConditionIsOpen) {
      dispatch(setTermsAndConditionIsOpen(!termsAndConditionIsOpen));
    }
  };

  const handleCheckboxChange = (prop, event) => {
    event.preventDefault();
    dispatch(setIsChecked(prop));
  };

  let costSummaryItems = costSItems;

  // flag for deselect pet charge ->>
  let isPetPackageSelected = false;

  useEffect(() => {
    costSummaryItems = [
      {
        packageName: "Room Cost",
        amount: `$${parseFloat(selectedRoom[0]?.amtTotal).toFixed(2)}`,
        amt: selectedRoom[0]?.amtTotal,
        type: "roomCost",
        taxes: selectedRoom[0]?.taxes,
      },
    ];
    if (allOptions.length) {
      allOptions?.map((option) => {
        option.options.map((opt) => {
          if (opt.isSelected) {
            if (opt.type == "pet") {
              isPetPackageSelected = true;
              costSummaryItems.push({
                packageName: opt.packageName,
                amount: `$${parseFloat(
                  opt.amtTotal * queryDetails.pets
                ).toFixed(2)}`,
                amt: opt.amtTotal * queryDetails.pets,
                type: opt.type,
                taxes: opt.taxes,
              });
            } else if (opt.isCostPerDay) {
              costSummaryItems.push({
                packageName: opt.packageName,
                amount: `$${parseFloat(opt.amtTotal).toFixed(2)}`,
                amt: opt.amtTotal,
                type: opt.type,
                taxes: opt.taxes,
              });
            } else {
              costSummaryItems.push({
                packageName: opt.packageName,
                amount: `$${parseFloat(opt.amount).toFixed(2)}`,
                amt: opt.amount,
                taxes: opt.taxes,
              });
            }
          }
        });
      });
    }
      if (queryDetails.pets > 0 && !isPetPackageSelected) {
        costSummaryItems.push({
          packageName: "Pet Fee",
          amount: `$${parseFloat(
            selectedRoom[0]?.petCharge * queryDetails?.pets
          ).toFixed(2)}`,
          amt: selectedRoom[0]?.petCharge * queryDetails?.pets,
          type: "petCharge",
          taxes: [],
        });
      }

      costSummaryItems.push({
        packageName: "Estimated Taxes & Fees",
        amount: ``,
        amt: 0,
        type: "taxesAndFees",
        isTaxesAndFees: true,
        taxes: [],
      });

      dispatch(setCostSItems(costSummaryItems));
    
  }, [allOptions, selectedRoom, queryDetails.pets]);

  let total = 0;
  var taxes = [];
  window.totalAmount = 0;
  window.roomTypeLable = selectedRoom[0]?.name;

  // if (costSummaryItems[0].taxes?.length > 0) {
  for (let i = 0; i < costSummaryItems.length; i++) {
    total += costSummaryItems[i].amt;
    window.totalAmount += costSummaryItems[i].amt;
    if (
      costSummaryItems[i].isTaxesAndFees !== true &&
      costSummaryItems[i]?.taxes?.length > 0
    ) {
      taxes = [...taxes, ...costSummaryItems[i].taxes];
    }
  }
  // }

  if (
    queryDetails.pets > 0 &&
    !costSummaryItems.filter(({ type }) => type == "pet")?.length
  ) {
    dispatch(
      setPackagesAndRoomTotalCost(
        total - selectedRoom[0]?.petCharge * queryDetails?.pets || 0
      )
    );
  } else {
    dispatch(setPackagesAndRoomTotalCost(total));
  }

  // CONCAT SAME TAX TYPE (taxAmt) -->>
  var UpdatedTaxArary = [];
  if (taxes.length > 0) {
    UpdatedTaxArary = taxes.reduce((acc, curr) => {
      const index = acc.findIndex((obj) => obj.type === curr.type);
      if (index === -1) {
        acc.push(curr);
      } else {
        var tempObj = acc[index];
        var totalAmount = Number(acc[index].taxAmt) + Number(curr.taxAmt);
        acc[index] = { ...tempObj, taxAmt: totalAmount };
      }
      return acc;
    }, []);
  }

  // FIND THE TOTAL OF TAX -->>
  var totalTax = 0;
  if (taxes.length > 0) {
    for (var i = 0; i < taxes.length; i++) {
      totalTax += Number(taxes[i].taxAmt);
    }
  }

  useEffect(() => {
    var totalAmount = 0;
    totalAmount = Number(total) + Number(totalTax);
    
    if (!isNaN(totalAmount)) {
      dispatch(setTotalCost(parseFloat(totalAmount).toFixed(2)));
    }
  }, [total, totalTax]);

  useEffect(() => {
    dispatch(setUserData(getAuthData()));
    if (isToken) {
      dispatch(getSavedCards());
    }
  }, [isToken]);

  const CancelPolicyComp = ({ children }) => (
    <div className=" text-[12px] font-light mt-[24.52px]">
      <span onClick={handleCancelToggle} className="underline cursor-pointer">
        Cancellation Policy
      </span>
      :&nbsp;
      {children}
    </div>
  );

  return (
    <>
      <BackDrop
        children={
          <ImSpinner2 className="text-[#FE0097] animate-spin text-[60px]" />
        }
        open={loading}
      />

      <Box
        sx={{
          maxWidth: {
            xs: "400px",
          },
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {/* <Enroll /> */}
        {/* <h3 className="mt-[46px] text-darkAsh uppercase text-center leading-[1] font-light text-[0.8125rem]">
          Cost Summary
        </h3> */}
        <div className="flex justify-between font-light text-[12px] mt-[32px]">
          <label>Check-In</label>
          <label>Check-Out</label>
        </div>
        <div className="flex justify-between font-normal text-[13px] mt-[6px]">
          <label>{moment.unix(arrivalDate).format("ddd, MMM D, YYYY")}</label>
          <label>{moment.unix(departureDate).format("ddd, MMM D, YYYY")}</label>
        </div>
      </Box>
      <Box
        sx={{
          maxWidth: {
            xs: "400px",
          },
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "35.3px",
        }}
      >
        {costSummaryItems?.map((costSummaryItem, index) => (
          <Fragment key={index}>
            <div
              className={`flex justify-between leading-[39.3px] ${
                costSummaryItem.type === "taxesAndFees"
                  ? Number(totalTax) > 0 && "mb-[12.86px]"
                  : "mb-[15.2px]"
              }`}
            >
              <div className="flex items-center">
                {costSummaryItem.type === "taxesAndFees" ? (
                  <>
                    {taxes.length > 0 && (
                      <>
                        <CostData
                          onClick={() => setIsCollapseTax(!isCollapseTax)}
                          data={costSummaryItem.packageName}
                          className={"items-start cursor-pointer"}
                        />
                        <IoInformationCircleOutline
                          className="cursor-pointer ml-1"
                          onClick={() => setIsCollapseTax(!isCollapseTax)}
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "gray",
                          }}
                        />
                        {/* <IconArrow
                          onClick={() => setIsCollapseTax(!isCollapseTax)}
                          rotate={!isCollapseTax ? 360 : 180}
                          className={`ml-1`}
                        /> */}
                      </>
                    )}
                  </>
                ) : (
                  <CostData
                    data={costSummaryItem.packageName}
                    className={"items-start"}
                  />
                )}
              </div>
              {costSummaryItem.type === "taxesAndFees" ? (
                <>
                  {taxes.length > 0 && (
                    <span className={`mt-[4px]`}>
                      <CostData
                        data={Number(totalTax).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                        className={"items-end"}
                      />
                    </span>
                  )}
                </>
              ) : (
                <span>
                  <CostData
                    data={Number(
                      costSummaryItem.amount.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    className={"items-end"}
                  />
                </span>
              )}
            </div>
            {costSummaryItem.type === "taxesAndFees" && (
              <Collapse timeout={700} in={isCollapseTax}>
                {UpdatedTaxArary?.length > 0 &&
                  UpdatedTaxArary.map((subTax, ind) => (
                    <div className="flex justify-between font-light text-[14px] mb-[3px]">
                      <p>{subTax.name}</p>
                      <p>
                        {Number(subTax?.taxAmt).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </p>
                    </div>
                  ))}
              </Collapse>
            )}
          </Fragment>
        ))}
        <hr className="border-gray-600 border-solid border-1" />
        <div className="flex justify-between mt-[13.27px] leading-[20.3px]">
          <CostData data={"Total Cost"} className={"items-start"} />
          <CostData
            data={`$${
              typeof totalCost === "string"
                ? Number(totalCost.replace(/[^0-9.-]+/g, ""))
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                    .slice(1)
                : ""
            }`}
            className={"items-end"}
          />
        </div>
        <EnrollV2/>

        {selectedRatePlan.pmsRateExternalCode == pmsRateExternalCode.advance &&
        Number(totalCost) > 0 ? (
          <CancelPolicyComp>
            Advanced Purchase reservations are non-refundable. If you must
            cancel, you will be charged ${totalCost} in Cancellation Fees.
          </CancelPolicyComp>
        ) : selectedRoom[0]?.cancellationPolicy ? (
          <CancelPolicyComp>
            {selectedRoom[0]?.cancellationPolicy}
          </CancelPolicyComp>
        ) : null}

        <BackDrop handleClose={handleClose} open={cancellationIsOpen}>
          <CancellationPolicy handleClose={handleClose} />
        </BackDrop>

        <Collapse
          in={queryDetails.pets > 0 ? true : false}
          timeout={1000}
          className={`overflow-hidden `}
        >
          <div
            className={`mt-[21px] text-[12px] font-light ${
              queryDetails.pets > 0 ? `opacity-100` : `opacity-0`
            } duration-1000`}
          >
            <button
              // onClick={handlePetToggle}
              className="underline cursor-default"
            >
              Pet Policy:
            </button>{" "}
            <span className="">
              Guests may bring a maximum of 2 canine companions weighing a
              maximum of 50 pounds each. Felines and other animal species are
              prohibited. A $125 non-refundable pet fee will be assessed per pet
              at the time of check-in. Service Animals exempt.
            </span>
          </div>
        </Collapse>

        {/* <BackDrop handleClose={handleClose} open={petIsOpen}>
          <PetPolicy handleClose={handleClose} />
        </BackDrop> */}
        <div className="mt-[67px]">
          <div className="inline-flex w-full">
            {/* {isChecked ? (
              <IoCheckboxSharp
                style={{ height: "1rem", width: "1rem", color: "#FE0097" }}
                onClick={(event) => handleCheckboxChange(false, event)}
              />
            ) : (
              <GrCheckbox
                style={{ height: "1rem", width: "1rem" }}
                onClick={(event) => handleCheckboxChange(true, event)}
              />
            )} */}
            {/* <input
              type="checkbox"
              className="form-checkbox h-4 w-4 accent-[#FE0097] mt-[1px]"
              checked={isChecked}
              onChange={handleCheckboxChange}
            /> */}

            <span className="text-[12px] font-light text-black w-full text-center  ">
              <p>By booking this reservation I agree to this</p>
              <button onClick={handleTandCToggle} className="underline   ">
                Terms & Conditions.
              </button>
              <BackDrop
                handleClose={handleClose}
                open={termsAndConditionIsOpen}
              >
                <TermsConditions handleClose={handleClose} />
              </BackDrop>
            </span>
          </div>
        </div>
        {userData?.firstName && (
          <div className="flex justify-center text-[12px] font-light mt-[18px]">
            Welcome, {userData?.firstName}
          </div>
        )}
        {/* Payment buttons */}
        <PaymentWithStripe queryDetails={queryDetails} />
        {isSavedCards ? (
          <PayWithCardButton
            type={"button"}
            onClick={() => {
              dispatch(setUserProfileNotFoundGPay(false));
              dispatch(setOpenCardOptions(!openCardOptions));
            }}
            className={"mt-[19px] bg-white text-black border border-black"}
            Icon={CardIcon}
            name={
              isCardSelected
                ? `Pay with ${selectedCard?.brand} - ${selectedCard?.last4}`
                : `Pay with Card`
            }
            spanClassName={"font-normal"}
            dropDownIcon={
              !openCardOptions ? (
                <ArrowDropDownIcon fontSize="large" />
              ) : (
                <ArrowDropUpIcon fontSize="large" />
              )
            }
          />
        ) : (
          <Button
            type={"button"}
            onClick={openPaymentInfo}
            className={"mt-[19px] bg-white text-black border border-black"}
            Icon={CardIcon}
            name={"Pay with Card"}
            spanClassName={"font-normal"}
          />
        )}
        {/*  Payment Information */}
        {
          <PayWithCard
            totalTax={totalTax}
            UpdatedTaxArary={UpdatedTaxArary}
            open={isSavedCards ? true : openPayInfo}
            queryDetails={queryDetails}
          />
        }
        {/* {<SavedCards open={openCardOptions} />} */}
        {!userData?.email && (
          <>
            {removeLogoutOption ? (
              // FOR IOS
              <a
                href="inapp://sign-in"
                className="flex items-center justify-center mt-[27px] text-xs font-light text-black "
              >
                <h4 className="mr-1">Already a member?</h4>
                <button id="signIn-btn" className="underline">
                  Sign In
                </button>
              </a>
            ) : (
              <div className="flex items-center justify-center mt-[27px] text-xs font-light text-black ">
                <h4 className="mr-1">Already a member?</h4>
                <button
                  id="signIn-btn"
                  onClick={handleSignInToggle}
                  className="underline"
                >
                  Sign In
                </button>
              </div>
            )}
          </>
        )}

        <BackDrop handleClose={handleClose} open={signInOpen}>
          <SignIn handleClose={handleClose} />
        </BackDrop>
      </Box>

      {/* <div className="flex justify-center">
        <button
          onClick={() => navigate(`/reservation_cancel`)}
          className="text-black font-light text-[12px] mt-[3rem] lg:absolute"
        >
          Cancel Reservation
        </button>
      </div> */}
    </>
  );
}

export default CostSummary;
