import { createSlice } from "@reduxjs/toolkit";
import { callResetPaswordApi, verifyResetPswdTkn } from "./api";
import { API_STATUS } from "../../config";

const initialState = {
  isLoading: false,
  verifyTokenStatus: {
    apiMessage: "",
    apiSuccess: null,
  },
  resetPasswordStatus: {
    apiMessage: "",
    apiSuccess: null,
  },
};
export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  extraReducers: (builder) => {
    builder
      //   FOR VERIFY RESET PASSWORD TOKEN API
      .addCase(verifyResetPswdTkn.pending, (state) => {
        state.verifyTokenStatus = {
          apiMessage: "",
          apiSuccess: null,
        };
      })
      .addCase(verifyResetPswdTkn.fulfilled, (state, { payload }) => {
        state.verifyTokenStatus = payload;
      })
      .addCase(verifyResetPswdTkn.rejected, (state, { payload }) => {
        state.verifyTokenStatus = payload;
      })
      //   FOR RESET PASSWORD API
      .addCase(callResetPaswordApi.pending, (state) => {
        state.isLoading = true;
        state.resetPasswordStatus = {
          apiMessage: "",
          apiSuccess: null,
        };
      })
      .addCase(callResetPaswordApi.fulfilled, (state, { payload }) => {
        state.resetPasswordStatus = payload;
        state.isLoading = false;
      })
      .addCase(callResetPaswordApi.rejected, (state, { payload }) => {
        state.resetPasswordStatus = payload;
        state.isLoading = false;
      });
  },
});

export default resetPasswordSlice.reducer;
export const resetPasswordSelector = (state) => state.resetPassword;
