import {
  lighten,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  responsiveFontSizes,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsMobileScreen } from "./themeSlice";
function MaterialTheme({ children }) {
  const dispatch = useDispatch();
  const isScreenSmall = useMediaQuery("(max-width:1199px)");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    dispatch(setIsMobileScreen(isScreenSmall));
  }, [isScreenSmall]);

  const theme = responsiveFontSizes(
    createTheme({
      palette: prefersDarkMode
        ? {
            // COLOR SCHEMA FOR DARK MODE
            mode: "dark",
            primary: {
              main: lighten("#F8F8F8", 0),
            },
            magenta: {
              // main: lighten("#FE0097", 0),
              main: lighten("#ac0067", 0),
            },
            black: {
              main: lighten("#000000", 0),
            },
            white: {
              main: lighten("#ffff", 0),
            },
            darkAsh: {
              main: lighten("#595959", 0),
            },
            darkAsh1: {
              main: lighten("#EDEDED", 0),
            },
          }
        : {
            // COLOR SCHEMA FOR LIGHT MODE
            mode: "light",
            primary: {
              main: lighten("#F8F8F8", 0),
            },
            magenta: {
             // main: lighten("#FE0097", 0),
             main: lighten("#ac0067", 0),
            },
            black: {
              main: lighten("#000000", 0),
            },
            white: {
              main: lighten("#ffff", 0),
            },
            darkAsh: {
              main: lighten("#595959", 0),
            },
            darkAsh1: {
              main: lighten("#EDEDED", 0),
            },
          },
      typography: {
        fontFamily: "Azo Sans",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
          fontSize: "0.8125rem",
        },
        h2: {
          fontSize: "0.8125rem",
        },
        h3: {
          fontSize: "1rem",
        },
        h4: {
          fontSize: "1rem",
        },
      },
      components: {
        MuiContainer: {
          styleOverrides: {},
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    })
  );
  return <ThemeProvider children={children} theme={theme} />;
}

export default MaterialTheme;
