import { ButtonBase, Typography } from "@mui/material";
import { CustomBox } from "../../commonElements";
import BubbleAnimate from "./bubbleAnimate";
import { useDispatch } from "react-redux";

function BubbleSelection({ options, title, requestBodyKey, onClickMethod }) {
  const dispatch = useDispatch();

  return (
    <CustomBox className={`mb-[35px] flex flex-col p-4`}>
      <Typography className={`text-black`} variant="h4">
        {title}
      </Typography>
      <div className="flex gap-[20px] md:gap-[24px] mt-[15px] flex-wrap">
        {options?.length &&
          options.map(({ subtitle, tag, title, isSelected }, index) => (
            <div
              key={index}
              onClick={() => {
                onClickMethod &&
                  dispatch(onClickMethod({ requestBodyKey, index }));
              }}
              className="text-center text-[13px]"
            >
              <BubbleAnimate>
                <ButtonBase
                  sx={{
                    borderRadius: "50%",
                    boxShadow: isSelected
                      ? "0 0 0 0.4px #CC005E"
                      : "0 0 0 0.4px gray",
                  }}
                >
                  <div
                    className={`${
                      isSelected ? `border-magentaDark border-[1px]` : `border-black border-[0.5px]`
                    }
                  w-[42px] h-[42px] rounded-full 
                  flex flex-col justify-center 
                  font-light
                  filter-brightness-150
                  text-center 
                  bg-main
                  cursor-pointer-desktop`}
                  >
                    <span className={` text-[14px] leading-[17.77px]`}>
                      {title}
                    </span>
                    <span className={`text-[8px] font-normal leading-[7.61px] uppercase`}>
                      {subtitle}
                    </span>
                  </div>
                </ButtonBase>
              </BubbleAnimate>
              <p className={`mt-[8px]  w-[39px] text-black`}>{tag}</p>
            </div>
          ))}
      </div>
    </CustomBox>
  );
}

export default BubbleSelection;
