import moment from "moment";

const maxDate = (groupCodeDetails) => {
  return Object.keys(groupCodeDetails).length > 0
    ? moment(groupCodeDetails?.endDate).toDate()
    : moment().add(1, "year").toDate();
};

const handleViewCallback = (
  action,
  activeStartDate,
  groupCodeDetails,
  callback
) => {
    let dateIn, dateOut;
    
  if (action == "prev" || action == "next") {
    if (
      (moment(activeStartDate).isSame(moment(), "month") &&
        moment(activeStartDate).isSame(moment(), "year")) ||
      (moment(activeStartDate).subtract(1, "month").isSame(moment(), "month") &&
        moment(activeStartDate).subtract(1, "month").isSame(moment(), "year"))
    ) {
      dateIn = moment().format("YYYY-MM-DD");
      dateOut = moment(activeStartDate)
        .endOf("month")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (
      moment(activeStartDate)
        .add(1, "month")
        .isSame(maxDate(groupCodeDetails), "month") &&
      moment(activeStartDate)
        .add(1, "month")
        .isSame(maxDate(groupCodeDetails), "year")
    ) {
      dateIn = moment(activeStartDate).startOf("week").format("YYYY-MM-DD");
      dateOut = moment(maxDate(groupCodeDetails)).format("YYYY-MM-DD");
    } else if (action == "prev") {
      dateIn = moment(activeStartDate)
        .subtract(1, "month")
        .startOf("week")
        .format("YYYY-MM-DD");
      dateOut = moment(activeStartDate)
        .endOf("month")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (action == "next") {
      dateIn = moment(activeStartDate).startOf("week").format("YYYY-MM-DD");
      dateOut = moment(activeStartDate)
        .add(1, "month")
        .endOf("month")
        .endOf("week")
        .format("YYYY-MM-DD");
      
    }
    callback(dateIn, dateOut);
  } else {
      return
  }
};

export const calendarHelper = { handleViewCallback, maxDate };
