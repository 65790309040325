import React from "react";

import { Button } from "../../commonElements";
import { Box } from "@mui/material";
import { ReservationCancelSuccessfulIcon } from "../../icons";
import { useNavigate, useParams } from "react-router-dom";

const ReservationCancelSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="flex items-center justify-center w-full h-auto p-4"
    >
      <div
        className="w-full p-[15px] md:p-[27px] bg-white"
        style={{ maxWidth: "435px" }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="flex items-center justify-center mb-[37px]">
            <ReservationCancelSuccessfulIcon />
          </div>

          <h1 className="font-normal text-center text-black text-lg md:text-xl mb-[37px]">
            Your reservation has been cancelled
          </h1>
          <h3 className="font-light text-center text-black mt-[26px] text-[13px] md:text-[16px]">
            We're sorry to hear that you won't be <br /> joining us and we hope
            to see you <br /> again soon.
          </h3>
        </Box>
        <Button
          type={"button"}
          className={"mt-[69px] bg-white text-black border border-black"}
          name={"Back to Shashi"}
          spanClassName={"font-normal"}
          onClick={() => navigate(`/`)}
        />
      </div>
    </div>
  );
};

export default ReservationCancelSuccessful;
