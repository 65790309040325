import { createHash } from "crypto-browserify";
import momentTimezone from "moment-timezone";
import axios from "axios";

const createHashDetails = async (value) =>
  await createHash("sha256").update(value).digest("hex");

export const FbFileConvertionApi = async (
  formFieldInitialState,
  reservationDetails,
  totalAmount,
  isCardSelected,
  userData,
  paymentMethod
) => {
  try {
    // ADD FB CONVERTION API -->>
    let response = await axios.get("https://api.ipify.org?format=json");
    let user_data = {
      client_user_agent: window.navigator?.userAgent || null,
      client_ip_address: response.data?.ip || null,
      em: null,
      ph: null,
      fn: null,
      ln: null,
      zp: null,
      ct: null,
      st: null,
      country: null,
    };
    // FOR EMAIL -->>
    if (formFieldInitialState.email) {
      user_data.em = await createHashDetails(formFieldInitialState.email);
    }

    // FOR FIRST NAME -->>
    if (formFieldInitialState.firstName) {
      user_data.fn = await createHashDetails(formFieldInitialState.firstName);
    }

    // FOR LAST NAME -->>
    if (formFieldInitialState.lastName) {
      user_data.ln = await createHashDetails(formFieldInitialState.lastName);
    }

    // FOR PHONE NUMBER -->>
    if (
      formFieldInitialState.mobileNoWithoutCountryCode &&
      formFieldInitialState.countryCodeForMobileNumber
    ) {
      let mobileNumber =
        formFieldInitialState.countryCodeForMobileNumber +
        formFieldInitialState.mobileNoWithoutCountryCode;
      user_data.ln = await createHashDetails(mobileNumber);
    }

    // FOR ZIPCODE -->>
    if (
      isCardSelected && userData?.zipCode 
    ) {
      user_data.zp = await createHashDetails(userData?.zipCode);
      
    } else if (paymentMethod == "card" && formFieldInitialState?.zipCode) {
      user_data.zp = await createHashDetails(formFieldInitialState.zipCode);
      
    } else if(paymentMethod != "card" && userData?.zipCode){
      user_data.zp = await createHashDetails(userData?.zipCode);
    }

    // FOR CITY -->>
    if (
       userData?.city 
    ) {
      user_data.ct = await createHashDetails(userData?.city );
    }

    // FOR STATE -->>
    if (
      userData?.state
   ) {
     user_data.st = await createHashDetails(userData?.state);
    }
    
    // FOR COUNTRY -->>
    if (
      isCardSelected && userData?.country 
    ) {
      user_data.country = await createHashDetails(userData?.country);
    } else if (paymentMethod == "card" && formFieldInitialState?.country?.label) {
      user_data.country = await createHashDetails(formFieldInitialState?.country?.label);
      
    } else if (paymentMethod != "card" &&  userData?.country) {
      user_data.country = await createHashDetails(formFieldInitialState?.country?.label);
      
    }

    let body = {
      data: [
        {
          user_data,
          action_source: "website",
          event_time: momentTimezone().format("X"),
          event_source_url: window.location.origin || null,
          event_name: "Purchase",
          custom_data: {
            value: totalAmount,
            reservation_id: reservationDetails.reservationId || null,
            number_of_nights: reservationDetails.numberOfNights || null,
            number_of_children: reservationDetails.numberOfAdults || null,
            number_of_adults: reservationDetails.numberOfAdults || null,
            room_type: reservationDetails.roomTypeName || null,
            currency: "USD",
          },
        },
      ],
    };

    axios
      .post("https://graph.facebook.com/v11.0/1292022331410247/events", body, {
        params: { access_token: window.FB_convertion_access_token },
      })
      .then((e) => {
        console.log(e.data, "fileConversion tracking success");
      });

    //<<-- ADD FB CONVERTION API
  } catch (error) {
    console.log(error);
  }
};
