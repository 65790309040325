import React, { useEffect, useState } from "react";
import {
  Count,
  Title,
  CollapseTitle,
  CustomContainer,
} from "../../commonElements";
import AvailableRoom from "./availableRoom";
import SelectedRoom from "./selectedRoom";
import StayOptions from "../stayOptions";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { roomSelector } from "../rooms/roomSlice";
import { Abtest } from "../../mixpanel";

function SectionB({ setQueryDetails, queryDetails }) {
  const roomState = useSelector(roomSelector);
  const [packageCollpase, setPackageCollapse] = useState(false);
  const [roomCollpase, setRoomCollapse] = useState(false);
  const [accessibleRoomCollapse, setAccessibleRoomCollapse] = useState(false);

  useEffect(() => {
    if (roomState?.selectedRoom?.length > 0) {
      setRoomCollapse(false);
      setAccessibleRoomCollapse(false);
      setPackageCollapse(false)
    }
  }, [roomState.selectedRoom]);

  return (
    <CustomContainer className="bg-main lg:bg-white pt-[32px] pb-[20px] lg:pb-0 mt-[44px] lg:mt-0">
      <Abtest
        VariantA={
          <>
            <Count count={"2"} />
            <Title
              variant={"h1"}
              className={`text-magenta filter-brightness-150`}
              content={"PERSONALIZE YOUR STAY"}
            />
            <p className="text-darkAsh text-[13px] flex justify-center mt-[4px] font-light uppercase mb-[32px]">
              OPTIONAL
            </p>
            <StayOptions />
          </>
        }
        VariantB={
          <>
            <Count className={"mb-0"} count={"2"} />
            <div className=" uppercase text-magenta filter-brightness-150 text-center leading-[15.6px] text-[13px] font-light" >Select your room</div>
            {roomState?.selectedRoom?.length > 0 ?
              <SelectedRoom />
              : 
            (
            <AvailableRoom
            for_DK_PK={true}
            isCollapse={true}
            callback={() => setRoomCollapse(false)}
           />
            )}
            
            <AvailableRoom
              isCollapse={roomCollpase}
              for_DK_PK={true}
                TitleComp={
                  <CollapseTitle
                    openLabel="Hide More Rooms"
                    closeLable="View More Rooms"
                    className={`ml-auto mt-[30px] mr-auto filter-brightness-150`}
                    onClick={() => setRoomCollapse(!roomCollpase)}
                    flag={roomCollpase}
                  />
                }
                callback={() => setRoomCollapse(false)}
                showAllRoom={true}
              />

            {/* COLLAPSE PACKAGES */}
            <StayOptions forDefaultView={true} />
            <CollapseTitle
              openLabel={roomState?.selectedRoom?.length > 0?"Hide More Packages":"Hide Packages"}
              closeLable={roomState?.selectedRoom?.length > 0?"View More Packages":"View Packages"}
              className={`ml-auto mr-auto my-[25px] text-magenta filter-brightness-150`}
              onClick={() => setPackageCollapse(!packageCollpase)}
              flag={packageCollpase}
            />
            <Collapse className="pt-1" timeout={2000} in={packageCollpase}>
              <StayOptions />
            </Collapse>
          </>
        }
      />
    </CustomContainer>
  );
}

export default SectionB;
