import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../features/calender/calenderSlice";
import { CustomContainer } from "../../commonElements";
import Calender from "../../features/calender";
import { GuestCount } from "../../features";
import { fetchRatePlanRoomType } from "../../features/rooms/api";
import { RatePlan, RatePlanSkelton, Swal } from "../../components";
import {
    collapseRatePlan,
    resetCorpCode,
    resetRatePlan,
    roomSelector,
    setCancellationPolicy,
    setCorpCode,
    setIsCorpCategory,
    setRatePlanCode,
    setSelectedRatePlan,
    setStatus,
} from "../../features/rooms/roomSlice";
import SectionA from "../../features/splitLayout/SectionA";
import axios from "axios";
import { cancelRoomApiMsg } from "../../config";
import { disable } from "mixpanel-browser";
import {
    setStatus as costSummarySetStatus,
    costSummarySelector,
} from "../../features/costSummary/costSummarySlice";

const WebflowWebView = () => {
    const { arrivalDate, departureDate, isDateSingleSelect, isDefaultDate } = useSelector(
        (state) => state.calender
    );
    const {
        loading,
        ratePlans,
        isCorpCategory,
        isRoomAvailable,
        isCollapseRatePlan,
        selectedRatePlan,
        ratePlanCode,
        isCorpCode,
        corpCode,
        isApiCall,
        status,
    } = useSelector(roomSelector);
    const costSummaryState = useSelector(costSummarySelector);
    console.log("selectedRatePlan:", selectedRatePlan);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let redirectLink;
    const searchParams = new URLSearchParams(location.search);

    const [queryDetails, setQueryDetails] = useState({
        _ga: searchParams.get("_ga") || "",
        rooms: searchParams.get("rooms") || "1",
        adults: searchParams.get("adults") || "1",
        groupId: searchParams.get("groupId") || "",
        children: searchParams.get("children") || "0",
        pets: searchParams.get("pets") || "0",
        roomTypeId: searchParams.get("roomTypeId") || "",
    });

    useEffect(() => {
        window.cancelTokenHotelsPostMethod = axios.CancelToken.source();
        dispatch(
            fetchRatePlanRoomType({
                navigate,
                queryDetails,
                isDateProvideInQuery: false,
                isdefaultRoomSelection: false,
            })
        );
        return () => window.cancelTokenHotelsPostMethod.cancel(cancelRoomApiMsg);
    }, [arrivalDate, departureDate, isApiCall]);

    useEffect(() => {
        if (searchParams.get("datein") && searchParams.get("dateout")) {
            dispatch(setDate([searchParams.get("datein"), searchParams.get("dateout")]));
        }

        if (sessionStorage.getItem("CorporateEmp")) {
            dispatch(setIsCorpCategory(true));
        }
    }, []);

    const handleClick = () => {
        return `${window?.location?.origin}?selectedParams=true&datein=${arrivalDate}&dateout=${departureDate}&rooms=${queryDetails.rooms}&adults=${queryDetails.adults}&children=${queryDetails.children}&pets=${queryDetails.pets}&ratePlanCode=${selectedRatePlan?.ratePlanCode}&corpCode=${corpCode}&ratePlanCategory=${selectedRatePlan?.ratePlanCategory}`;
    };

    const isWebflowWebView = window.location.pathname.includes("webflowWebView");
    console.log("WebflowWebView >> isWebflowWebView:", isWebflowWebView);

    if (isWebflowWebView) {
      let chatWithUsToggleContainer = document.getElementById("chatWithUsToggleContainer");
      if (chatWithUsToggleContainer) {
        console.log("chatWithUsToggleContainer available:");

        chatWithUsToggleContainer.style.display = "none";
      } else {
        console.log("chatWithUsToggleContainer not available:");
      }
    }
    // commit to trigger aws

    return (
        <>
            {(isRoomAvailable == false && !isDefaultDate) || isCorpCode ? (
                <Swal status={status} dispatchMetod={setStatus} />
            ) : (
                ""
            )}
            <Swal status={costSummaryState?.status} dispatchMetod={costSummarySetStatus} />

            <div className="bg-black px-[12px] pt-[50px] pb-[0px] w-full h-full fixed">
                <p className="flex justify-center uppercase mb-[50px] tracking-widest font-normal text-[#F41095]">
                    BOOK A ROOM
                </p>

                <Calender queryDetails={queryDetails} />
                <GuestCount queryDetails={queryDetails} setQueryDetails={setQueryDetails} />

                {loading ? (
                    <RatePlanSkelton />
                ) : (
                    <RatePlan
                        loading={loading}
                        corpCode={corpCode}
                        ratePlans={ratePlans}
                        ratePlanCode={ratePlanCode}
                        isOpen={isCollapseRatePlan}
                        resetCorpCode={resetCorpCode}
                        resetRatePlan={resetRatePlan}
                        funCollapse={collapseRatePlan}
                        funSelectCorpCode={setCorpCode}
                        funSelectPlan={setRatePlanCode}
                        isCorpCategory={isCorpCategory}
                        selectedRatePlan={selectedRatePlan}
                        setSelectedRatePlan={setSelectedRatePlan}
                        funSelectCPolicy={setCancellationPolicy}
                        setIsCorpCategory={setIsCorpCategory}
                    />
                )}

                <div className="css-2khm96">
                    <a
                        className={`${loading ? "disabled cursor-not-allowed no-underline opacity-60" : ""
                            } px-[14px] w-full font-normal tracking-wide bg-[#F41095] text-white flex justify-center items-center h-[55px]`}
                        href={!loading ? handleClick() : null}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        CHECK AVAILABILITY
                    </a>

                    {/* <button
                        className="px-[14px] w-full border border-black border-solid font-normal tracking-wide bg-[#F41095] text-white flex justify-center items-center h-[39.1px]"
                        onClick={handleClick}
                        disabled={loading}
                    >
                        CHECK AVAILABILITY
                    </button> */}
                </div>
            </div>

            {/* <SectionA {...sectionProps} /> */}
        </>
    );
};

export default WebflowWebView;
