import React, { Fragment } from "react";
import { Rooms } from "..";
import Calender from "../calender";
import GuestCount from "../guestcount";
import RateplanComp from "./rateplanComp";
import { useLocation } from "react-router-dom";
import { Count, CustomContainer, Title } from "../../commonElements";
import { Abtest } from "../../mixpanel";

function SectionA({ setQueryDetails, queryDetails }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return (
    <CustomContainer className="bg-main  lg:bg-white pt-[32px] pb-[8px] lg:top-[58px] lg:sticky">
      <Count count={"1"} />
      <Abtest
       
        VariantA={
          <>
            <Title
              variant={"h1"}
              className={`text-magenta filter-brightness-150`}
              content={"Book your room"}
            />
            <p className="text-darkAsh text-[13px] flex justify-center mt-[4px] font-light uppercase mb-[32px]">
              REQUIRED
            </p>
          </>
        }
        VariantB={<div className=" uppercase mb-[32px] text-magenta filter-brightness-150 text-center leading-[15.6px] text-[13px] font-light" >Check availability</div>}
      />

      <Calender queryDetails={queryDetails} />
      <GuestCount
        queryDetails={queryDetails}
        setQueryDetails={setQueryDetails}
      />
      <Abtest
        VariantA={
          <Rooms
            isDateProvideInQuery={
              searchParams.get("datein") && searchParams.get("dateout")
                ? true
                : false
            }
            queryDetails={queryDetails}
            isdefaultRoomSelection={true}
          />
        }
        VariantB={
          <>
            <RateplanComp />
            <Rooms
              isDateProvideInQuery={
                searchParams.get("datein") && searchParams.get("dateout")
                  ? true
                  : false
              }
              queryDetails={queryDetails}
              isdefaultRoomSelection={false}
            />
          </>
        }
      />
    </CustomContainer>
  );
}

export default SectionA;
