import { Button, Title } from "../../commonElements";
import moment from "moment";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { costSummarySelector } from "../../features/costSummary/costSummarySlice";
import { Fragment, useEffect, useState } from "react";
// import { InfoIcon } from "../../icons";
import { IoInformationCircleOutline } from "react-icons/io5"
import { signInSelector } from "../../features/signIn/signInSlice";

const CostData = ({ data, className }) => (
  <div className={`flex flex-col ${className}`}>
    <p className="capitalize text-base font-normal leading-[1.5]">{data}</p>
  </div>
);

const ReservationSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { reservationRespData, totalCost, costSItems,totalTax,UpdatedTaxArary } = location.state;
  const [isCollapseTax,setIsCollapseTax] = useState(false)
  const costSummaryStates = useSelector(costSummarySelector);
  const signInStates = useSelector(signInSelector)

  const {
    firstName,
    lastName,
    roomTypeName,
    totalRoom,
    numberOfAdult,
    numberOfChildren,
    numberOfPets,
    reservationData,
    totalAmount,
  } = reservationRespData?.data;

  let guestDetails = [];
  totalRoom > 0 && guestDetails.push(`${totalRoom} Rooms `);
  numberOfAdult > 0 && guestDetails.push(`${numberOfAdult} Adults `);
  numberOfChildren > 0 && guestDetails.push(`${numberOfChildren} Children `);
  numberOfPets > 0 && guestDetails.push(`${numberOfPets} Pets `);

  const reservations = reservationData.reservations;
  const { reservationId, arrivalDate, departureDate } = reservations[0];
  console.log("reservations", reservations[0]);
  console.log("reservationRespData", reservationRespData);

  useEffect(() => {
    if (Object.keys(costSummaryStates?.reservationRespData).length == 0) {
      navigate(`/`)
    }
  }, []);

  const handleClick = () => {
    window.location.href = "https://shashihotel.com/";
  };

  return (
    <div
      className="w-full h-full  flex flex-col items-center overflow-auto p-4"
    >
      <h1 className="font-normal text-center text-black text-lg md:text-xl mt-[100px]">
        Thank You, {firstName + " " + lastName} <br />
        Your reservation is completed.
      </h1>
      <h3 className="font-light text-center text-black mt-[26px] text-[13px] md:text-[16px]">
        Reservation Number
      </h3>
      <h2 className="font-normal text-center text-black mt-[10px] mb-[47px] text-xl md:text-[24px]">
        {reservationId}
      </h2>

      <h1 className="font-normal text-center text-black text-lg md:text-xl mb-[37px]">
        Customize your stay, access your Mobile Key,
        <br />& more by downloading Shashi App today!
      </h1>

      <div className="mb-[31px]">
        <img
          src="https://res.cloudinary.com/shashigroup/image/upload/v1665658537/static%20Images%20-shashi-hotel-website/shashi-qr-code.png"
          alt="shashiQr"
          width="277"
          height="277"
        />
      </div>

      <div
        className="w-full p-[15px] md:p-[27px] bg-white"
        style={{ maxWidth: "561px" }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Title
            variant={"h2"}
            className={`text-magenta filter-brightness-150`}
            sx={{ fontSize: "0.8125rem" }}
            content="reservation details"
          />

          <div className="flex justify-between font-light text-[12px] mt-[38px]">
            <label>Check-In</label>
            <label>Check-Out</label>
          </div>
          <div className="flex justify-between font-normal text-[13px] mt-[6px]">
            <label>{moment(arrivalDate).format("ddd, MMM D, YYYY")}</label>
            <label>{moment(departureDate).format("ddd, MMM D, YYYY")}</label>
          </div>
        </Box>
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "35.3px",
          }}
        >
          {costSItems?.map((costSummaryItem, index) => (
            <Fragment key={index}>
              <div
                className={`flex justify-between ${
                  costSummaryItem.type === "taxesAndFees"
                    ? Number(totalTax) > 0 && "mb-[12.86px]"
                    : "mb-[15.2px]"
                }`}
              >
                <div className="flex items-center">
                  <CostData
                    data={costSummaryItem.packageName}
                    className={"items-start"}
                  />
                  {costSummaryItem.type === "taxesAndFees" && (
                    <span className="ml-[-2px]">
                      <IoInformationCircleOutline
                        className="ml-1 cursor-pointer"
                        onClick={() => setIsCollapseTax(!isCollapseTax)}
                        style={{ height: "20px", width: "20px", color: "gray" }}
                      />
                      {/* <Tooltip
                    classes={{ tooltip: "bg-black-imp" }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    className="my-tooltip"
                    title="A 10% Transient Occupancy Tax (TOT) and 0.195% CA Tourism Assessment Tax applicable to room costs, living wage, surcharges, services, and package inclusions per local taxes & fees. A 4.25% Living Wage Surcharge is applied to only room costs to assist in living wages for our staff. All taxes & fees are subject to continual change without notice in accordance with local laws provided to us for interpretation."
                  >
                    <IconButton>
                    <IoInformationCircleOutline style={{height:'20px', width:'20px', color:'gray'}}  />
                    </IconButton>
                  </Tooltip> */}
                    </span>
                  )}
                </div>
                {costSummaryItem.type === "taxesAndFees" ? (
                  <span
                    className={`${
                      costSummaryItem.type === "taxesAndFees" && "mt-[4px]"
                    }`}
                  >
                    <CostData
                      data={Number(totalTax).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      className={"items-end"}
                    />
                  </span>
                ) : (
                  <span>
                    <CostData
                      data={Number(
                        costSummaryItem.amount.replace(/[^0-9.-]+/g, "")
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      className={"items-end"}
                    />
                  </span>
                )}
              </div>
              {costSummaryItem.type === "taxesAndFees" && (
                <Collapse timeout={700} in={isCollapseTax}>
                  {UpdatedTaxArary?.length > 0 &&
                    UpdatedTaxArary.map((subTax, ind) => (
                      <div className="flex justify-between font-light text-[14px] mb-[3px]">
                        <p>{subTax.name}</p>
                        <p>
                          {Number(subTax?.taxAmt).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    ))}
                </Collapse>
              )}
            </Fragment>
          ))}

          <hr className="border-gray-600 border-solid border-1" />

          <div className="flex justify-between mt-[13.27px] leading-[20.3px]">
            <CostData data={"Total Cost"} className={"items-start"} />
            <CostData
              data={`$${
                typeof totalCost === "string"
                  ? Number(totalCost.replace(/[^0-9.-]+/g, ""))
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .slice(1)
                  : ""
              }`}
              className={"items-end"}
            />
          </div>
        </Box>
        {signInStates?.removeLogoutOption ? (
          <a href="inapp://upcoming">
            <Button
              type={"button"}
              className={"mt-[69px] bg-white text-black border border-black"}
              name={"Go to Reservation Details"}
              spanClassName={"font-normal"}
            />
          </a>
        ) : (
          <Button
            type={"button"}
            onClick={handleClick}
            className={"mt-[69px] bg-white text-black border border-black"}
            name={"Back to Shashi"}
            spanClassName={"font-normal"}
          />
        )}
      </div>
    </div>
  );
};

export default ReservationSuccess;
