import axios from 'axios';
import { config } from '../config';
import { getAuthToken } from "./cookieReducer";

/* <<<-----------INSTANCE FOR RESERVATION----------->>> */

export const AXIOS_RESERVATION = axios.create({
  baseURL: config.RESERVATION_API,
});

AXIOS_RESERVATION.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AXIOS_RESERVATION.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

/* <<<-----------INSTANCE FOR HOSPITALITY----------->>> */

export const AXIOS_HOSPITALITY = axios.create({
  baseURL: config.HOSPITALITY_API,
});

AXIOS_HOSPITALITY.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = authToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

AXIOS_HOSPITALITY.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);



