import { Route, Routes, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import "./App.css";
import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {  stripeSelector } from "./features/stripe/stripeSlice";
import { checkAuthTokenAvailability, getAuthData, setAuthToken } from "./util/cookieReducer";
import { getUserProfile } from "./features/signIn/api";
import {
  Err404,
  RoomBooking,
  ReservationCancel,
  ReservationFailure,
  ReservationCancelDetails,
  ReservationCancelSuccessful,
  ReservationCancelFailed,
  ReservationSuccess,
  WebflowWebView,
  VerifyUser,
  PasswordReset,
} from "./pages";
import { setIsToken, setUserData } from "./features/signIn/signInSlice";
import { setCorpCode, setIsCorpCategory, setRatePlanCode, setVisitedRoomBookingPage } from "./features/rooms/roomSlice";
import { RatePlan_Category } from "./config";

function App() {

  const isWebflowWebView = window.location.pathname.includes("webflowWebView");
  console.log("App.js isWebflowWebView:", isWebflowWebView);

  const { hotelDetails } =
  useSelector(stripeSelector);

  const { state,search } = useLocation();
  const reservationStatus = state?.reservationStatus;
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hotelDetails?.customBookingEngine === false) {
      window.location.href = hotelDetails?.defaultBookingEngineURL;
    }
  }, [hotelDetails]);

  const stripePromise = useMemo(() => {
    if(isWebflowWebView) return null
    
    const { loadStripe } = require("@stripe/stripe-js");
    return hotelDetails?.paymentGatewayDetails?.stripePublicKey ? loadStripe(hotelDetails?.paymentGatewayDetails?.stripePublicKey) : null;
  }, [hotelDetails]);

  useEffect(() => {

    let getUserQueryProp = {
      ratePlanCode: searchParams.get("ratePlanCode") || "",
      corpCode: searchParams.get('corpCode') || ""
    }

    window.FB_convertion_access_token = process.env?.REACT_APP_FB_CONVERTION_TOKEN || "";

    if (searchParams.get("AT")) {
      getUserQueryProp.token = searchParams.get("AT")
      setAuthToken(searchParams.get("AT"));
      dispatch(getUserProfile(getUserQueryProp));
    } else if (checkAuthTokenAvailability()) {
      dispatch(getUserProfile(getUserQueryProp));
    }

    if (
      searchParams.get('ratePlanCode')) {
      dispatch(setRatePlanCode(searchParams.get('ratePlanCode')))
      if (
        searchParams.get('corpCode') &&
        searchParams.get('ratePlanCategory') == RatePlan_Category.corp) {
        dispatch(setCorpCode(searchParams.get('corpCode')));
        dispatch(setIsCorpCategory(true));

      }
    }
    dispatch(setVisitedRoomBookingPage())
  }, []);

  useEffect(() => {
    dispatch(setIsToken(checkAuthTokenAvailability()));
    dispatch(setUserData(getAuthData()));
  }, [dispatch]);
  
  return isWebflowWebView ? (
      <Routes>
        <Route path="/webflowWebView" element={<WebflowWebView />} />
        <Route path="*" element={<Err404 />} />
      </Routes>
    ) : (
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path="/" element={<RoomBooking />} />
        <Route
          path="/success"
          element={!reservationStatus ? <Err404 /> : <ReservationSuccess />}
        />
        <Route
          path="/failure"
          element={reservationStatus == null || reservationStatus == true? <Err404 /> : <ReservationFailure />}
        />
        {/* <Route path="/reservation_cancel" element={<ReservationCancel />} /> */}
        <Route
          path="/reservation_cancel_details"
          element={<ReservationCancelDetails />}
        />
        <Route
          path="/reservation_cancel_successful"
          element={<ReservationCancelSuccessful />}
        />
        <Route
          path="/reservation_cancel_failed"
          element={<ReservationCancelFailed />}
          />
          <Route path="/verify" element={<VerifyUser />} />
          <Route
            path="/reset-password/:resetPasswordToken/"
            element={<PasswordReset />}
          />
        <Route path="*" element={<Err404 />} />
      </Routes>
    </Elements>
  );
}

export default App;
