import { AXIOS_HOSPITALITY } from "./axiosInterceptors"

export const verifyUserEmail = (token) => {
  return new Promise((resolve, reject) => {
    AXIOS_HOSPITALITY.get("verifyUserEmail", {
      params: { code: token }
    })
      .then((response) => {
        console.log('response',response);
        if (response?.data?.success) {
          resolve(response?.data?.result);
        } else {
          reject(response?.data?.result?.message);
        }
      })
      .catch((err) => {
        console.log(err?.code);
        if (!err?.code) {
          reject(err?.response?.data?.result?.message);
        } else {
          reject(err?.message);
        }
      });
  });
};
