// ROOM DESCRIPTION CONTENT A
const roomDescA = [
  {
    title: "ROOM AMENITIES",
    subData: [
      {
        title: "100% Egyptian Cotton Sheets",
      },
      {
        title: `Plush Towels  |  Luxurious Robes  |  Slippers  |  Turn Down Service`,
      },
      {
        title: "Hardwood Flooring‍",
      },
      {
        title: "Artisinal Coffee & Tea‍",
      },
      {
        title: "Safe  |  Full View Mirror‍",
      }
    ],
  },
  {
    title: "TECHNOLOGY",
    subData: [
      {
        title: `4K 65" TV  |  Apple TV  |  Chrome Cast‍`,
      },
      {
        title: "Wireless Charging Station  |  Complimentary WiFi",
      },
      {
        title: "Shashi App  |  Shashi Cash  |  Express Check-In",
      },
    ],
  },
  {
    title: "DINING - MICHELIN CHEF",
    subData: [
      {
        title: "Broma",
      },
      {
        title: "The Emerald Hour",
      },
      {
        title: "In-Room Dining",
      },
      {
        title: "The Marketplace",
      },
      {
        title: "Carte Blanche Café - Coming Summer 2023",
      }
    ],
  },
  {
    title: "HOTEL AMENITIES",
    subData: [
      {
        title: "Fitness Center  |  Complimentary Bikes",
      },
      {
        title: "Sauna  |  Steam  |  Pool  |  Jacuzzi",
      },
      {
        title: "Free Parking",
      }
    ],
  },
];

// ROOM DESCRIPTION CONTENT B
const roomDescB = [
  {
    title: "ROOM",
    subData: [
      {
        title: "Turn Down Service",
      },
      {
        title: "Hardwood Flooring",
      },
      {
        title: "Artisinal Coffee & Tea",
      },
      {
        title: "Safe",
      },
      {
        title: "100% Egyptian Cotton Sheets",
      },
      {
        title: "Plush Towels",
      },
      {
        title: "Luxurious Robes",
      },
      {
        title: "Slippers",
      },
      {
        title: "Full View Mirror",
      },
    ],
  },
  {
    title: "TECHNOLOGY",
    subData: [
      {
        title: "4K 65'' TV",
      },
      {
        title: "Apple TV",
      },
      {
        title: "Chrome cast",
      },
      {
        title: "Wireless Charging Station",
      },
      {
        title: "Shashi App",
      },
      {
        title: "Complimentary WiFi",
      },
    ],
  },
  {
    title: "DINING",
    subData: [
      {
        title: "Broma",
      },
      {
        title: "The Emerald Hour",
      },
      {
        title: "In-Room Dining",
      },
      {
        title: "Marketplace",
      },
      {
        title: "Carte Blanche Café - Coming Soon",
      },
      {
        title: "Belle Terre - Coming Soon",
      },
    ],
  },
  {
    title: "HOTEL",
    subData: [
      {
        title: "Fitness Center",
      },
      {
        title: "Sauna",
      },
      {
        title: "Steam",
      },
      {
        title: "Pool",
      },
      {
        title: "Jacuzzi",
      },
      {
        title: "Complimentary Bikes",
      },
    ],
  },
];

export const RoomDecObject = {roomDescA,roomDescB}
