import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY } from "../../util/axiosInterceptors";

export const verifyResetPswdTkn = createAsyncThunk(
  "resetPassword/submit",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
      let res = await AXIOS_HOSPITALITY.get("verifyResetLink", {
        params: { token: state },
      });
      return {
        apiMessage: res?.data?.result?.message,
        apiSuccess: res?.data?.success,
      };
    } catch (error) {

      throw rejectWithValue({
        apiMessage:
          error?.response?.data?.result?.message ||
          "Something went wrong please try again later",
        apiSuccess: error?.response?.data?.success,
      });
    }
  }
);

export const callResetPaswordApi = createAsyncThunk(
  "resetPassoword/submit",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
        let res = await AXIOS_HOSPITALITY.post("resetPassword", state);
      return {
        apiMessage:
          res.data?.result?.message,
        apiSuccess: res?.data?.success,
      }
      
    } catch (error) {
        throw rejectWithValue({
        apiMessage:
          error?.response?.data?.result?.message ||
          "Something went wrong please try again later",
        apiSuccess: false,
      });
    }
  }
);
