import { styled } from "@mui/material";
import React from "react";



function CoverImage({ alt, src, ...props }) {
 
  const Image = styled("img")({});
  return (
    <div className="relative">
    <Image
      loading="lazy"
      src={src}
      alt={alt}
      {...props}
      className="
    bg-gray-300
    aspect-[10/5]
      w-full h-full 
      object-cover
      z-[-100]
      "
    />
    </div>
  );
}

export default CoverImage;
