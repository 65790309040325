import { createSlice } from "@reduxjs/toolkit";
import { makePayment, makeReservation, makeTokenization } from "./api";

const initialState = {
  loading: false,
  isChecked: true,
  signInOpen: false,
  cancellationIsOpen: false,
  // petIsOpen: false,
  termsAndConditionIsOpen: false,
  openPayInfo: false,
  isSignInOption: true,
  paymentMethod: "card",
  paymentMode: "fuesbox",
  costSItems: [
    {
      packageName: "Room Cost",
      amount: `0.00`,
      amt: 0,
      type: "roomCost",
    },
    {
      packageName: "Taxes & Fees",
      amount: `0.00`,
      amt: 0,
      type: "taxesAndFees",
    },
  ],
  totalCost: 0,
  amount: 0,
  data: {},
  bookingData: [],
  guestInfo: {},
  paymentResData: {},
  reservationRespData: {},
  reservationStatus: null,
  packagesAndRoomTotalCost:null,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
  formFieldInitialState: {
    firstName: "",
    lastName: "",
    email: "",
    countryCodeForMobileNumber: "+1",
    mobileNoWithoutCountryCode: "",
    name: "",
    card: "",
    expiryDate: "",
    cvv: "",
    country: { value: "US", label: "United States" },
    zipCode: "",
    isUsrProfileNotFoundGpay: false,
  },
};
export const costSummarySlice = createSlice({
  name: "costSummary",
  initialState,
  reducers: {
    setIsChecked: (state, action) => {
      state.isChecked = action.payload;
    },
    setSignInOpen: (state, action) => {
      state.signInOpen = action.payload;
    },
    setCancellationIsOpen: (state, action) => {
      state.cancellationIsOpen = action.payload;
    },
    // setPetIsOpen: (state, action) => {
    //   state.petIsOpen = action.payload;
    // },
    setOpenPayInfo: (state, action) => {
      state.openPayInfo = action.payload;
    },
    setIsSignInOption: (state, action) => {
      state.isSignInOption = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setPaymentMode: (state, action) => {
      state.paymentMode = action.payload;
    },
    setCostSItems: (state, action) => {
      state.costSItems = action.payload;
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload;
      state.amount = Math.round(parseFloat(action.payload) * 100);
    },
    setBookingData: (state, action) => {
      state.bookingData = [action.payload];
    },
    setGuestInfo: (state, action) => {
      state.guestInfo = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTermsAndConditionIsOpen: (state, action) => {
      state.termsAndConditionIsOpen = action.payload;
    },
    setPackagesAndRoomTotalCost: (state, action) => {
      state.packagesAndRoomTotalCost = action.payload;
    },
    setGuestProfileToForm: (state, { payload }) => {
      const {
        mobileNoWithoutCountryCode,
        firstName,
        lastName,
        country,
        name,
        cvv,
        card,
        email,
        zipCode,
        expiryDate,
        countryCodeForMobileNumber,
      } = payload;
      state.formFieldInitialState.email = email || "";
      state.formFieldInitialState.lastName = lastName || "";
      state.formFieldInitialState.firstName = firstName || "";
      state.formFieldInitialState.countryCodeForMobileNumber =
        countryCodeForMobileNumber || "+1";
      state.formFieldInitialState.mobileNoWithoutCountryCode =
        mobileNoWithoutCountryCode || "";

      // CARD DETAILS
      state.formFieldInitialState.name = name || "";
      state.formFieldInitialState.card = card || "";
      state.formFieldInitialState.expiryDate = expiryDate || "";
      state.formFieldInitialState.cvv = cvv || "";
      state.formFieldInitialState.country = country || {
        value: "US",
        label: "United States",
      };
      state.formFieldInitialState.zipCode = zipCode || "";
    },
    resetPaymentForm: (state) => {
      state.formFieldInitialState.firstName = "";
      state.formFieldInitialState.lastName = "";
      state.formFieldInitialState.email = "";
      state.formFieldInitialState.countryCodeForMobileNumber = "+1";
      state.formFieldInitialState.mobileNoWithoutCountryCode = "";
      state.formFieldInitialState.name = "";
      state.formFieldInitialState.card = "";
      state.formFieldInitialState.expiryDate = "";
      state.formFieldInitialState.cvv = "";
      state.formFieldInitialState.country = {
        value: "US",
        label: "United States",
      };
      state.formFieldInitialState.zipCode = "";
    },
    setReservationStatus: (state, action) => {
      state.reservationStatus = action.payload;
    },
    setUserProfileNotFoundGPay: (state, { payload }) => {
      state.isUsrProfileNotFoundGpay = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeTokenization.pending, (state) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(makeTokenization.fulfilled, (state, { payload }) => {
        // state.loading = false;
        state.status = payload.status;
        state.data = payload.tokenizedData;
      })
      .addCase(makeTokenization.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
      })
      .addCase(makePayment.pending, (state) => {
        state.loading = true;
        state.paymentResData = {};
      })
      .addCase(makePayment.fulfilled, (state, { payload }) => {
        // state.loading = false;
        state.status = payload.status;
        state.paymentResData = payload.paymentResData;
      })
      .addCase(makePayment.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
      })
      .addCase(makeReservation.pending, (state) => {
        state.loading = true;
        state.reservationRespData = {};
      })
      .addCase(makeReservation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
        state.reservationRespData = payload.reservationRespData;
        state.reservationStatus = true;
      })
      .addCase(makeReservation.rejected, (state, { payload }) => {
        state.loading = false;
        state.status = payload.status;
        state.reservationStatus = false;
      });
  },
});

export const {
  setIsChecked,
  setSignInOpen,
  setOpenPayInfo,
  setIsSignInOption,
  setBookingData,
  setTotalCost,
  setPaymentMethod,
  setPaymentMode,
  setGuestInfo,
  setStatus,
  setCostSItems,
  resetPaymentForm,
  setCancellationIsOpen,
  // setPetIsOpen,
  setGuestProfileToForm,
  setPackagesAndRoomTotalCost,
  setUserProfileNotFoundGPay,
  setTermsAndConditionIsOpen,
  setReservationStatus,
} = costSummarySlice.actions;

export const costSummarySelector = (state) => state.costSummary;

export default costSummarySlice.reducer;
