import { createSlice } from "@reduxjs/toolkit";
import { fetchpaymentDetails } from "./api";

let initialState = {
  hotelDetails: {}
};

const stripeSlice = createSlice({
  initialState,
  name: "stripe",
  extraReducers: (builder) => {
    builder
      // CASE FOR USER SIGN IN --->>>
      .addCase(fetchpaymentDetails.pending, (state) => {
        state.hotelDetails = {};
      })
      .addCase(fetchpaymentDetails.fulfilled, (state, { payload }) => {
        state.hotelDetails = payload;
      })
      .addCase(fetchpaymentDetails.rejected, (state, { payload }) => {
        state.hotelDetails = {};
      });
  },
});

export default stripeSlice.reducer;
export const stripeSelector = (state) => state.stripe;
