import { Backdrop } from "@mui/material";
import React, { useEffect } from "react";

function Index({ open, handleClose, children, sx, ...props }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else if (open === false) {
      document.body.style.overflow = "scroll";
    }
  }, [open]);
  return (
    <Backdrop
      {...props}
      sx={{ color: "#fff", zIndex: 100, ...sx }}
      open={open}
      onClick={handleClose}
      children={children}
    />
  );
}

export default Index;
