import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackDrop, CustomContainer, Title } from "../../commonElements";
import { RoomCard } from "../../components";
import { roomSelector, showAllRooms } from "./roomSlice";
import { motion } from "framer-motion";
import { Modal } from "@mui/material";
import { signInSelector } from "../signIn/signInSlice";
function AvailableRooms() {
  const { isRoomChangeFlag, roomType } = useSelector(roomSelector);
  const {removeLogoutOption} = useSelector(signInSelector)
  const dispatch = useDispatch();
  return (
    <Modal
      sx={{ zIndex: "35", overflow: "auto" }}
      open={isRoomChangeFlag}
      onClose={() => {
        dispatch(showAllRooms(false));
      }}
    >
      <motion.section
        onClick={() => {
          dispatch(showAllRooms(false))
          // if (removeLogoutOption) {
          //   window.location.href = "inapp://RoomTypeChangeDone";
          // }
        }}
        style={{ outline: "none",paddingBottom:removeLogoutOption?'120px':'50px' }}
        initial={{ opacity: 0.3 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className={`bg-main pt-[90px] pb-[50px] pl-[18px] pr-[18px] min-h-screen`}
      >
        <Title variant={'h1'} content={"Book your room"} className={`text-magenta filter-brightness-150`} />
        {roomType?.length > 0 &&
          roomType.map((sltdRm, index) => (
            <RoomCard
              uniqueTag={'AVAILABLE_ROOM_'}
              isRoomChangeFlag={isRoomChangeFlag}
              index={index}
              key={index}
              RoomDetails={sltdRm}
            />
          ))}
      </motion.section>
    </Modal>
  );
}

export default AvailableRooms;
