import { useDispatch, useSelector } from "react-redux";
import { CustomBox } from "../../commonElements";
import { BiMinus, BiPlus } from "react-icons/bi";
import { roomSelector, triggerRoomApi } from "../rooms/roomSlice";
import { useEffect, useState } from "react";
import sweetAlert from "sweetalert";
import "../../components/sweetAlert/sweetAlert.css";


function GuestCount({ queryDetails, setQueryDetails }) {
  const { adults, children, pets } = queryDetails;
  const dispatch = useDispatch()
  const { selectedRoom } = useSelector(roomSelector);
  // const [status,setStatus] = useState({message :"", success:null})

  useEffect(() => {
    //   maxAdultCount, maxChildCount, minPetCount, maxOccupancy,
    //   minAdultCount, minChildCount, maxPetCount, minOccupancy,

    // if (selectedRoom.length > 0) {
    // queryDetails.adults = String(selectedRoom[0]?.minAdultCount);
    // queryDetails.children = String(selectedRoom[0]?.minChildCount);
    // queryDetails.pets = String(selectedRoom[0]?.minPetCount);
    // setQueryDetails({ ...queryDetails });
    // }
  }, [selectedRoom]);

  useEffect(() => {
    dispatch(triggerRoomApi())
  }, [queryDetails.adults, queryDetails.children, queryDetails.pets]);

  function Counter({
    value,
    label,
    keyName,
    minLimit,
    maxLimit,
    queryDetails,
    maxOccupancy,
    setQueryDetails,
    sumOfAdultChild = false,
  }) {
    return (
      <div className="flex flex-col items-center justify-center">
        <label className="text-[10px] uppercase">{label}</label>
        <div className="flex items-center flex-nowrap text-xl mt-3">
          <BiMinus
            onClick={() => {
              if (value <= minLimit) return;
              queryDetails[keyName] = String((value -= 1));
              setQueryDetails({ ...queryDetails });
            }}
            className={`text-xl mr-1 ${
              value == minLimit
                ? `text-gray-300 cursor-not-allowed`
                : `cursor-pointer`
            }`}
          />
          <span className="pt-[3px] leading-[17px] w-3">{value}</span>
          <BiPlus
            onClick={() => {
              if (
                value >= maxLimit ||
                (sumOfAdultChild && sumOfAdultChild >= maxOccupancy)
              ) {
                // setStatus({
                //   message: sumOfAdultChild
                //     ? `Maximum number of person for room occupancy reached`
                //     : `Maximum number of pet for room occupancy reached`,
                //   success: false,
                // });
                return;
              }
              queryDetails[keyName] = String((value += 1));
              setQueryDetails({ ...queryDetails });
            }}

            className={`text-xl ml-1 ${
              value == maxLimit || sumOfAdultChild == maxOccupancy
                ? `text-gray-300 cursor-not-allowed`
                : `cursor-pointer`
            }`}
          />
        </div>
      </div>
    );
  }

  // useEffect(() => {
  //   // ADD STATUS FOR TEMPORERLY. NEED TO MAKE IT GLOBAL STATUS

  //   if (status.message && status.success == false) {
  //     sweetAlert({
  //       text: status.message,
  //       buttons: {
  //         confirm: {
  //           className: "bg-black",
  //           text: "OK",
  //         },
  //       },
  //     }).then((e) => {
  //       setStatus({ message: "", success: null });
  //     })
  //   }
  // },[status]);


  return (
    <CustomBox
      className="pl-[14px] pr-[14px] pt-[11px] pb-[11px] flex justify-between"
      sx={{ marginTop: "35px" }}
    >
      <Counter
        label={"ADULTS"}
        isRequired={true}
        keyName={"adults"}
        value={Number(adults)}
        queryDetails={queryDetails}
        setQueryDetails={setQueryDetails}
        minLimit={1}
        maxLimit={2}
        maxOccupancy={selectedRoom[0]?.maxOccupancy}
        sumOfAdultChild={Number(adults) + Number(children)}
      />

      <Counter
        value={Number(children)}
        label={"CHILDREN"}
        keyName={"children"}
        queryDetails={queryDetails}
        setQueryDetails={setQueryDetails}
        minLimit={0}
        maxLimit={2}
        maxOccupancy={selectedRoom[0]?.maxOccupancy}
        sumOfAdultChild={Number(adults) + Number(children)}
      />

      <Counter
        value={Number(pets)}
        label={"PETS"}
        keyName={"pets"}
        queryDetails={queryDetails}
        setQueryDetails={setQueryDetails}
        minLimit={0}
        maxLimit={2}
      />
    </CustomBox>
  );
}

export default GuestCount;
