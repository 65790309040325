import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import sweetAlert from "sweetalert";
import "./sweetAlert.css";
function Swal({ status, dispatchMetod }) {
  const { apiMessage, apiSuccess } = status;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!apiSuccess && apiMessage) {
  //     sweetAlert(apiMessage);
  //   }

  useEffect(() => {
    if (!apiSuccess && apiMessage) {
      sweetAlert({
        text: apiMessage,

        buttons: {
          confirm: {
            className: "bg-black",
            text: "OK",
          },
        },
      });
    }

    return () => {
      // for reset status
      if (!dispatchMetod) return;
      dispatch(
        dispatchMetod({
          apiMessage: "",
          apiSuccess: true,
        })
      );
    };
  }, [apiMessage, apiSuccess]);
  return <Fragment />;
}

export default Swal;
