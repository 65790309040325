import { Container } from "@mui/system";
import React from "react";

export default function index({ children, sx, ...props }) {
  return (
    <Container
      {...props}
      sx={{
        paddingLeft: { xs: "18px", md: "18px" },
        paddingRight: { xs: "18px", md: "18px" },
        height: "fit-content",
        ...sx,
      }}
      children={children}
    />
  );
}
