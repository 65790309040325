import React from 'react';
import { Button } from '../../commonElements';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setReservationStatus } from '../../features/costSummary/costSummarySlice';
import { FailureIcon } from '../../icons';

const ReservationFailure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const location = useLocation();
 
  const {status} = location.state;

  console.log('status from failur',status);

  const handleClick = () => {
    navigate('/')
    dispatch(setReservationStatus(null))
  };

  return (
    <div className="flex justify-center items-center w-full h-auto bg-main p-4">
    <div className=" w-full sm:w-[561px] mx-auto pb-[33px] bg-white px-[24px] h-fit">
      <div className="w-full mx-auto flex items-center justify-center mt-[109px]">
        <FailureIcon />
      </div>
      <h1 className="font-light text-center text-black mt-[56px] pb-[48px] text-[22px]">
        Oops, something went wrong.
        <br />
        Please try again.
      </h1>
      <h1 className="font-light text-center text-black text-[22px]">
        If the issue persists,
        <br />
        please contact Front Desk at <br />
        (650) 420-2600.
      </h1>
      <Button
        type={"button"}
        className={"mt-[49px] bg-black text-white border"}
        name={"Back to Shashi"}
        spanClassName={"font-normal"}
        onClick={handleClick}
      />
    </div>
  </div>
  );
};

export default ReservationFailure;
