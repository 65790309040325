import { ButtonBase, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { CustomBox, CustomSwiper } from "../../commonElements";
import CoverImage from "../coverImage";
import BubbleAnimate from "./bubbleAnimate";

function SelectWithImg({
  images,
  title,
  options,
  onClickMethod,
  requestBodyKey,
}) {
  const dispatch = useDispatch();
  return (
    <CustomBox className={`mb-[35px]`}>
      <Typography className={`p-4`} variant="h4">
        {title}
      </Typography>
      <CustomSwiper>
        {images?.length &&
          images.map((src, index) => (
            <SwiperSlide key={index} lazy={true}>
              <CoverImage alt={title} src={src} />
            </SwiperSlide>
          ))}
      </CustomSwiper>
      <div className="p-4 flex gap-[24px] flex-col">
        <div className=" flex flex-wrap gap-[24px] justify-start">
        {options?.length &&
          options.map(
            ({ title, tag, isSelected, subtitle, description }, index) => (
              <div key={index}>
                <BubbleAnimate>
                  <ButtonBase
                    sx={{
                      borderRadius: "50%",
                      boxShadow: isSelected
                        ? "0 0 0 0.4px #CC005E"
                        : "0 0 0 0.4px gray",
                    }}
                  >
                    <div
                      onClick={() => {
                        dispatch(onClickMethod({ requestBodyKey, index }));
                      }}
                      className={`${
                        isSelected
                          ? `border-magentaDark border-[1px]`
                          : `border-black border-[0.5px]`
                      }
                  bg-main
                  filter-brightness-150
                  w-[42px] h-[42px] rounded-full 
                  flex flex-col justify-center 
                  text-center font-light cursor-pointer-desktop`}
                    >
                      <span className={` text-[14px] leading-[17.77px]`}>
                        {title}
                      </span>
                      <span
                        className={`text-[8px] font-normal leading-[7.61px] uppercase`}
                      >
                        {subtitle}
                      </span>
                    </div>
                  </ButtonBase>
                </BubbleAnimate>
                <p className="whitespace-pre text-[12px] mt-[5px] text-center">
                  {tag}
                </p>
              </div>
            )
          )}
        </div>
        <div>
          {options.length > 0 &&
            options[0]?.description &&
            options[0]?.description
              .split("+")
              .slice(1)
              .map((e) => (
                <div className="flex flex-nowrap flex-row">
                  <span className="text-black   mr-[4px] font-light text-[13px] ">
                    +
                  </span>
                  <p
                    className={` text-start text-[0.75rem] font-light "text-black`}
                  >
                    {e}
                  </p>
                </div>
              ))}
        </div>
      </div>
    </CustomBox>
  );
}

export default SelectWithImg;
