import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { CustomBox } from "../../commonElements";

function RoomCardSkeleton() {
  const SmTextSkel = (props) => (
    <Skeleton
      variant="text"
      sx={{
        bgcolor: "#e0e0e0",
        ...props,
      }}
    />
  );

  const SmTextBse = ({ children, className }) => (
    <div
      className={`
      pl-[14px] pt-[14px] 
      pr-[14px] flex flex-wrap 
      justify-between ${className}`}
    >
      {children}
    </div>
  );

  const ImageSkel = () => (
    <Skeleton
      className="aspect-[10/5]  w-full"
      variant="rectangular"
      sx={{
        bgcolor: "#e0e0e0",
        height: "auto",
        marginTop: "18px",
      }}
    />
  );

  return (
    <CustomBox
      onClick={(e) => e.stopPropagation()}
      className={`room-type-baseDiv text-black `}
      sx={{ marginTop: "32px" }}
    >
      <SmTextBse>
        <SmTextSkel height="15px" width="68px" />
        <SmTextSkel height="15px" width="68px" />
      </SmTextBse>
      <SmTextBse>
        <SmTextSkel width="90px" />
        <SmTextSkel width="90px" />
      </SmTextBse>
      <ImageSkel />
      <SmTextBse className="mb-2">
        <SmTextSkel width="70px" height="15px" />
        <SmTextSkel width="50px" height="15px" />
        <SmTextSkel width="30px" height="15px" />
      </SmTextBse>

      {/* <Skeleton variant="rectangular" /> */}
    </CustomBox>
  );
}

export default RoomCardSkeleton;
