import React  from "react";
import { useSelector } from "react-redux";
import { roomSelector } from "../../features/rooms/roomSlice";
import { Navigate } from "react-router-dom";

function IntroPage() {
  const { isVisitedRoomBookingPage } = useSelector(roomSelector);
  if (isVisitedRoomBookingPage)
    return (
      <section className="fixed top-0 bottom-0 bg-main  overflow-auto w-full h-full flex justify-center p-6">
        <div className=" w-full sm:w-[561px] bg-white mt-auto mb-auto flex flex-col items-center py-[87px] px-[52px]">
          <img src="/images/icons/hotelCartoon.svg" alt="" />
          <h3 className="mt-[78px] text-[24px] text-center font-normal leading-[28.8px]">
            This is the Shashi Booking Engine page.
          </h3>
          <h3 className="text-[20px] text-center font-light mt-[36px] leading-[24px]">
            Please go to the hotel specific booking page to continue with your
            reservation process.
          </h3>
        </div>
      </section>
    );
  
  return <Navigate to={'/'}/>
}

export default IntroPage;
