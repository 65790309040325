import { Box } from "@mui/material";
import React, { useRef } from "react";
import { useInView } from "framer-motion";
function CustomBox({ sx, className, darkMode, children, ...props }) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        transitionDelay: "0.150s",
        transitionDuration: "2s",
        maxWidth: {
          xs: "500px",
        },
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        bgcolor: darkMode ? "black.main" : "white.main",
        ...sx,
        // transform:isInView?'translateY(0px)':'translateY(25px)',
        // opacity: isInView ? `1` : `0.7`,
      }}
      // -translate-5px
      className={`${className}  
      ${`border-black border-[0.5px] `} `}
      children={children}
    />
  );
}

export default CustomBox;
