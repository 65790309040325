import { Form, Formik } from "formik";
import React from "react";
import { resetPasswordSchema } from "../../commonElements/validationSchema";
import { Input } from "../../commonElements/input/cardInput";
import { Button, Title } from "../../commonElements";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { callResetPaswordApi } from "./api";
import { resetPasswordSelector } from "./resetPasswordSlice";
import { useParams } from "react-router-dom";

const loadingThreeDots = () => (
  <ThreeDots
    height="24"
    width="24"
    radius="9"
    color="white"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);

function ResetPassword() {
  const dispatch = useDispatch();
  const { resetPasswordToken } = useParams();
  const { isLoading } = useSelector(resetPasswordSelector);

  return (
    <div className="bg-white px-10 sm:px-18 py-10 w-[430px] mb-[58px] lg:mb-0">
      <Title
        variant={"h1"}
        content={`reset password`}
        className={`text-magenta filter-brightness-150`}
      />
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={resetPasswordSchema}
        onSubmit={({ password }) =>
          dispatch(callResetPaswordApi({ token: resetPasswordToken, password }))
        }
      >
        {() => (
          <Form className="mt-6">
            <Input placeholder="Password" name="password" type="password" />
            <Input
              placeholder="Confirm Password"
              name="confirmPassword"
              type="password"
            />

            <Button
              type={"submit"}
              className={"text-white bg-black"}
              spanClassName={"font-normal"}
              name={!isLoading && "Submit"}
              loadingThreeDots={isLoading && loadingThreeDots()}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
