import React, { Fragment } from "react";
import { CloseButton } from "../../commonElements/button";

function TermsConditions({ handleClose }) {
  return (
    <Fragment>
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full p-6 m-auto bg-white text-black text-justify shadow-md max-w-7xl relative mx-5"
      >
        <div className="right-0 absolute mr-2 -mt-4 sm:mt-auto sm:mr-6">
          <CloseButton handleClose={handleClose} />
        </div>

        <h1 className="font-normal text-center text-black mt-7 text-xl">
          HOTEL TERMS &amp; CONDITIONS
        </h1>

        <div className="overflow-y-scroll h-[75vh]">
          <div className="m-5">
            <p className="font-light text-base">
              Your reservation at Shashi Hotel will be subject to the following
              Hotel Policy Terms & Conditions. When you make your reservation,
              you will be deemed to have read, understood, and accepted these
              Terms & Conditions.
            </p>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">CHECK IN / CHECK OUT</p>

            <ul className="font-light text-base list-none ">
              <li>Check-In time is 3:00 PM.&nbsp;</li>
              <li>Checkout time is 11:00 AM.</li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">RESERVATIONS</p>

            <ul className="font-light text-base list-none ">
              <li>
                All reservations must be guaranteed with a valid credit card at
                the time of booking. The hotel reserves the right to cancel the
                reservation if a valid credit card is not present. The credit
                card used to guarantee the reservation must be present upon
                Check-In at the respective properties. Proper photo
                identification and credit card are required for Mobile Check-In
                with a Mobile Key (digital key).&nbsp;
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">PRICING AND PAYMENT</p>

            <ul className="font-light text-base list-none ">
              <li>
                All prices and rates are subject to change without prior notice.
                Advance Purchase Rate Plans require full payment at the time of
                booking. Specific Advance Purchase Rate Plans are
                non-cancellable, non-refundable, non-changeable and
                non-transferable specifically stated otherwise in the Rate
                Description.&nbsp;
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">CANCELLATION & NO-SHOW POLICY</p>

            <ul className="font-light text-base list-none ">
              <li>
                Reservation cancellations must be completed 48 hours prior to
                the arrival date and time in accordance with the local time
                zone. Reservations cancelled within 48 hours will be assessed a
                fee of one night room & tax. No-Show Reservations fail to
                check-in on the arrival date booked unless the hotel is properly
                notified of travel delays. Subsequent days of a No-Show
                reservation will be cancelled. No-Show Reservations will be
                assessed a fee of one night room & tax.Specific Advance Purchase
                Rate Plan Cancellations & No-Shows will be assessed a fee of the
                full amount of the reservation & tax. Re-instatement of
                Cancelled and No-Show Reservations are subject to availability.
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">SPECIAL REQUESTS</p>

            <ul className="font-light text-base list-none ">
              <li>
                Room Type Upgrades, Early Check-In, and Late Checkout are
                available for purchase. In the unlikely event we are unable to
                accommodate the request, your purchase will be refunded in full.
                Complimentary special requests for specific guestroom
                attributes, Early Check-In, and Late Checkout are subject to
                availability.&nbsp;
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">CHILDREN POLICY</p>

            <ul className="font-light text-base list-none ">
              <li>
                Children are considered 17 years of age and under are
                complimentary with an accompanying adult and must be supervised
                at all times.
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">PET POLICY</p>

            <ul className="font-light text-base list-none ">
              <li>
                Guests may bring a maximum of 2 canine companions weighing a
                maximum of 50 pounds each. Feline and other animal species are
                prohibited. A $125 non-refundable pet fee will be assessed per
                pet at the time of check-in. Service Animals exempt. Please
                schedule a specific time to schedule room cleaning upon arrival
                with the Front Desk. Housekeeping is prohibited from entering
                guestrooms with pets when pet owners are not present.&nbsp;
              </li>
            </ul>
          </div>

          <div className="m-5">
            <p className="font-normal text-lg">PARKING</p>

            <ul className="font-light text-base list-none ">
              <li>
                Parking is complimentary. Parking prices are subject to change
                without notice due to special event days and city-wide
                activities. For specific inquiries of current parking prices,
                please contact the front desk.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TermsConditions;
