import React from "react";
import { ReservationCancelFailedIcon } from "../../icons";
import { motion } from "framer-motion";
function Error({ status }) {
  return (
    <motion.div
      initial={{ opacity: 0.2, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.5 }}
      className="bg-white px-10 sm:px-18 py-10 w-[530px] mb-[58px] lg:mb-0 flex flex-col items-center justify-center"
    >
      <ReservationCancelFailedIcon />
      <p className="mt-6 font-light text-[19px] text-center">
        {status?.apiMessage}
      </p>
    </motion.div>
  );
}

export default Error;
