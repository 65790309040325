import React, { Fragment, useEffect } from "react";
import App from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { stripeSelector } from "../../features/stripe/stripeSlice";
import { fetchpaymentDetails } from "../../features/stripe/api";
import { useNavigate } from "react-router-dom";
import { setVisitedRoomBookingPage } from "../../features/rooms/roomSlice";

function RootPage() {
  const { hotelDetails } = useSelector(stripeSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setVisitedRoomBookingPage());
    let callback = () => {
      navigate("/intro");
    };
    dispatch(fetchpaymentDetails({ callback }));
  }, []);

  if (hotelDetails?.hotelCode) {
    return <App />;
  } else {
    return <Fragment />;
  }
}

export default RootPage;
