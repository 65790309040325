import { setAuthToken } from "../../util/cookieReducer";
import { AddNewUser, UpdateLoyalty } from "./api";

const { createSlice, isFulfilled } = require("@reduxjs/toolkit");
const initialState = {
  isCreateNewUser: false,
  collapseEnroll:false,
  isLoading: false,
  isChecked: false,
  status: {
    apiMessage: "",
    apiSuccess: null,
  },
  isShashiCashTermsAndCondition:false
};
const enrollSlice = createSlice({
  name: "enroll",
  initialState,
  reducers: {
    setIsCreateNewUser: (state, { payload }) => {
      state.isCreateNewUser = payload;
    },
    setIsChecked: (state, { payload }) => {
      state.isChecked = payload;
    },
    setStatus:(state,{payload}) => {
      state.status = payload
    },
    setShashiCashTermsAndCondition: (state, { payload }) => {
      state.isShashiCashTermsAndCondition = payload;
    },
    setCollapseEnroll: (state,{payload}) => {
      state.collapseEnroll = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddNewUser.pending, (state) => {
        state.isLoading = true;
        state.status = {
          apiMessage: "",
          apiSuccess: null,
        };
      })
      .addCase(AddNewUser.fulfilled, (state, { payload }) => {
        setAuthToken(payload.token)
        // state.isLoading = false;
        state.isCreateNewUser = false
      })
      .addCase(AddNewUser.rejected, (state, { payload }) => {
        // console.log('payload:', payload)

        state.isLoading = false;
        state.status = payload.status;
        state.isChecked = false
      })

      // FOR UPDATE USE LOYALTY ----->>.
      .addCase(UpdateLoyalty.pending, (state) => {
        state.isLoading = true;
        state.status = {
          apiMessage: "",
          apiSuccess: null,
        };
      })
      .addCase(UpdateLoyalty.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(UpdateLoyalty.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default enrollSlice.reducer;
export const { setIsCreateNewUser, setIsChecked, setStatus, setShashiCashTermsAndCondition, setCollapseEnroll } = enrollSlice.actions;
export const enrollSelector = (state) => state.enroll;
