import React from "react";
import { BackDrop } from "../../commonElements";
import {
  enrollSelector,
  setShashiCashTermsAndCondition,
} from "../../features/enroll/enrollSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { CloseButton } from "../../commonElements/button";

function TandC() {
  const dispatch = useDispatch();
  const { isShashiCashTermsAndCondition } = useSelector(enrollSelector);

  function close() {
    dispatch(setShashiCashTermsAndCondition(false));
  }
  return (
    <BackDrop open={isShashiCashTermsAndCondition} handleClose={close}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="shashi-cash-backdrop w-full h-fit p-6 bg-white text-black text-justify shadow-md max-w-7xl relative mx-5 my-5 overflow-hidden"
      >
        <div className="right-0 absolute mr-2 -mt-4 sm:mt-auto sm:mr-6">
          <CloseButton handleClose={close} />
        </div>

        <h1 className="font-normal uppercase text-center text-black mt-7 text-xl">
          Shashi Cash Terms & Conditions
        </h1>

        <div className="overflow-y-scroll h-[71vh] sm:h-[75vh] px-5">
          <p className="mt-5">
            {`Thank you for viewing the Shashi Cash Terms & Conditions for Shashi
          Group LLC’s Loyalty Program deemed “Shashi Cash”. Your use of the
          Shashi Website and Mobile Application are subject to these terms and
          conditions set forth on the individual pages within the Shashi Website
          and Mobile Application unless otherwise expressly stated in the Shashi
          Cash Terms & Conditions. The Loyalty Program offers benefits to Shashi
          Group’s Participating Properties that are owned, managed, operated, or
          licensed by Shashi Group that displays or links these Terms &
          Conditions.`}
          </p>
          <p>
            By agreeing to join Shashi Cash to receive 5% cash back on room
            reservations and dining, excluding taxes, fees, and gratuities, you
            agree to the following statements:
          </p>
          <p>
            {`You have read and accepted the Shashi Group’s Loyalty Program Shashi
          Cash Term & Conditions, and you have read and accepted the Website’s
          Terms & Conditions. You consent to the collection, use, and disclosure
          of your personal data by Shashi Group, Shashi Cash, Shashi Hotel, and
          Participating Properties, and their authorized third-party agents and
          licensees in accord with the Sashi Group Privacy Statement.`}
          </p>
          <p>
            The Shashi Cash benefits, offers, amenities, awards, and services
            are subject to availability and may be changed by Shashi Group at
            any time without notice. Shashi Group reserves the right to
            terminate any Loyalty Program account in violation of the Terms &
            Conditions. Any cash back earned is forfeited.
          </p>
          <p>
            {` These Shashi Cash Terms & Conditions supersede any previous legacy
          rules. At Shashi Group’s discretion, Shashi Cash redemption rules, of
          how Members can earn and spend Shashi Cash can be modified, changed,
          replaced, upgraded, or rebranded at any time with or without notice.
          Your acceptance to the Terms & Conditions means you accept and agree
          to the Shashi Cash Terms & Conditions. If you do not accept, please
          exit the Website and Mobile Application immediately.`}
          </p>
          <h2>Use of Cookies</h2>
          <p>
            A cookie is an encrypted text file located in browser directories.
            Cookies are used to help users navigate websites, perform functions
            within the website, and recall preferences used on the website to
            better user experience. By agreeing to use the site, you are
            agreeing to the use of cookies.
          </p>
          <p>For more information, please visit whatisacookie.org.</p>
          <h2>Location Based Services</h2>
          <p>
            {`Location Based Services are used to identify the user’s location
          within a Shashi owned, managed, operated, or licensed property in
          proximity to the user’s room and property’s key lock system. Location
          activity is only used when activating Mobile Key within the Shashi App
          at participating properties. Location services are only used by Shashi
          services to assist the user which include providing user special
          offers, suggestions, services, functionality, and promotions.`}
          </p>
          <h2>Enrollment, Eligibility & Earning Cash Back</h2>
          <p>
            Members are eligible for Shashi Cash under these conditions which
            may be subject to change with or without notice at the discretion of
            Shashi Group, LLC.
          </p>
          <p>
            To be eligible, members must meet these conditions. Members must
            download the Shashi App on iOS or Android Mobile Application stores.
            The Shashi App is a free Application. Prices can vary and change at
            any time with or without notice.
          </p>
          <p>
            {` Members must create a Shashi Profile containing necessary information
          to identify the user. A Shashi ID is an email that is permanent and
          cannot be changed. The Shashi ID will be used by Shashi Group as the
          user’s or member’s unique identifier. Members will not be allowed to
          change the Shashi ID. Members will be able to add additional emails to
          their Shashi Profile that is changeable.`}
          </p>
          <p>
            The Shashi ID and Primary Email must be verified by the User to earn
            Shashi Cash. Upon verification the Member must enroll in Shashi
            Cash. Members can enter either a Bank Account, Paypal, or Venmo
            Account to transfer Shashi Cash. Without these Accounts Shashi Cash
            will remain in the Shashi Application until transferred. Cash
            transfer capable companies can be added or subtracted with or
            without notice.
          </p>
          <p>
            {`Member’s Reservations that are eligible must be shown, imported, or
          added on the App. In the event the Reservations are not added in the
          App, the App has features to assist in the process, or assistance is
          given at the property level.`}
          </p>
          <p>
            Members must complete the Reservation by completing the duration of
            their stay and by checking out of the property. Shashi Cash is
            transferred between 5-7 business days depending on bank institution
            and if members adhere and abide by the Shashi Cash Terms and
            Conditions
          </p>
          <h2>Participating Properties</h2>
          <p>
            Shashi Group, LLC participating properties include the Shashi Hotel.
            Properties can be added or subtracted over time without notice.
            Consult the Terms and Conditions for the latest participating
            properties.
          </p>
          <h2>Program Benefits</h2>
          <p>
            Members of Shashi Cash are eligible to earn 5% cash back on Room
            Reservations and Dining at participating Shashi Group owned,
            managed, operated, or licensed properties when reservations are made
            via Shashi App, Shashi Website, or accepted partner reservation
            portals.
          </p>
          <h2>Program Related Communication</h2>
          <p>
            Members of Shashi Cash may receive program related communication via
            SMS, push notifications, or internet emails of promotions, services,
            assistance, local attraction, or time sensitive offers.
          </p>
          <h2>Cancellation, Termination, or Suspending Program</h2>
          <p>
            Members can choose to delete their Shashi Cash Account. Data from
            the Shashi account will be deleted, and will no longer be stored
            with Shashi Group per local laws. Once a member deletes an account,
            the data can no longer be retrieved as it is no longer stored.
          </p>
          <p>
            Members cannot delete Shashi Cash profile or accounts if there is a
            pending balance within the profile. Any remaining Shashi Cash
            balance must be transferred to a valid account prior to account
            deletion.
          </p>
          <p>
            Members are subject to Termination or Suspension of Shashi Cash if
            the Member violates the Shashi Cash Terms & Conditions. Members are
            subject to review by Shashi Group, LLC and will be notified of the
            violation in question.
          </p>
          <h2>Contact Information</h2>
          <p>
            If you have any questions or concerns regarding the Terms &
            Conditions, please contact us at guestservices@shashihotel.com. Any
            communication transmitted to us via the App, website, or internet
            email is transmitted on a non-confidential basis. Communication and
            material can be used for business purposes such as training,
            research, and action by Shashi Group, LLC.
          </p>
          <p>Revised 1/2023. © 2023 Shashi Group, LLC. All Rights Reserved.</p>
        </div>
      </div>
    </BackDrop>
  );
}

export default TandC;
