import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY } from "../../util/axiosInterceptors";
import { getUserProfile } from "../signIn/api";

export const AddNewUser = createAsyncThunk(
  "enroll/addNewUser",
  async (state, { getState, dispatch, rejectWithValue }) => {
    try {
      const { stripe } = getState()
      const {getUserQueryProp, formData} =  state
      let { hotelDetails } = stripe
      var value = { confirmpassword: formData.password,OSType :'BE', ...formData };
      let updateLoyalityValue = { isLoyaltyApply: true, email: formData.email };
      let res = await AXIOS_HOSPITALITY.post("userRegistrationv5", value);
       await dispatch(UpdateLoyalty({updateLoyalityValue,token:res?.data?.result?.token,getUserQueryProp}))
        return res.data.result;
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: err?.response?.data?.success || false,
          apiMessage:
            err?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const UpdateLoyalty = createAsyncThunk(
  "enroll/UpdateLoyalty",
  async (state, { getState, dispatch, rejectWithValue }) => {
    const {updateLoyalityValue,token,getUserQueryProp} = state
    try {
     let res =  await AXIOS_HOSPITALITY.post(
        "updateUserLoyaltyApply",
        updateLoyalityValue,
        { headers: { Authorization: token } }
      );
      await dispatch(getUserProfile({token,...getUserQueryProp}));
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: err?.response?.data?.success || false,
          apiMessage:
            err?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
