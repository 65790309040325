import { Swiper } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React from "react";
import "swiper/css/zoom";
import "swiper/css";
import { Navigation } from "swiper";
import { IconCarouselArrow, IconCarouselLeftArrow, IconCarouselRightArrow } from "../../icons";

const NextPrevComp = () => {
  return (
    <div>
      <div
        className="
      absolute w-[50px]
      h-full left-0 top-0
      flex justify-center
      items-center"
      >
        <IconCarouselLeftArrow
          className="swiper-button-custom-prev absolute z-[1]"
        />
      </div>
      <div
        className="
      absolute w-[50px]
      h-full right-0 top-0
      flex justify-center
      items-center"
      >
        <IconCarouselRightArrow
          className="swiper-button-custom-next absolute z-[1]" />
      </div>
    </div>
  );
};

function index({ children }) {
  return (
    <Swiper
      className="mySwiper z-0"
      effect="slide"
      loop={true}
      modules={[Navigation]}
      navigation={{
        prevEl: ".swiper-button-custom-prev",
        nextEl: ".swiper-button-custom-next",
        enabled: true,
      }}
      lazyPreloaderClass={true}
      thumbs={true}
    >
      {children}
      <NextPrevComp />
    </Swiper>
  );
}

export default index;
