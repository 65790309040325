import { configureStore } from "@reduxjs/toolkit";
import calenderSlice from "../features/calender/calenderSlice";
import costSummarySlice from "../features/costSummary/costSummarySlice";
import roomSlice from "../features/rooms/roomSlice";
import savedCardsSlice from "../features/savedCards/savedCardsSlice";
import signInSlice from "../features/signIn/signInSlice";
import stripeSlice from "../features/stripe/stripeSlice";
import themeSlice from "../theme/themeSlice";
import reservationCancelSlice from "../pages/ReservationCancel/reservationCancelSlice";
import resetPasswordSlice from "../features/resetPassword/resetPasswordSlice";
import enrollSlice from "../features/enroll/enrollSlice";

export const store = configureStore({
  reducer: {
    calender: calenderSlice,
    costSummary: costSummarySlice,
    room: roomSlice,
    theme: themeSlice,
    signIn: signInSlice,
    stripe: stripeSlice,
    savedCards: savedCardsSlice,
    reservationCancel: reservationCancelSlice,
    resetPassword: resetPasswordSlice,
    enroll: enrollSlice
  },
});
