const index = ({
  className = "",
  name = "",
  Icon = () => null,
  spanClassName = "",
  onClick = () => {},
  disabled = false,
  loadingThreeDots = () => null,
  type = "button",
  dropDownIcon = () => null,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`pt-[11px] pb-[11px] flex items-center justify-center ${className}`}
      style={{ width: "100%", height: "40px" }}
    >
      <Icon />

      <span
        className={`flex items-center font-medium text-[13.333] text-center ${spanClassName}`}
      >
        {name}
        {loadingThreeDots}
      </span>
      <div>{dropDownIcon}</div>
    </button>
  );
};

export default index;

export const CloseButton = ({ handleClose }) => (
  <button
    onClick={handleClose}
    type="button"
    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-gray-500 hover:bg-gray-100 "
  >
    <span className="sr-only">Close menu</span>
    <svg
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);
