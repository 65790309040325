import React, { Fragment, useState } from "react";
import { Button } from "../../commonElements";

import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import { Formik, Form } from "formik";
import { signInValidationSchema } from "../../commonElements/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "./api";
import { Swal } from "../../components";
import { signInSelector } from "./signInSlice";
import { ThreeDots } from "react-loader-spinner";
import { Input } from "../../commonElements/input/cardInput";
import { CloseButton } from "../../commonElements/button";
import { useLocation } from "react-router-dom";

const loadingThreeDots = () => (
  <ThreeDots
    height="24"
    width="24"
    radius="9"
    color="white"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);

const SignIn = ({ handleClose }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const { status, isLoading } = useSelector(signInSelector);
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    let getUserQueryProp = {
      ratePlanCode: searchParams.get("ratePlanCode") || "",
      corpCode: searchParams.get('corpCode') || "" 
    }
    dispatch(signIn({formData:values,getUserQueryProp}));
    setSubmitting(false);
  };

  return (
    <Fragment>
      <Swal status={status} />
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full p-6 m-auto bg-white  shadow-md max-w-xl relative mx-[0.7rem] "
        style={{ zIndex: 1000 }}
      >
        <div className="right-0 absolute mr-6">
          <CloseButton handleClose={handleClose} />
        </div>

        <h1 className="font-normal text-center text-black mt-7 text-xl">
          SIGN IN
        </h1>

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={signInValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="mt-6">
              <div className="relative">
                <Input
                  type={"email"}
                  placeholder={"SHASHI ID"}
                  name={"email"}
                />
              </div>

              <div>
                <div className="relative">
                  <Input
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder={"PASSWORD"}
                    name={"password"}
                    autoComplete={"new-password"}
                  />

                  <div className="absolute right-0 z-30 inset-y-1 flex items-center px-4 ">
                    <button type="button" onClick={togglePasswordVisibility}>
                      {isPasswordVisible ? (
                        <EyeIcon className="w-5 h-5" />
                      ) : (
                        <EyeOffIcon className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {/* Forgot Password div hidded */}
              <div className="text-right mt-7 hidden">
                <button className="underline text-black text-sm font-light leading-5">
                  Forgot Password?
                </button>
              </div>

              <div className="mt-12 mb-7">
                <Button
                  type={"submit"}
                  disabled={isSubmitting}
                  className={"text-white bg-black"}
                  spanClassName={"font-normal"}
                  name={!isLoading && "Sign in"}
                  loadingThreeDots={isLoading && loadingThreeDots()}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default SignIn;
