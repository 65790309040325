import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import { setDate } from "../../features/calender/calenderSlice";
import { useDispatch } from "react-redux";
import RoomDescription from "../../features/roomDescription";
import SectionA from "../../features/splitLayout/SectionA";
import SectionB from "../../features/splitLayout/SectionB";
import SectionC from "../../features/splitLayout/SectionC";

function RoomBooking() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // console.log("usdgaigdiu", isOpen);
  useEffect(() => {
    if (searchParams.get("datein") && searchParams.get("dateout")) {
      dispatch(
        setDate([searchParams.get("datein"), searchParams.get("dateout")])
      );
    }
  }, []);

  const [queryDetails, setQueryDetails] = useState({
    _ga: searchParams.get("_ga") || "",
    rooms: searchParams.get("rooms") || "1",
    adults: searchParams.get("adults") || "1",
    groupId: searchParams.get("groupId") || "",
    children: searchParams.get("children") || "0",
    roomTypeId:searchParams.get("roomTypeId") || "",
    pets: searchParams.get("pets") || "0",
    ratePlanCode: searchParams.get("ratePlanCode") || "",
  });

  const sectionProps = { queryDetails, setQueryDetails };

  return (
    <section className=" lg:grid lg:grid-cols-3 lg:gap-[35px] w-full ml-auto mr-auto max-w-screen-xl lg:px-[80px] pt-0">
      <Helmet>
        <title>Room Booking</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <SectionA {...sectionProps} />
      <SectionB {...sectionProps} />
      <SectionC {...sectionProps} />
      <RoomDescription />
    </section>
  );
}

export default RoomBooking;
