import { createSlice } from "@reduxjs/toolkit";
import {
  removeAuth,
  setAuthData,
  setAuthToken,
} from "../../util/cookieReducer";
import { getUserProfile, signIn } from "./api";

let defaultStatus = {
  apiSuccess: true,
  apiMessage: "",
};
const initialState = {
  isToken: false,
  userData: {},
  status: defaultStatus,
  isLoading: false,
  removeLogoutOption:false
};
export const signInSlice = createSlice({
  initialState,
  name: "signIn",
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsToken: (state, action) => {
      state.isToken = action.payload;
    },
    setIsLogoutOption: (state, action) => {
      state.removeLogoutOption =  action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // CASE FOR USER SIGN IN --->>>
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
        state.isToken = false;
        state.status = defaultStatus;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        setAuthToken(payload.token);
        state.isToken = payload.token ? true : false;
        state.status = payload.status;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        removeAuth();
        state.status = payload.status;
        state.isLoading = false;
      })
      //   CASE FOR FETCH USER PROFILE --->>>
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
        // state.userData = {};
        state.status = defaultStatus;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        setAuthData(payload.userData);
        state.userData = payload.userData;
        state.status = payload.status;
        state.isLoading = false;
      })
      .addCase(getUserProfile.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
        removeAuth();
      });
  },
});
export default signInSlice.reducer;
export const signInSelector = (state) => state.signIn;
export const { setUserData, setIsToken, setIsLogoutOption } = signInSlice.actions;
